import environment from '@/environment';
import useSWR from 'swr';
import { useHasFeature } from './useFeatureFlags';
import useSdk from './useSdk';

export namespace Stripe {
  export type LegalEntity = {
    id: string;
    name: string;
    phone: string;
    mail: string;
    zip: string;
    street: string;
    country: string;
    city: string;
    managerName: string;
    managerMail: string;
    managerPhone: string;
    companyType: string;
    commercialRegisterNumber: string;
    commercialRegisterName: string;
    vatNumber: string;
    stripeAccountID: string;
  };

  export type Location = {
    id: string;
    name: string;
    phone: string;
    mail: string;
    zip: string;
    street: string;
    country: string;
    city: string;
    latitude: number;
    longitude: number;
    openingHours: any;
    legalEntity: string;
  };

  export type Membership = {
    id: string;
    appsiteID: string;
    legalEntity: Stripe.LegalEntity['id'];
    mainLocation: Stripe.Location['id'];
    account: string;
    accountData: any;
    locationName: string;
    stripeCustomerID: string;
    stripeCustomer: {
      email: string;
      name: string;
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
      };
    };
    membershipRequest: string;
  };

  export type Product = {
    id: string;
    active: boolean;
    created: number;
    default_price: string;
    description: string;
    images: string[];
    livemode: boolean;
    metadata: any;
    name: string;
    package_dimensions: string;
    shippable: string;
    statement_descriptor: string;
    tax_code: string;
    unit_label: string;
    updated: number;
    url: string;
  };

  export type Account = {
    id: string;
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    balance: number;
    cash_balance: {
      available: {
        eur: number;
      };
      customer: string;
      livemode: boolean;
      settings: {
        reconciliation_mode: string;
        using_merchant_default: boolean;
      };
    };
    created: number;
    currency: string;
    default_source: string;
    delinquent: boolean;
    description: string;
    discount: string;
    email: string;
    invoice_credit_balance: {
      eur: number;
    };
    invoice_prefix: string;
    invoice_settings: {
      custom_fields: string;
      default_payment_method: string;
      footer: string;
      rendering_options: string;
    };
    livemode: boolean;
    metadata: {};
    name: string;
    phone: string;
    preferred_locales: [string];
    shipping: {
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
      };
      name: string;
      phone: string;
    };
    subscriptions: {
      data: [
        {
          id: string;
          application: string;
          application_fee_percent: string;
          automatic_tax: {
            enabled: boolean;
          };
          billing_cycle_anchor: number;
          billing_thresholds: string;
          cancel_at: number;
          cancel_at_period_end: boolean;
          canceled_at: number;
          cancellation_details: {
            comment: string;
            feedback: string;
            reason: string;
          };
          collection_method: string;
          created: number;
          currency: string;
          current_period_end: number;
          current_period_start: number;
          customer: string;
          days_until_due: number;
          default_payment_method: string;
          default_source: string;
          default_tax_rates: [string];
          description: string;
          discount: string;
          ended_at: string;
          items: {
            data: [
              {
                id: string;
                billing_thresholds: string;
                created: number;
                metadata: {};
                plan: {
                  id: string;
                  active: boolean;
                  aggregate_usage: string;
                  amount: number;
                  amount_decimal: string;
                  billing_scheme: string;
                  created: number;
                  currency: string;
                  interval: string;
                  interval_count: number;
                  livemode: boolean;
                  metadata: {};
                  nickname: string;
                  product: string;
                  tiers_mode: string;
                  transform_usage: string;
                  trial_period_days: string;
                  usage_type: string;
                };
                price: {
                  id: string;
                  active: boolean;
                  billing_scheme: string;
                  created: number;
                  currency: string;
                  custom_unit_amount: string;
                  livemode: boolean;
                  lookup_key: string;
                  metadata: {};
                  nickname: string;
                  product: string;
                  recurring: {
                    aggregate_usage: string;
                    interval: string;
                    interval_count: number;
                    trial_period_days: string;
                    usage_type: string;
                  };
                  tax_behavior: string;
                  tiers_mode: string;
                  transform_quantity: string;
                  type: string;
                  unit_amount: number;
                  unit_amount_decimal: string;
                };
                quantity: number;
                subscription: string;
                tax_rates: [string];
              },
            ];
            has_more: boolean;
            total_count: number;
            url: string;
          };
          latest_invoice: string;
          livemode: boolean;
          metadata: {};
          next_pending_invoice_item_invoice: string;
          on_behalf_of: string;
          pause_collection: string;
          payment_settings: {
            payment_method_options: string;
            payment_method_types: string;
            save_default_payment_method: string;
          };
          pending_invoice_item_interval: string;
          pending_setup_intent: string;
          pending_update: string;
          plan: {
            id: string;
            active: boolean;
            aggregate_usage: string;
            amount: number;
            amount_decimal: string;
            billing_scheme: string;
            created: number;
            currency: string;
            interval: string;
            interval_count: number;
            livemode: boolean;
            metadata: {};
            nickname: string;
            product: string;
            tiers_mode: string;
            transform_usage: string;
            trial_period_days: string;
            usage_type: string;
          };
          quantity: number;
          schedule: string;
          start_date: number;
          status: string;
          test_clock: string;
          transfer_data: string;
          trial_end: number;
          trial_settings: {
            end_behavior: {
              missing_payment_method: string;
            };
          };
          trial_start: number;
        },
      ];
      has_more: boolean;
      total_count: number;
      url: string;
    };
    tax_exempt: string;
    test_clock: string;
  };
}

const fetcher = () => async (method: string, endpoint: string, body?: any) =>
  (
    await fetch(`${environment.clubappApiUrl}/hectorone/${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  ).json();

export const useStripe = () => {
  const { hasFeature } = useHasFeature();
  const { api } = useSdk();

  if (!hasFeature('ho-stripe')) {
    return {
      locations: [],
      location: {},
      legalEntities: [],
      memberships: [],
      membership: {},
      legalEntity: {},
    }; // ignorieren wenn react rumflennt. ändert sich nicht einfach so. nur wenn man stripe nutzt
  }

  const get = (endpoint: string) => fetcher()(`GET`, endpoint);
  const post = (endpoint: string, body: any) => fetcher()(`POST`, endpoint, body);
  const put = (endpoint: string) => fetcher()(`PUT`, endpoint);
  const del = (endpoint: string) => fetcher()(`delete`, endpoint);

  const { data: legalEntities } = useSWR<Stripe.LegalEntity[]>(
    api ? `hectorone/legalEntity?${api.shortID}` : null,
    async () => get(`legalEntity`),
  );

  const { data: locations } = useSWR<Stripe.Location[]>(api ? `hectorone/location?${api.shortID}` : null, async () =>
    get(`location`),
  );

  const { data: memberships } = useSWR<Stripe.Membership[]>(
    api ? `hectorone/membership?${api.shortID}` : null,
    async () => get(`membership`),
  );

  const membership = (membershipId: Stripe.Membership['id']) => ({
    get: async () => get(`membership/${membershipId}`),
  });

  const location = {
    create: async (location: Partial<Stripe.Location>) => post(`location`, location),
    delete: async (locationId: Stripe.Location['id']) => del(`location/${locationId}`),
  };

  function legalEntity(id: string) {
    return {
      get: async (): Promise<Stripe.LegalEntity> => get(`legalEntity/${id}`),
      products: async (): Promise<Stripe.Product[]> => get(`legalEntity/${id}/product`),
      memberships: async (filter: {
        query?: string;
        page?: number;
        perPage?: number;
        club?: string;
      }): Promise<Stripe.Membership[]> => {
        const filterString = Object.entries(filter)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        return get(`legalEntity/${id}/membership?${filterString}`);
      },
      locations: async (): Promise<Stripe.Location[]> => get(`legalEntity/${id}/location`),
      accounts: async (): Promise<Stripe.Account[]> => get(`legalEntity/${id}/account`),
    };
  }

  return {
    locations,
    location,
    legalEntities: legalEntities || [],
    memberships,
    membership,
    legalEntity,
  };
};
