import Button from '@/components/Button';
import CalendarRangeInput from '@/components/CalendarRangeInput';
import Form from '@/components/Form';
import Info from '@/components/Info';
import Modal from '@/components/Modal';
import Spinner from '@/components/Spinner';
import { ToggleInput } from '@/components/Toggle';
import { useDiscountCodes } from '@/hooks/useDiscounts';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

export default function FilterCodesModal({
  show,
  headline,
  setShow,
  discountID,
  children,
}: {
  show: boolean;
  headline: string;
  setShow: (show: boolean) => void;
  discountID: string;
  children: (codes: any[]) => React.ReactNode;
}) {
  const getCodes = useDiscountCodes();
  const form = useForm({
    defaultValues: {
      onlyPrinted: false,
      printed: {
        from: '',
        to: '',
      },
      onlyIssued: false,
      issued: {
        from: '',
        to: '',
      },
      onlyRedeemed: false,
      redeemed: {
        from: '',
        to: '',
      },
      dateFrom: '',
      dateTo: '',
    },
  });

  const data = form.watch();
  const filter = useMemo(() => {
    const values: any = {};
    values.size = 9999;
    if (data.onlyPrinted) values.printed = data.onlyPrinted;
    if (data.onlyIssued) values.issued = data.onlyIssued;
    if (data.onlyRedeemed) values.redeemed = data.onlyRedeemed;

    if (data.onlyPrinted) {
      values.printedFrom = data.printed.from ? dayjs(data.printed.from).format('YYYY-MM-DD') : null;
      values.printedTo = data.printed.to ? dayjs(data.printed.to).format('YYYY-MM-DD') : null;
    }

    if (data.onlyIssued) {
      values.issuedFrom = data.issued.from ? dayjs(data.issued.from).format('YYYY-MM-DD') : null;
      values.issuedTo = data.issued.to ? dayjs(data.issued.to).format('YYYY-MM-DD') : null;
    }

    if (data.onlyRedeemed) {
      values.redeemedFrom = data.redeemed.from ? dayjs(data.redeemed.from, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
      values.redeemedTo = data.redeemed.to ? dayjs(data.redeemed.to, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
    }

    return values;
  }, [data]);

  const { data: codes, isValidating } = getCodes(discountID, filter);

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <div className="flex gap-3 items-baseline justify-between">
        <h2 className="text-xl">{headline}</h2>
        <Button.Action onClick={() => setShow(false)}>
          <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-400" />
        </Button.Action>
      </div>
      <div className="flex flex-col gap-3">
        <Form.Item $dense $first>
          <Form.Item.Label>Gedruckt</Form.Item.Label>
          <ToggleInput control={form.control} label="Nur gedruckte Codes anzeigen?" name="onlyPrinted" />
          <div className={classNames(form.watch('onlyPrinted') ? 'opacity-100' : 'opacity-50 pointer-events-none')}>
            <Controller
              control={form.control}
              name="printed"
              render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
            />
          </div>
        </Form.Item>
        <Form.Item $dense $first>
          <Form.Item.Label>Exportiert</Form.Item.Label>
          <ToggleInput control={form.control} label="Nur ausgegebene Codes anzeigen?" name="onlyIssued" />
          <div className={classNames(form.watch('onlyIssued') ? 'opacity-100' : 'opacity-50 pointer-events-none')}>
            <Controller
              control={form.control}
              name="issued"
              render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
            />
          </div>
        </Form.Item>
        <Form.Item $dense $first>
          <Form.Item.Label>Eingelöst</Form.Item.Label>
          <ToggleInput control={form.control} label="Nur eingelöste Codes anzeigen?" name="onlyRedeemed" />
          <div className={classNames(form.watch('onlyRedeemed') ? 'opacity-100' : 'opacity-50 pointer-events-none')}>
            <Controller
              control={form.control}
              name="redeemed"
              render={({ field }) => <CalendarRangeInput value={field.value} onChange={field.onChange} />}
            />
          </div>
        </Form.Item>
        <div className="flex gap-3 mt-3 justify-end">
          {isValidating ? (
            <Spinner />
          ) : (
            <Info>
              {codes?.data.length > 0 ? (
                <span>{codes?.data.length} Codes gefunden</span>
              ) : (
                <span>Keine Codes gefunden</span>
              )}
            </Info>
          )}

          {children(codes?.data)}
        </div>
      </div>
    </Modal>
  );
}
