import React from 'react';
import { classNames } from '../util/classNames';
import Layout from './Layout';
import Spinner from './Spinner';

const PageLoader = ({ visible = true, log }: { visible?: boolean; log?: any }) => {
  return (
    // bg-gray-200 dark:bg-gray-600
    <Layout hideSideMenu skeleton>
      <div
        className={classNames(
          'pointer-events-none transition-opacity fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50',
          visible ? 'opacity-75' : 'opacity-0',
        )}
      >
        <Spinner className="w-16 h-16" />
      </div>
    </Layout>
  );
};

export default PageLoader;
