import { useEffect, useRef, useState } from 'react';

function useTimeoutFlag(ms = 1000) {
  const [toggle, setToggle] = useState(undefined);
  const timeout = useRef(null);
  useEffect(() => {
    return clearTimeout(timeout.current);
  }, []);
  const trigger = () => {
    setToggle(true);
    timeout.current = setTimeout(() => {
      setToggle(false);
    }, ms);
  };
  return [toggle, trigger];
}
export default useTimeoutFlag;
