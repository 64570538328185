import useSWR from 'swr';
import useSdk from './useSdk';

export const useGlobalContract = (name = 'GlobalContract') => {
  const { api } = useSdk();

  return useSWR(
    api && name ? `globalContract/${name}` : null,
    async () => {
      try {
        return (await api.entryList('eva_template', { type: 'global_contract', name })).getFirstItem();
      } catch (error) {
        console.error(error);
        console.dir(error);
        return undefined;
      }
    },
    { revalidateOnFocus: false },
  );
};
