import React, { useRef, useState } from 'react';
import Button from '../components/Button';

const Context = React.createContext({} as any);

export default function useConfirm() {
  const [confirm, setconfirm] = useState(null);
  const [acceptLabel, setAcceptLabel] = useState('Akzeptieren');
  const [declineLabel, setDeclineLabel] = useState('Abbrechen');
  const userResolved = useRef<(value: string) => void>(null);
  const userRejected = useRef<(value: string) => void>(null);

  async function confirmUser(message: string, acceptLabel?: string, declineLabel?: string) {
    setconfirm(message);
    setAcceptLabel(acceptLabel || 'Akzeptieren');
    setDeclineLabel(declineLabel || 'Abbrechen');
    return new Promise<string>((resolve, reject) => {
      userResolved.current = resolve;
      userRejected.current = reject;
    });
  }

  const Confirm = () => {
    const { confirm, setconfirm, userResolved } = React.useContext(Context);

    if (!confirm) return null;
    return (
      <div className="fixed bg-gray-500/50 inset-0 block z-50">
        <div
          ref={(me) => (me ? (me.style.left = `calc(50vw - ${me.getBoundingClientRect().width / 2}px )`) : null)}
          className="fixed top-[12vh] z-50 left-[50vw] min-w-[320px] rounded-md shadow-md bg-white dark:bg-gray-600 p-6 "
        >
          <div>
            <p className="text-sm mb-3 dark:text-white">{confirm}</p>
            <div className="flex gap-3 mt-3 justify-end">
              <Button
                $secondary
                onClick={() => {
                  userRejected.current?.('');
                  setconfirm(null);
                }}
              >
                {declineLabel}
              </Button>
              <Button
                $primary
                onClick={() => {
                  userResolved.current?.('');
                  setconfirm(null);
                }}
              >
                {acceptLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return {
    confirmUser,
    ConfirmProvider: () => (
      <Context.Provider value={{ confirm, setconfirm, userResolved }}>
        <Confirm />
      </Context.Provider>
    ),
  };
}
