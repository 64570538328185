import { Popover, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Controller } from 'react-hook-form';
import { classNames } from '../util/classNames';
import Button from './Button';
import Input from './Input';

const decimalToHex = (alpha: number) => ([0, 1].includes(alpha) ? '' : Math.round(255 * alpha).toString(16));

/** Simple hex color picker */
function ColorPicker({
  value: valueProp,
  onChange,
  presetColors,
  className,
  disableAlpha,
}: {
  /** the current color */
  value: string;
  /** called when the color changes */
  onChange: (value: string) => void;
  /** an array of preset colors to show */
  presetColors?: string[];
  /** additional class names */
  className?: string;
  disableAlpha?: boolean;
}) {
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    setValue(() => valueProp || '#ffffff');
  }, [valueProp]);
  return (
    <Popover as="div" className={classNames('relative inline-block text-left', className)}>
      <Popover.Button className="w-10 h-10 border dark:border-gray-700 border-gray-200 rounded-lg bg-white overflow-clip">
        <div className="w-full h-full" style={{ backgroundColor: value }} />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="z-20 origin-bottom-left absolute left-0 mt-2">
          <SketchPicker
            disableAlpha={disableAlpha}
            presetColors={presetColors || []}
            color={value}
            className="text-black"
            onChangeComplete={(v) => onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null)}
            onChange={(v) => onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null)}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export function ColorPickerInput(props) {
  const { presetColors, control, name, rules, className, disableAlpha } = props;
  return (
    <Controller
      render={({ field }) => (
        <ColorPicker
          disableAlpha={disableAlpha}
          className={className}
          value={field.value}
          onChange={field.onChange}
          presetColors={presetColors}
        />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}

/**
 * Picker styled to work in Sidebar forms
 */
export function InlinePicker({
  value,
  onChange,
  disableAlpha,
}: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  disableAlpha?: boolean;
}) {
  return (
    <Popover className="relative">
      <Popover.Button className="flex gap-1.5 items-center border dark:border-gray-700 border-gray-200 rounded-lg p-1">
        <div className="w-6 h-6 border dark:border-gray-700 border-gray-200 rounded-md bg-white overflow-clip">
          <div className="w-full h-full" style={{ backgroundColor: value }} />
        </div>
        <span style={{ fontFamily: 'monospace', fontSize: 11, width: 60, textAlign: 'left' }}>{value || 'none'}</span>
      </Popover.Button>

      <Popover.Panel className="absolute bg-white p-2 shadow rounded-lg z-10">
        <SketchPicker
          disableAlpha={disableAlpha}
          className="text-black"
          presetColors={[]}
          color={value ?? '#ffffff'}
          onChangeComplete={(v) => onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null)}
          onChange={(v) => onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null)}
        />
        <Button $secondary className="w-full mt-1 p-1 text-xs" onClick={() => onChange(null)}>
          <XMarkIcon className="w-4 h-4 mr-2" />
          Leeren
        </Button>
      </Popover.Panel>
    </Popover>
  );
}

/**
 * Picker styled to work in Sidebar forms
 */
export function InlinePickerRange({
  value,
  onChange,
  settings,
  disableAlpha,
}: {
  value: string;
  onChange: (value: string) => void;
  settings: any;
  disabled?: boolean;
  disableAlpha?: boolean;
}) {
  const checkRGB = (value) => {
    if (
      value.rgb.r >= settings.r.min &&
      value.rgb.r <= settings.r.max &&
      value.rgb.g >= settings.g.min &&
      value.rgb.g <= settings.g.max &&
      value.rgb.b >= settings.b.min &&
      value.rgb.b <= settings.b.max
    ) {
      return true;
    }
    return false;
  };
  const checkTolerance = (value) => {
    if (
      value.rgb.r <= value.rgb.b + settings.tolerance &&
      value.rgb.r >= value.rgb.b - settings.tolerance &&
      value.rgb.r <= value.rgb.g + settings.tolerance &&
      value.rgb.r >= value.rgb.g - settings.tolerance &&
      value.rgb.b <= value.rgb.g + settings.tolerance &&
      value.rgb.b >= value.rgb.g - settings.tolerance
    ) {
      return true;
    }
    return false;
  };
  const validate = (value) => {
    if (checkRGB(value) && checkTolerance(value)) {
      return true;
    }
    return false;
  };

  return (
    <Popover className="relative">
      <Popover.Button className="flex gap-1.5 items-center border dark:border-gray-700 border-gray-200 rounded-lg p-1">
        <div className="w-6 h-6 border dark:border-gray-700 border-gray-200 rounded-md bg-white overflow-clip">
          <div className="w-full h-full" style={{ backgroundColor: value }} />
        </div>
        <span style={{ fontFamily: 'monospace', fontSize: 11, width: 60, textAlign: 'left' }}>{value || 'none'}</span>
      </Popover.Button>

      <Popover.Panel className="absolute z-10">
        <SketchPicker
          disableAlpha={disableAlpha}
          presetColors={[]}
          color={value ?? '#ffffff'}
          className="text-black"
          onChangeComplete={(v) => onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null)}
          onChange={(v) => {
            if (validate(v)) {
              onChange(v?.hex ? `${v.hex}${decimalToHex(v.rgb.a)}` : null);
            }
          }}
        />
        <Button className="w-full mt-1 p-1 text-xs" onClick={() => onChange(null)}>
          zurücksetzen
        </Button>
      </Popover.Panel>
    </Popover>
  );
}

export function NativeColorPickerInput(props) {
  const { control, name, rules, className, placeholder, hideTextInput } = props;
  const pickerRef = useRef<any>();
  return (
    <Controller
      render={({ field }) => (
        <div className={classNames('max-w-lg flex rounded-md shadow-sm', className)}>
          <div
            className={classNames(
              'w-8 h-8 cursor-pointer overflow-hidden inline-flex border border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-600 text-gray-500 dark:text-gray-100 sm:text-sm',
              hideTextInput ? 'rounded-md' : 'border-r-0 rounded-l-md',
            )}
            style={{ backgroundColor: field.value }}
            onClick={() => pickerRef?.current?.click()}
          >
            <Input.Color
              value={field.value ?? '#ffffff'}
              onChange={field.onChange}
              className="w-8 h-full opacity-0 pointer-events-none"
              ref={pickerRef}
            />
          </div>
          {!hideTextInput && (
            <input
              type="text"
              value={field.value || ''}
              onChange={field.onChange}
              placeholder={placeholder}
              className="w-24 block pl-2 min-w-0 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 dark:border-gray-500 dark:bg-gray-700"
            />
          )}
        </div>
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}

export default ColorPicker;

export const ColorPickerExample = () => {
  const [value, setValue] = useState('#FF00AA');
  return <ColorPicker value={value} onChange={setValue} />;
};
