const parsePrice = (inp) => `${parseFloat(inp).toFixed(2).toString().replace('.', ',')} €`;

export const MEMBERSHIP: any = {
  name: 'Mitgliedschaft',
  variables: [
    {
      identifier: 'id',
      value: '{{id}}',
      description: 'Membership-EntryID',
      placeholder: 'XW5jiXoPPf',
    },
    {
      identifier: 'membershipRequestID',
      value: '{{membershipRequestID}}',
      description: 'Membership-Request-ID',
      placeholder: '1023',
    },
    {
      identifier: 'club.id',
      value: '{{club.id}}',
      description: 'Club-ID',
      placeholder: 'YX4wsYoZZr',
    },
    {
      identifier: 'club.name',
      value: '{{club.name}}',
      description: 'Club-Name',
      placeholder: 'Mein Club Musterstadt',
    },
    {
      identifier: 'club.studioNumber',
      value: '{{club.studioNumber}}',
      description: 'Club-Studionummer',
      placeholder: '123',
    },
    {
      identifier: 'club.owner',
      value: '{{club.owner}}',
      description: 'Club-Besitzer',
      placeholder: 'Alex Besitzer',
    },
    {
      identifier: 'club.addressStreet',
      value: '{{club.addressStreet}}',
      description: 'Club-Straße',
      placeholder: 'Pumperstraße 123',
    },
    {
      identifier: 'club.addressZipCode',
      value: '{{club.addressZipCode}}',
      description: 'Club-PLZ',
      placeholder: '21337',
    },
    {
      identifier: 'club.addressCity',
      value: '{{club.addressCity}}',
      description: 'Club-Stadt',
      placeholder: 'Musterdorf',
    },
    {
      identifier: 'club.addressCountryCode',
      value: '{{club.addressCountryCode}}',
      description: 'Club-Länderkürzel',
      placeholder: 'DE',
    },
    {
      identifier: 'club.addressCo',
      value: '{{club.addressCo}}',
      description: 'Club-Adresse C/O',
      placeholder: 'Businesspark 2A',
    },
    {
      identifier: 'club.bankAccountIBAN',
      value: '{{club.bankAccountIBAN}}',
      description: 'Club-IBAN',
      placeholder: 'DE11500105172878648336',
    },
    {
      identifier: 'club.bankAccountBIC',
      value: '{{club.bankAccountBIC}}',
      description: 'Club-BIC',
      placeholder: 'MUSTERDEXXX',
    },
    {
      identifier: 'club.bankAccountBankName',
      value: '{{club.bankAccountBankName}}',
      description: 'Club-Bankname',
      placeholder: 'Volksbank Musterdorf',
    },
    {
      identifier: 'club.bankAccountOwner',
      value: '{{club.bankAccountOwner}}',
      description: 'Club-Kontoinhaber',
      placeholder: 'Alex Besitzer',
    },
    {
      identifier: 'club.contactEmail',
      value: '{{club.contactEmail}}',
      description: 'Club-E-Mail',
      placeholder: 'info@meinclub.de',
    },
    {
      identifier: 'club.contactMobile',
      value: '{{club.contactEmail}}',
      description: 'Club-Mobilnummer',
      placeholder: '+49 1515 12341234',
    },
    {
      identifier: 'club.contactPhone',
      value: '{{club.contactPhone}}',
      description: 'Club-Telefonnummer',
      placeholder: '+49 711 23456789',
    },
    {
      identifier: 'club.sepaUci',
      value: '{{club.sepaUci}}',
      description: 'Club-SEPA-UCI',
      placeholder: 'XX123123',
    },
    {
      identifier: 'club.uStId',
      value: '{{club.uStId}}',
      description: 'Club-USt-ID.',
      placeholder: 'DE123456789',
    },
    {
      identifier: 'membershipTemplate.id',
      value: '{{membershipTemplate.id}}',
      description: 'Vertragsvorlagen-ID',
      placeholder: 'ZRXxx7Z45',
    },
    {
      identifier: 'membershipTemplate.title',
      value: '{{membershipTemplate.title}}',
      description: 'Vertrags-Titel',
      placeholder: 'Flexo Abo',
    },
    {
      identifier: 'membershipTemplate.type',
      value: '{{membershipTemplate.type}}',
      description: 'Vertrags-Art',
      placeholder: 'addon',
    },
    {
      identifier: 'membershipTemplate.initialPrice',
      value: '{{membershipTemplate.initialPrice | currency}}',
      description: 'Einrichtungsgebühr',
      placeholder: 2900,
    },
    {
      identifier: 'membershipTemplate.mainInterval',
      value: '{{membershipTemplate.mainInterval | interval}}',
      description: 'Abrchnungsintervall',
      placeholder: 'P1M',
    },
    {
      identifier: 'membershipTemplate.mainIntervalPrice',
      value: '{{membershipTemplate.mainIntervalPrice | currency}}',
      description: 'Grundpreis',
      placeholder: 2999,
    },
    {
      identifier: 'membershipTemplate.initialIntervalDuration',
      value: '{{membershipTemplate.initialIntervalDuration | interval}}',
      description: 'Rabattdauer',
      placeholder: 'P3M',
    },
    {
      identifier: 'membershipTemplate.initialIntervalPrice',
      value: '{{membershipTemplate.initialIntervalPrice | currency}}',
      description: 'Rabattpreis',
      placeholder: 1999,
    },
    {
      identifier: 'membershipTemplate.administrationFeeInterval',
      value: '{{membershipTemplate.administrationFeeInterval | interval}}',
      description: 'Verwaltungsgebühren-Intervall',
      placeholder: 'P1Y',
    },
    {
      identifier: 'membershipTemplate.administrationFee',
      value: '{{membershipTemplate.administrationFee | currency}}',
      description: 'Verwaltungsgebühr',
      placeholder: 9999,
    },
    {
      identifier: 'membershipTemplate.initialAdministrationFeeDuration',
      value: '{{membershipTemplate.initialAdministrationFeeDuration | interval}}',
      description: 'Rabattdauer Verwaltungsgebühr',
      placeholder: 'P1Y',
    },
    {
      identifier: 'membershipTemplate.initialAdministrationFee',
      value: '{{membershipTemplate.initialAdministrationFee | currency}}',
      description: 'Anfangs-Verwaltungsgebühr',
      placeholder: 4999,
    },
    {
      identifier: 'membershipTemplate.initialDuration',
      value: '{{membershipTemplate.initialDuration | interval}}',
      description: 'Grundlaufzeit',
      placeholder: 'P12M',
    },
    {
      identifier: 'membershipTemplate.renewalDuration',
      value: '{{membershipTemplate.renewalDuration | interval}}',
      description: 'Verlängerungs-Laufzeit',
      placeholder: 'P12M',
    },
    {
      identifier: 'membershipTemplate.terminationNoticePeriod',
      value: '{{membershipTemplate.terminationNoticePeriod | interval}}',
      description: 'Kündigungsfrist',
      placeholder: 'P1M',
    },
    {
      identifier: 'membershipTemplate.useFullIntervals',
      value: '{{membershipTemplate.useFullIntervals}}',
      description: 'Volle Abrechnungszeiträume',
      placeholder: true,
    },
    {
      identifier: 'membershipTemplate.useFullDurations',
      value: '{{membershipTemplate.useFullDurations}}',
      description: 'Volle Laufzeiten',
      placeholder: true,
    },
    {
      identifier: 'membershipTemplate.terminationOn15thBefore',
      value: '{{membershipTemplate.terminationOn15thBefore}}',
      description: 'Kündigung zum 15. des Vormonats',
      placeholder: true,
    },
    {
      identifier: 'membershipTemplate.freeTrialPeriod',
      value: '{{membershipTemplate.freeTrialPeriod | interval}}',
      description: 'Kostenloser Testzeitraum',
      placeholder: 'P2W',
    },
    {
      identifier: 'membershipTemplate.initialPriceIgnoresFreeTrialPeriod',
      value: '{{membershipTemplate.initialPriceIgnoresFreeTrialPeriod}}',
      description: 'Einrichtungsgebühr ignoriert Testphase',
      placeholder: true,
    },
    {
      identifier: 'membershipTemplate.autoAddons',
      value: '{{membershipTemplate.autoAddons}}',
      description: 'Automatisch zugebuchte Addons',
      placeholder: [{ _entryID: 'Kaffee' }],
    },
    {
      identifier: 'membershipTemplate.requiresAddons',
      value: '{{membershipTemplate.requiresAddons}}',
      description: 'Erforderliche Abos',
      placeholder: [],
    },
    {
      identifier: 'membershipTemplate.isStandalone',
      value: '{{membershipTemplate.isStandalone}}',
      description: 'Eigenständiger Vertrag',
      placeholder: true,
    },
    {
      identifier: 'membershipTemplate.isBookableFrom',
      value: '{{membershipTemplate.isBookableFrom | date}}',
      description: 'Buchbar ab',
      placeholder: '2021-02-12',
    },
    {
      identifier: 'membershipTemplate.isBookableTo',
      value: '{{membershipTemplate.isBookableTo | date}}',
      description: 'Buchbar bis',
      placeholder: '2035-03-05',
    },
    {
      identifier: 'membershipTemplate.minimumMembershipAge',
      value: '{{membershipTemplate.minimumMembershipAge}}',
      description: 'Mindestalter',
      placeholder: 16,
    },
    {
      identifier: 'membershipTemplate.earliestStartDate',
      value: '{{membershipTemplate.earliestStartDate}}',
      description: 'Mitgliedschaft Start Datum',
      placeholder: '2024-12-12',
    },
    {
      identifier: 'membershipTemplate.renewal',
      value: '{{membershipTemplate.renewal}}',
      description: 'Laufzeitoption',
      placeholder: 'Mitgliedschaft verlängert sich automatisch',
    },
    {
      identifier: 'account.accountID',
      value: '{{account.accountID}}',
      description: 'Account-ID',
      placeholder: '833750b6-38c2-4304-848a-fa6d96cb1c82',
    },
    {
      identifier: 'account.email',
      value: '{{account.email}}',
      description: 'E-Mail',
      placeholder: 'eva.adams@gmail.com',
    },
    {
      identifier: 'state',
      value: '{{state}}',
      description: 'Mitgliedschafts-Status',
      placeholder: 'finished',
    },
    {
      identifier: 'aboBooking.id',
      value: '{{aboBooking.id}}',
      description: 'Buchungs-ID',
      placeholder: 'XxT9kkKKOx',
    },
    {
      identifier: 'aboBooking.hasFreeTrialPeriod',
      value: '{{aboBooking.hasFreeTrialPeriod}}',
      description: 'Buchung hat Testphase',
      placeholder: true,
    },
    {
      identifier: 'userProfile.id',
      value: '{{userProfile.id}}',
      description: 'Nutzerprofil-ID',
      placeholder: 'YyY9zzZZ3y',
    },
    {
      identifier: 'userProfile.mainClub._entryTitle',
      value: '{{userProfile.mainClub._entryTitle}}',
      description: 'Hauptclub',
      placeholder: 'Mein Club Musterstadt',
    },
    {
      identifier: 'title',
      value: '{{title}}',
      description: 'Titel',
      placeholder: 'Dr.',
    },
    {
      identifier: 'name',
      value: '{{name}}',
      description: 'Vorname',
      placeholder: 'Eva',
    },
    {
      identifier: 'surname',
      value: '{{surname}}',
      description: 'Nachname',
      placeholder: 'Adams',
    },
    {
      identifier: 'gender',
      value: '{{gender}}',
      description: 'Geschlecht',
      placeholder: 'f',
    },
    {
      identifier: 'birthday',
      value: '{{birthday | date}}',
      description: 'Geburtsdatum',
      placeholder: '2000-02-20',
    },
    {
      identifier: 'street',
      value: '{{street}}',
      description: 'Straße',
      placeholder: 'Mustergasse 1',
    },
    {
      identifier: 'zipCode',
      value: '{{zipCode}}',
      description: 'PLZ',
      placeholder: '21337',
    },
    {
      identifier: 'city',
      value: '{{city}}',
      description: 'Stadt',
      placeholder: 'Mustermetropole',
    },
    {
      identifier: 'countryCode',
      value: '{{countryCode}}',
      description: 'Länderkürzel',
      placeholder: 'DE',
    },
    {
      identifier: 'mobile',
      value: '{{mobile}}',
      description: 'Telefon (Mobil)',
      placeholder: '+49 1515 67896789',
    },
    {
      identifier: 'subscribeToNewsletter',
      value: '{{subscribeToNewsletter}}',
      description: 'Newsletter abonniert',
      placeholder: true,
    },
    {
      identifier: 'iban',
      value: '{{iban}}',
      description: 'IBAN',
      placeholder: 'DE83500105177797533336',
    },
    {
      identifier: 'bic',
      value: '{{bic}}',
      description: 'BIC',
      placeholder: 'MUSTERDEXXX',
    },
    {
      identifier: 'bankName',
      value: '{{bankName}}',
      description: 'Bankname',
      placeholder: 'Volksbank Musterdorf',
    },
    {
      identifier: 'accountOwner',
      value: '{{accountOwner}}',
      description: 'Kontoinhaber',
      placeholder: 'Eva u. Julia Adams',
    },
    {
      identifier: 'referral',
      value: '{{referral}}',
      description: 'Referral-ID',
      placeholder: '0UEk8GEFPX',
    },
  ],
};

export const HECTOR_ADDON: any = {
  name: 'Addon',
  variables: [
    {
      identifier: 'name',
      value: '{{name}}',
      description: 'Vorname des Mitglieds',
      placeholder: 'Heinz',
    },
    {
      identifier: 'surname',
      value: '{{surname}}',
      description: 'Nachname des Mitglieds',
      placeholder: 'Tester',
    },
    {
      identifier: 'addon.name',
      value: '{{addon.name}}',
      description: 'Addon: Name',
      placeholder: 'Test-Addon',
    },
    {
      identifier: 'addon.pricePerInterval',
      value: '{{currency(addon.pricePerInterval)}}',
      description: 'Addon: Preis pro Interval',
      placeholder: 19.99,
    },
    {
      identifier: 'addon.interval',
      value: '{{interval(addon.interval)}}',
      description: 'Addon: Interval',
      placeholder: 'P1M',
    },
    {
      identifier: 'addon.duration',
      value: '{{interval(addon.duration)}}',
      description: 'Addon: Dauer',
      placeholder: 'P3M',
    },
    {
      identifier: 'addon.begin',
      value: '{{date(addon.begin)}}',
      description: 'Addon: Beginn',
      placeholder: '2009-12-12',
    },
    {
      identifier: 'addon.possibleDateOfEnd',
      value: '{{date(addon.possibleDateOfEnd)}}',
      description: 'Addon: Mögliches Addonende',
      placeholder: '2009-12-12',
    },
    {
      identifier: 'addon.entranceFee',
      value: '{{currency(addon.entranceFee)}}',
      description: 'Addon: Startgebühr',
      placeholder: 0.0,
    },
    {
      identifier: 'addon.partialFee',
      value: '{{currency(addon.partialFee)}}',
      description: 'Addon: Anteiliger Beitrag',
      placeholder: 12.15,
    },
  ],
};

export const HECTOR_MEMBERSHIP: any = {
  name: 'Mitgliedschaft',
  variables: [
    {
      identifier: 'membership',
      value: '{{membership}}',
      description: 'Name der Mitgliedschaft',
      placeholder: 'monatlich kündbar',
    },
    {
      identifier: 'activated',
      value: '{{activated}}',
      description: 'Antrag aktiviert',
      placeholder: true,
    },
    {
      identifier: 'newsletterTerms',
      value: '{{newsletterTerms}}',
      description: 'Newsletter-Bedingungen',
      placeholder: 'Ich aboniere den Newsletter.',
    },
    {
      identifier: 'sepaTerms',
      value: '{{sepaTerms}}',
      description: 'Sepa-Bedingungen',
      placeholder: 'Ich akzeptiere die Sepa Bedingungen.',
    },
    {
      identifier: 'requestDate',
      value: '{{date(requestDate)}}',
      description: 'Antragsdatum',
      placeholder: '2020-09-04T13:22:04.599Z',
    },
    {
      identifier: 'club.name',
      value: '{{club.name}}',
      description: 'Name des Clubs',
      placeholder: 'ODF_STUDIO_01',
    },
    {
      identifier: 'club.owner',
      value: '{{club.owner}}',
      description: 'Inhaber des Clubs',
      placeholder: 'Happy Systems GmbH',
    },
    {
      identifier: 'club.sepaUci',
      value: '{{club.sepaUci}}',
      description: 'Creditor ID des Clubs',
      placeholder: 'DE34ZZZ00000333619',
    },
    {
      identifier: 'club.uStId',
      value: '{{club.uStId}}',
      description: 'USt-ID des Clubs',
      placeholder: 'SN123456',
    },
    {
      identifier: 'club.bankAccount.bankName',
      value: '{{club.bankAccount.bankName}}',
      description: 'Bank Name des Clubs',
      placeholder: 'SPK Kraichgau',
    },
    {
      identifier: 'club.bankAccount.bic',
      value: '{{club.bankAccount.bic}}',
      description: 'BIC der Bank des Clubs',
      placeholder: 'BRUSDE66XXX',
    },
    {
      identifier: 'club.bankAccount.iban',
      value: '{{club.bankAccount.iban}}',
      description: 'IBAN des Club Bank Kontos',
      placeholder: 'DE30663500360007031008',
    },
    {
      identifier: 'club.bankAccount.accountOwner',
      value: '{{club.bankAccount.accountOwner}}',
      description: 'Inhaber des Club Bank Kontos',
      placeholder: 'Happy Systems GmbH',
    },
    {
      identifier: 'club.address.street',
      value: '{{club.address.street}}',
      description: 'Adresse des Clubs: Straße',
      placeholder: 'Am Leinwedel 67',
    },
    {
      identifier: 'club.address.zipCode',
      value: '{{club.address.zipCode}}',
      description: 'Adresse des Clubs: Postleitzahl',
      placeholder: '74889',
    },
    {
      identifier: 'club.address.city',
      value: '{{club.address.city}}',
      description: 'Adresse des Clubs: Stadt',
      placeholder: 'Sinsheim',
    },
    {
      identifier: 'club.address.countryCode',
      value: '{{club.address.countryCode}}',
      description: 'Adresse des Clubs: Land',
      placeholder: 'DE',
    },
    {
      identifier: 'club.contact.email',
      value: '{{club.contact.email}}',
      description: 'Email-Adresse des Clubs',
      placeholder: 'service@hector.de',
    },
    {
      identifier: 'club.contact.mobile',
      value: '{{club.contact.mobile}}',
      description: 'Handy-Nummer des Clubs',
      placeholder: '07000 1000 339',
    },
    {
      identifier: 'club.contact.phone',
      value: '{{club.contact.phone}}',
      description: 'Telefon-Nummer des Clubs',
      placeholder: '07000 1000 339',
    },
    {
      identifier: 'club.contact.telefax',
      value: '{{club.contact.telefax}}',
      description: 'Fax-Nummer des Clubs',
      placeholder: '07261 94 88 20',
    },
    {
      identifier: 'customer.id',
      value: '{{customer.id}}',
      description: 'Mitgliedsnummer',
      placeholder: '123456',
    },
    {
      identifier: 'customer.company',
      value: '{{customer.company}}',
      description: 'Firma des Mitglieds',
      placeholder: 'Sport GmbH',
    },
    {
      identifier: 'customer.personalData.title',
      value: '{{customer.personalData.title}}',
      description: 'Titel des Mitglieds',
      placeholder: 'Dr.',
    },
    {
      identifier: 'customer.personalData.gender',
      value: '{{customer.personalData.gender}}',
      description: 'Geschlecht des Mitglieds',
      placeholder: 'm',
    },
    {
      identifier: 'customer.personalData.name',
      value: '{{customer.personalData.name}}',
      description: 'Vorname des Mitglieds',
      placeholder: 'Heinz',
    },
    {
      identifier: 'customer.personalData.surname',
      value: '{{customer.personalData.surname}}',
      description: 'Nachname des Mitglieds',
      placeholder: 'Tester',
    },
    {
      identifier: 'customer.personalData.birthday',
      value: '{{date(customer.personalData.birthday)}}',
      description: 'Geburtstag des Mitglieds',
      placeholder: '1990-10-10',
    },
    {
      identifier: 'customer.paymentInformation.bankAccount.bankName',
      value: '{{customer.paymentInformation.bankAccount.bankName}}',
      description: 'Bankname des Mitglieds',
      placeholder: 'Test Bank',
    },
    {
      identifier: 'customer.paymentInformation.bankAccount.bic',
      value: '{{customer.paymentInformation.bankAccount.bic}}',
      description: 'BIC der Bank des Mitglieds',
      placeholder: 'TEST123BIC',
    },
    {
      identifier: 'customer.paymentInformation.bankAccount.iban',
      value: '{{customer.paymentInformation.bankAccount.iban}}',
      description: 'IBAN des Mitglieds',
      placeholder: 'DE98ZZZ09999999999',
    },
    {
      identifier: 'customer.paymentInformation.bankAccount.accountOwner',
      value: '{{customer.paymentInformation.bankAccount.accountOwner}}',
      description: 'Kontoinhaber des Mitglieds',
      placeholder: 'Horst Maier',
    },
    {
      identifier: 'customer.paymentInformation.sepaMandate.mandateReference',
      value: '{{customer.paymentInformation.sepaMandate.mandateReference}}',
      description: 'SEPA-Mandat Referenz',
      placeholder: 'SEPA2323-xxx',
    },
    {
      identifier: 'customer.paymentInformation.sepaMandate.signatureDate',
      value: '{{date(customer.paymentInformation.sepaMandate.signatureDate)}}',
      description: 'SEPA-Mandat Datum',
      placeholder: '1990-10-10',
    },
    {
      identifier: 'customer.contact.email',
      value: '{{customer.contact.email}}',
      description: 'E-Mail Adresse des Mitglieds',
      placeholder: 'heinz@testmail.com',
    },
    {
      identifier: 'customer.contact.subscribeToNewsletter',
      value: '{{customer.contact.subscribeToNewsletter}}',
      description: 'Mitglied hat Newsletter aboniert',
      placeholder: true,
    },
    {
      identifier: 'customer.contact.mobile',
      value: '{{customer.contact.mobile}}',
      description: 'Mobil-Telefonnummer des Mitglieds',
      placeholder: '0160/55554444',
    },
    {
      identifier: 'customer.contact.phone',
      value: '{{customer.contact.phone}}',
      description: 'Telefonnummer des Mitglieds',
      placeholder: '07554/232332',
    },
    {
      identifier: 'customer.address.street',
      value: '{{customer.address.street}}',
      description: 'Adresse des Mitglieds: Straße',
      placeholder: 'Teststraße 31',
    },
    {
      identifier: 'customer.address.zipCode',
      value: '{{customer.address.zipCode}}',
      description: 'Adresse des Mitglieds: Postleitzahl',
      placeholder: '88888',
    },
    {
      identifier: 'customer.address.city',
      value: '{{customer.address.city}}',
      description: 'Adresse des Mitglieds: Stadt',
      placeholder: 'Teststadt',
    },
    {
      identifier: 'customer.address.countryCode',
      value: '{{customer.address.countryCode}}',
      description: 'Adresse des Mitglieds: Land',
      placeholder: 'Deutschland',
    },
    {
      identifier: 'customer.address.co',
      value: '{{customer.address.co}}',
      description: 'Adresse des Mitglieds: Adresszusatz',
      placeholder: 'Test GmbH',
    },
    {
      identifier: 'contract.name',
      value: '{{contract.name}}',
      description: 'Vertrag: Name',
      placeholder: 'Test-Vertrag',
    },
    {
      identifier: 'contract.pricePerInterval',
      value: '{{currency(contract.pricePerInterval)}}',
      description: 'Vertrag: Preis pro Interval',
      placeholder: 19.99,
    },
    {
      identifier: 'contract.interval',
      value: '{{interval(contract.interval)}}',
      description: 'Vertrag: Interval',
      placeholder: 'P1M',
    },
    {
      identifier: 'contract.duration',
      value: '{{interval(contract.duration)}}',
      description: 'Vertrag: Dauer',
      placeholder: 'P3M',
    },
    {
      identifier: 'contract.begin',
      value: '{{date(contract.begin)}}',
      description: 'Vertrag: Beginn',
      placeholder: '2009-12-12',
    },
    {
      identifier: 'contract.possibleDateOfEnd',
      value: '{{date(contract.possibleDateOfEnd)}}',
      description: 'Vertrag: Mögliches Vertragsende',
      placeholder: '2009-12-12',
    },
    {
      identifier: 'contract.entranceFee',
      value: '{{currency(contract.entranceFee)}}',
      description: 'Vertrag: Startgebühr',
      placeholder: 0.0,
    },
    {
      identifier: 'contract.partialFee',
      value: '{{currency(contract.partialFee)}}',
      description: 'Vertrag: Anteiliger Beitrag',
      placeholder: 12.15,
    },
  ],
};

export const HECTOR_VOUCHER: any = {
  name: 'Gutschein',
  variables: [
    {
      identifier: 'from',
      value: '{{from}}',
      description: 'Von',
      placeholder: 'Peter',
    },
    {
      identifier: 'to',
      value: '{{to}}',
      description: 'Für',
      placeholder: 'Siggi',
    },
    {
      identifier: 'voucherCode',
      value: '{{voucherCode}}',
      description: 'Gutscheincode',
      placeholder: 'XX332',
    },
    {
      identifier: 'email',
      value: '{{email}}',
      description: 'E-Mail Adresse',
      placeholder: 'peter@hector.de',
    },
  ],
};

export const DSB_DEFAULT: any = ({
  regularFee,
  reducedFee,
  reducedDuration,
  testingDuration,
  entryFee,
  annualFee,
  addons,
  logo,
}) => ({
  objects: [
    {
      type: 'image',
      originX: 'left',
      originY: 'top',
      left: 100,
      top: 110,
      width: 300,
      height: 300,
      fill: 'rgb(0,0,0)',
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 0.6,
      scaleY: 0.6,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'nonzero',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      crossOrigin: 'anonymous',
      cropX: 0,
      cropY: 0,
      hide: null,
      show: null,
      src: logo,
      filters: [],
    },
    {
      rx: 0,
      ry: 0,
      top: 487,
      fill: 'transparent',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1050,
      height: 110,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#343434',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 617,
      fill: 'transparent',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1050,
      height: 160,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#343434',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 310,
      fill: 'transparent',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 510,
      height: 157.92,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#bababa',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 800,
      fill: '#f4f4f4',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1050,
      height: 330,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#343434',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 310,
      fill: 'transparent',
      left: 638.47,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 510,
      height: 157.92,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#bababa',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 107,
      fill: '#000000',
      left: 340,
      path: null,
      text: 'Mitgliedschaftsvereinbarung',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 850,
      height: 27.12,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '24',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 150,
      fill: 'transparent',
      left: 340,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 810,
      height: 140,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#bababa',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 170,
      fill: 'black',
      left: 360,
      path: null,
      text: 'Vertragspartner',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 120.05,
      height: 15.82,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '14',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 200,
      fill: 'rgb(0,0,0)',
      left: 360,
      path: null,
      text: 'Name:\nVorname:\nStraße:\nPLZ, Ort:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 70,
      height: 60.75,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 200,
      fill: 'rgb(0,0,0)',
      left: 445,
      path: null,
      text: '{{customer.personalData.surname}}\n{{customer.personalData.name}}\n{{customer.address.street}}\n{{customer.address.zipCode}} {{customer.address.city}}, {{customer.address.countryCode}}',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 323,
      height: 76.48,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 200,
      fill: 'rgb(0,0,0)',
      left: 774,
      path: null,
      text: 'Geburtstag:\nMobil:\nE-Mail:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 80,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 200,
      fill: 'rgb(0,0,0)',
      left: 864,
      path: null,
      text: '{{date(customer.personalData.birthday)}}\n{{customer.contact.mobile}}\n{{customer.contact.email}}',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 360,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 357,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Das Mitglied ist zum Besuch des Clubs während der Öffnungszeiten und zur Nutzung folgender inkludierter Leistungen berechtigt: Die Nutzung der gesamten Trainingsfläche mit Kraft-, Fitness- und Cardiobereich. Die Mitgliedschaft wird auf unbestimmte Dauer abgeschlossen und kann jederzeit mit einer Frist von 1 Monat in Textform gekündigt werden.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 470,
      height: 76.48,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 330,
      fill: '#1b1b1b',
      left: 120,
      path: null,
      text: 'ENTHALTENE LEISTUNGEN',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 480,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 507,
      fill: '#1b1b1b',
      left: 120,
      path: null,
      text: `${testingDuration} KOSTENLOSE TESTPHASE - kostenlos testen & trainieren`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 730,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: 'transparent',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 1147,
      fill: 'transparent',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1050,
      height: 240,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#969696',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 331,
      fill: '#1b1b1b',
      left: 660,
      path: null,
      text: 'WEITERE ZUSATZLEISTUNGEN | ADD-ONs',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 445,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1194,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Ich ermächtige die {{club.owner}} Zahlungen von meinem Konto mittels SEPA-Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der {{club.owner}} auf mein Konto gezogenen SEPA-Lastschriften einzulösen. Hinweis: Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1010,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1164,
      fill: '#000000',
      left: 120,
      path: null,
      text: 'Abbuchungsauftrag + SEPA-Lastschriftmandat',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 440,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1259,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Kontoinhaber:\nKreditinstitut (BIC):\nIBAN:\nMandatsreferenz:\nCredior-ID:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 120,
      height: 76.48,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1259,
      fill: 'rgb(0,0,0)',
      left: 240,
      path: null,
      text: '{{customer.paymentInformation.bankAccount.accountOwner}}\n{{customer.paymentInformation.bankAccount.bankName}} ({{customer.paymentInformation.bankAccount.bic}})\n{{customer.paymentInformation.bankAccount.iban}}\n{{customer.paymentInformation.sepaMandate.mandateReference}}\n{{club.sepaUci}}\n',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 617.7,
      height: 92.21,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1354,
      fill: 'black',
      left: 120,
      path: null,
      text: 'Hinweis: Die SEPA-Lastschriften können im Detail auf der Club-Webseite oder über die Club-App im Mitgliederbereich mit den persönlichen Zugangsdaten abgerufen werden.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1010,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1412.96,
      fill: 'rgb(0,0,0)',
      left: 627.96,
      path: null,
      text: 'Ich bestätige, den Vertrag gelesen und die dort angeführten Erklärungen erhalten, sowie AGB und Datenschutz akzeptiert zu haben.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 290,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1620,
      fill: 'rgb(0,0,0)',
      left: 100,
      path: null,
      text: 'Die Vereinbarung wurde am {{today()}} vom Kunde per Email bestätigt. ',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 470,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 1640,
      fill: 'transparent',
      left: 100,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: true,
      skewX: 0,
      skewY: 0,
      width: 470,
      height: 1,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#969696',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1660,
      fill: 'rgb(0,0,0)',
      left: 100,
      path: null,
      text: '{{club.owner}} | {{club.name}} | {{club.address.street}}, {{club.address.zipCode}}, {{club.address.city}} | E-Mail: {{club.contact.email}}\nIBAN: {{club.bankAccount.iban}} | BIC: {{club.bankAccount.bic}}',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1060,
      height: 29.29,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 1410.96,
      fill: 'transparent',
      left: 557.96,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 40,
      height: 40,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#969696',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 2120,
      fill: 'rgb(0,0,0)',
      left: 420,
      type: 'group',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 42,
      height: 42,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      objects: [
        {
          rx: 0,
          ry: 0,
          top: -21,
          fill: 'transparent',
          left: -21,
          type: 'rect',
          angle: 0,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          width: 40,
          height: 40,
          scaleX: 1,
          scaleY: 1,
          shadow: null,
          stroke: '#ff9300',
          opacity: 1,
          originX: 'left',
          originY: 'top',
          version: '5.2.1',
          visible: true,
          fillRule: 'nonzero',
          paintFirst: 'fill',
          strokeWidth: 2,
          strokeLineCap: 'butt',
          strokeUniform: false,
          strokeLineJoin: 'miter',
          backgroundColor: '',
          strokeDashArray: null,
          strokeDashOffset: 0,
          strokeMiterLimit: 10,
          globalCompositeOperation: 'source-over',
        },
      ],
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 0,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 685,
      fill: 'rgb(0,0,0)',
      left: 690,
      path: null,
      text: '{{date(requestDate)}}',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: null,
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 685,
      fill: 'rgb(0,0,0)',
      left: 520,
      path: null,
      text: 'Trainingsbeginn:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 667,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Mitgliedschaft regulär monatlich:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 220,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 665,
      fill: 'rgb(0,0,0)',
      left: 690,
      path: null,
      text: 'erster Tag des Folgemonats',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: null,
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 357,
      fill: 'rgb(0,0,0)',
      left: 660,
      path: null,
      text: 'Weiterhin gesondert buchbar sind Angebote, die nicht in genannter Mitgliedschaft beinhaltet sind, wie: Getränke-, Kaffee-, Shake- & Bestprice-ADD-ON, u.s.w.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 440,
      height: 29.29,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 707,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Zzgl. Verwaltungsgebühr jährlich:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 220,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 707,
      fill: 'rgb(0,0,0)',
      left: 349,
      path: null,
      text: `${parsePrice(annualFee)}`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 67.1,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'right',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: null,
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 706,
      fill: 'black',
      left: 520,
      path: null,
      text: 'Erster Abbuchungsbetrag:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 705,
      fill: 'black',
      left: 690,
      path: null,
      text: 'Enthält das einmalige Startpaket, die Verwaltungsgebühr, sowie den ersten anteiligen Monatsbeitrag, berechnet vom Tag nach der Testphase, bis zum nächsten Monatsersten {auf Basis des vereinbarten, rabattierten Monatsbeitrags}. Dieser Beitrag wird nach der Testphase abgebucht.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 440,
      height: 60.75,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 871,
      fill: '#000000',
      left: 120,
      path: null,
      text: `${
        reducedFee && regularFee
          ? `Die monatliche Trainingsgebühr beträgt für die ersten ${reducedDuration}: ${parsePrice(
              reducedFee,
            )} / Monat \nDanach beträgt die reguläre Trainingsgebühr: ${parsePrice(regularFee)} /Monat`
          : `Die Trainingsgebühr beträgt ${parsePrice(regularFee)} /Monat`
      }  (Abbuchung der Trainingsgebühr erfolgt monatlich zu jedem 1. eines Monats)`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 940,
      height: 29.29,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 821,
      fill: 'black',
      left: 120,
      path: null,
      text: '',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 370,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 757,
      fill: '#000000',
      left: 120,
      path: null,
      text: 'Sämtliche Preise enthalten die gesetzliche MwSt.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 500,
      height: 11.3,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '10',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 840,
      fill: '#1b1b1b',
      left: 120,
      path: null,
      text: 'DEIN ABO',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 560,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 173,
      fill: 'black',
      left: 500,
      path: null,
      text: 'MG-Nr: {{customer.id}}',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 370,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 687,
      fill: 'rgb(0,0,0)',
      left: 120,
      path: null,
      text: 'Zzgl. einmaligem Startpaket:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 220,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 687,
      fill: 'rgb(0,0,0)',
      left: 365,
      path: null,
      text: `${parsePrice(entryFee)}`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 50,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'right',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1820,
      fill: 'rgb(0,0,0)',
      left: 330,
      type: 'group',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 271,
      height: 42,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      objects: [
        {
          top: -21,
          fill: 'rgb(0,0,0)',
          left: -65.5,
          path: null,
          text: 'Ich habe die Datenschutzerklärung gelesen und verstanden',
          type: 'textbox',
          angle: 0,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          width: 200,
          height: 29.29,
          scaleX: 1,
          scaleY: 1,
          shadow: null,
          stroke: null,
          opacity: 1,
          originX: 'left',
          originY: 'top',
          version: '5.2.1',
          visible: true,
          fillRule: 'nonzero',
          fontSize: 12,
          minWidth: 20,
          overline: false,
          pathSide: 'left',
          direction: 'ltr',
          fontStyle: 'normal',
          pathAlign: 'baseline',
          textAlign: 'left',
          underline: false,
          fontFamily: 'Arial',
          fontWeight: 'normal',
          lineHeight: 1.16,
          paintFirst: 'fill',
          charSpacing: 0,
          linethrough: false,
          strokeWidth: 1,
          strokeLineCap: 'butt',
          strokeUniform: false,
          strokeLineJoin: 'miter',
          backgroundColor: '',
          pathStartOffset: 0,
          splitByGrapheme: false,
          strokeDashArray: null,
          strokeDashOffset: 0,
          strokeMiterLimit: 10,
          textBackgroundColor: '',
          globalCompositeOperation: 'source-over',
        },
        {
          rx: 0,
          ry: 0,
          top: -21,
          fill: 'transparent',
          left: -135.5,
          type: 'rect',
          angle: 0,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          width: 40,
          height: 40,
          scaleX: 1,
          scaleY: 1,
          shadow: null,
          stroke: '#ff9300',
          opacity: 1,
          originX: 'left',
          originY: 'top',
          version: '5.2.1',
          visible: true,
          fillRule: 'nonzero',
          paintFirst: 'fill',
          strokeWidth: 2,
          strokeLineCap: 'butt',
          strokeUniform: false,
          strokeLineJoin: 'miter',
          backgroundColor: '',
          strokeDashArray: null,
          strokeDashOffset: 0,
          strokeMiterLimit: 10,
          globalCompositeOperation: 'source-over',
        },
      ],
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 0,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1490,
      fill: 'rgb(0,0,0)',
      left: 169,
      path: null,
      text: 'Ich möchte zukünftig über Neuigkeiten per E-Mail und SMS informiert werden',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 260,
      height: 29.29,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1483,
      fill: 'rgb(0,0,0)',
      left: 100,
      type: 'group',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 42,
      height: 42,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#969696',
      objects: [
        {
          rx: 0,
          ry: 0,
          top: -21,
          fill: 'transparent',
          left: -21,
          type: 'rect',
          angle: 0,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          width: 40,
          height: 40,
          scaleX: 1,
          scaleY: 1,
          shadow: null,
          stroke: '#969696',
          opacity: 1,
          originX: 'left',
          originY: 'top',
          version: '5.2.1',
          visible: true,
          fillRule: 'nonzero',
          paintFirst: 'fill',
          strokeWidth: 2,
          strokeLineCap: 'butt',
          strokeUniform: false,
          strokeLineJoin: 'miter',
          backgroundColor: '',
          strokeDashArray: null,
          strokeDashOffset: 0,
          strokeMiterLimit: 10,
          globalCompositeOperation: 'source-over',
        },
      ],
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 0,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1930,
      fill: 'rgb(0,0,0)',
      left: 480,
      type: 'group',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 42,
      height: 42,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      objects: [
        {
          rx: 0,
          ry: 0,
          top: -21,
          fill: 'transparent',
          left: -21,
          type: 'rect',
          angle: 0,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          width: 40,
          height: 40,
          scaleX: 1,
          scaleY: 1,
          shadow: null,
          stroke: '#ff9300',
          opacity: 1,
          originX: 'left',
          originY: 'top',
          version: '5.2.1',
          visible: true,
          fillRule: 'nonzero',
          paintFirst: 'fill',
          strokeWidth: 2,
          strokeLineCap: 'butt',
          strokeUniform: false,
          strokeLineJoin: 'miter',
          backgroundColor: '',
          strokeDashArray: null,
          strokeDashOffset: 0,
          strokeMiterLimit: 10,
          globalCompositeOperation: 'source-over',
        },
      ],
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 0,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1140,
      fill: '#000000',
      left: 120,
      path: null,
      text: '',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 980,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 980,
      fill: '#000000',
      left: 120,
      path: null,
      text: `${addons.length ? 'Bei der Online-Anmeldung wurden folgende Add-ONs gebucht:' : ''}\n${addons
        .map(
          ({ title, fee, testing, description }) =>
            `${title} mit kostenloser Testphase für ${testing}, danach ${parsePrice(fee)} ${
              description?.length ? `(${description})` : ''
            }`,
        )
        .join('\n')}\n\n${
        addons.length
          ? 'Ein Storno innerhalb der Testphase ist täglich am ADD-ON-Terminal im Club möglich. Danach kann eine Kündigung jederzeit mit einer Kündigungsfrist von 1 Monat in Textform oder am ADD-ON-Terminal erfolgen.'
          : ''
      }\n`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1010,
      height: 107.94,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 954,
      fill: '#000000',
      left: 120,
      path: null,
      text: 'Gebuchte ADD-ONs mit kostenfreier Testphase',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 510,
      height: 15.82,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '14',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1192,
      fill: 'rgb(0,0,0)',
      left: 770,
      path: null,
      text: '',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1460,
      fill: 'rgb(0,0,0)',
      left: 340,
      path: null,
      text: '',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 76.7,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 665,
      fill: 'rgb(0,0,0)',
      left: 520,
      path: null,
      text: 'Mitgliedschaftsbeginn:',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 160,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: 12,
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 668,
      fill: 'rgb(0,0,0)',
      left: 355,
      path: null,
      text: `${parsePrice(regularFee)}`,
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 60,
      height: 13.56,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'right',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: null,
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 637,
      fill: '#1b1b1b',
      left: 120,
      path: null,
      text: 'ABO-DETAILS & PREISE',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 220,
      height: 18.08,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '16',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 534,
      fill: '#000000',
      left: 120,
      path: null,
      text: 'Zufriedenheitsgarantie: Ein Rücktritt ist jederzeit, ohne Angabe von Gründen, innerhalb der Testphase ab Vertragsabschluss (=kostenpflichtig bestellen) möglich. \nInnerhalb der Testphase trainierst und testest du kostenfrei. In dieser Zeit kannst du die Mitgliedschaft täglich in Textform stornieren. Nach der Testphase beginnt die genannte Mitgliedschaft gem. den aktuell gültigen AGB, sowie die monatlichen Beitragsbuchungen. Die Testphase gilt einmalig und nur für Neukunden.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 1010,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: 'transparent',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1411,
      fill: 'rgb(0,0,0)',
      left: 169,
      path: null,
      text: 'Ich stimme zu, dass zur Verwaltung meiner Daten diese an die Hectors-Welt GmbH, Abteilung Z-M-V, weitergegeben werden dürfen.',
      type: 'textbox',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 322,
      height: 45.02,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: null,
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      fontSize: '12',
      minWidth: 20,
      overline: false,
      pathSide: 'left',
      direction: 'ltr',
      fontStyle: 'normal',
      pathAlign: 'baseline',
      textAlign: 'left',
      underline: false,
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: 1.16,
      paintFirst: 'fill',
      charSpacing: 0,
      linethrough: false,
      strokeWidth: 1,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      pathStartOffset: 0,
      splitByGrapheme: false,
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 4,
      textBackgroundColor: '',
      globalCompositeOperation: 'source-over',
    },
    {
      rx: 0,
      ry: 0,
      top: 1410,
      fill: 'transparent',
      left: 99,
      type: 'rect',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 40,
      height: 40,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: '#969696',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 2,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 10,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1415,
      fill: 'rgb(0,0,0)',
      left: 562,
      path: [
        ['M', 10, 10],
        ['L', 40, 40],
        ['M', 40, 10],
        ['L', 10, 40],
      ],
      type: 'path',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 30,
      height: 30,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: 'black',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 4,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 4,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1487,
      fill: 'rgb(0,0,0)',
      left: 103,
      path: [
        ['M', 10, 10],
        ['L', 40, 40],
        ['M', 40, 10],
        ['L', 10, 40],
      ],
      show: 'customer.contact.subscribeToNewsletter',
      type: 'path',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 30,
      height: 30,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: 'black',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 4,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 4,
      globalCompositeOperation: 'source-over',
    },
    {
      top: 1414,
      fill: 'rgb(0,0,0)',
      left: 103,
      path: [
        ['M', 10, 10],
        ['L', 40, 40],
        ['M', 40, 10],
        ['L', 10, 40],
      ],
      type: 'path',
      angle: 0,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      width: 30,
      height: 30,
      scaleX: 1,
      scaleY: 1,
      shadow: null,
      stroke: 'black',
      opacity: 1,
      originX: 'left',
      originY: 'top',
      version: '5.2.1',
      visible: true,
      fillRule: 'nonzero',
      paintFirst: 'fill',
      strokeWidth: 4,
      strokeLineCap: 'butt',
      strokeUniform: false,
      strokeLineJoin: 'miter',
      backgroundColor: '',
      strokeDashArray: null,
      strokeDashOffset: 0,
      strokeMiterLimit: 4,
      globalCompositeOperation: 'source-over',
    },
  ],
  background: 'white',
});
