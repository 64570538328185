import { DropCursorExtension } from '@remirror/extension-drop-cursor';
import { HeadingExtension } from '@remirror/extension-heading';
import { HistoryExtension } from '@remirror/extension-history';
import { ImageExtension } from '@remirror/extension-image';
import { LinkExtension } from '@remirror/extension-link';
import { BulletListExtension, OrderedListExtension } from '@remirror/extension-list';
import { Remirror, useRemirror, useRemirrorContext } from '@remirror/react';
import React, { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { htmlToProsemirrorNode, prosemirrorNodeToHtml } from 'remirror';
import {
  BoldExtension,
  ItalicExtension,
  PlaceholderExtension,
  StrikeExtension,
  UnderlineExtension,
} from 'remirror/extensions';
import Button from '../Button';
import { FloatingLinkToolbar } from './FloatingLink';
import Tailbar, { triggerImageAdd } from './Tailbar';

export interface EditorRef {
  setContent: (content: any) => void;
}

const ImperativeHandle = forwardRef((_: unknown, ref: Ref<EditorRef>) => {
  const { setContent } = useRemirrorContext({
    autoUpdate: true,
  });
  // Expose content handling to outside
  useImperativeHandle(ref, () => ({ setContent }));
  return <></>;
});

export type ToolbarItem =
  | 'undo'
  | 'redo'
  | 'bold'
  | 'italic'
  | 'strike'
  | 'underline'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'listUnordered'
  | 'listOrdered'
  | 'variables';

declare interface ProseEditorProps {
  value: string;
  options?: {
    placeholder?: {
      text: string;
    };
    toolbar: Array<ToolbarItem>;
  };
  onAssignVar?: (v: any) => void;
  onChange: (value: string) => void;
  onImageAdd?: (files: File[]) => Promise<WithSrc[]>;
  disabled?: boolean;
  vars?: any;
}

const ProseEditor = (props: ProseEditorProps) => {
  const { value: valueProp, onChange, onAssignVar, onImageAdd, options, disabled = false, vars } = props as any;
  const editorRef = useRef<EditorRef | null>(null);

  const extensions = useCallback(() => {
    return [
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new StrikeExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ImageExtension({ enableResizing: true }),
      new DropCursorExtension(),
      new LinkExtension({ autoLink: true }),
      new HistoryExtension({}),
      new HeadingExtension({}),
      new PlaceholderExtension({ placeholder: options?.placeholder?.text }),
    ];
  }, [options]);

  const { manager, state, setState } = useRemirror({
    extensions,
    stringHandler: 'html',
    content: valueProp,
  });

  const internalValue = useRef<string>('');

  useEffect(() => {
    if (valueProp !== internalValue.current) {
      // console.log('value changed from outside', valueProp, value);
      const doc = htmlToProsemirrorNode({
        content: valueProp || '',
        schema: state.schema,
      });
      // https://remirror.io/docs/faq/#q-how-to-replace-the-content-in-the-editor
      editorRef.current!.setContent(doc);
      internalValue.current = valueProp;
    }
  }, [valueProp]);

  return (
    <div className="border dark:border-gray-500 rounded-lg" spellCheck={false}>
      <Remirror
        editable={!disabled}
        manager={manager}
        initialContent={state}
        classNames={[
          'w-full max-w-full min-h-16 max-h-96 overflow-y-auto border-1 border-gray-200 dark:text-white rounded-b-md prose prose-sm p-2 prose-p:my-0 focus:outline-none bg-white !text-black',
          disabled && 'bg-gray-200 dark:bg-gray-900',
        ]}
        autoRender="end"
        onChange={(parameter) => {
          const html = prosemirrorNodeToHtml(parameter.state.doc);
          onChange(html || '');
          setState(parameter.state);
          // setValue(html);
          internalValue.current = html;
        }}
      >
        <Tailbar vars={vars} options={options} onAssignVar={onAssignVar} onImageAdd={onImageAdd} />
        <FloatingLinkToolbar />
        <ImperativeHandle ref={editorRef} />
      </Remirror>
    </div>
  );
};

export default ProseEditor;

// wrap editor in react-hook-form controller

export function ProseInput(props: any) {
  const { control, name, defaultValue, rules, onImageAdd, disabled = false } = props;
  return (
    <Controller
      render={({ field }) => (
        <ProseEditor disabled={disabled} value={field.value} onChange={field.onChange} onImageAdd={onImageAdd} />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
}

export function ProseInputExample() {
  const { control, reset, watch } = useForm({
    defaultValues: { prose: '<p>Hello</p>' },
  });
  const value = watch('prose');
  return (
    <div className="space-y-4">
      <Button
        onClick={() => {
          reset({
            prose: `<p>${Date.now()}</p>`,
          });
        }}
      >
        change from outside
      </Button>
      <Button
        onClick={() => {
          triggerImageAdd('https://unsplash.it/200/200');
        }}
      >
        add image from outside
      </Button>
      <ProseInput
        control={control}
        name="prose"
        onImageAdd={async (files) => {
          // await new Promise((resolve) => setTimeout(resolve, 1000));
          // const _urls = await Promise.all(
          //   files.map((file) => getFileUrl(file))
          // );
          // return _urls.map((src) => ({ src }));
        }}
      />
      <div>
        <code>{value}</code>
      </div>
      <h3>disabled</h3>

      <ProseInput control={control} name="prose" disabled={true} />
    </div>
  );
}
