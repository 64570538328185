export const GLOBALTEMPLATEVAR = [
  {
    identifier: 'membership.consumerism',
    value: '{{consumerism}}',
    description: 'Konsumentenschutz',
    placeholder:
      'Est ex culpa esse. Et id eiusmod nisi. Fugiat nisi adipisicing anim ex. Eu tempor ad non sint qui ex qui. Voluptate nulla sunt exercitation irure esse.',
  },
  {
    identifier: 'membership',
    value: '{{membership}}',
    description: 'Name der Mitgliedschaft',
    placeholder: 'monatlich kündbar',
  },
  {
    identifier: 'activated',
    value: '{{activated}}',
    description: 'Antrag aktiviert',
    placeholder: true,
  },
  {
    identifier: 'newsletterTerms',
    value: '{{newsletterTerms}}',
    description: 'Newsletter-Bedingungen',
    placeholder: 'Ich aboniere den Newsletter.',
  },
  {
    identifier: 'sepaTerms',
    value: '{{sepaTerms}}',
    description: 'Sepa-Bedingungen',
    placeholder: 'Ich akzeptiere die Sepa Bedingungen.',
  },
  {
    identifier: 'requestDate',
    value: '{{requestDate | date}}',
    description: 'Antragsdatum',
    placeholder: '12.12.2002',
  },
  {
    identifier: 'club.name',
    value: '{{club.name}}',
    description: 'Name des Clubs',
    placeholder: 'ODF_STUDIO_01',
  },
  {
    identifier: 'club.owner',
    value: '{{club.owner}}',
    description: 'Inhaber des Clubs',
    placeholder: 'Happy Systems GmbH',
  },
  {
    identifier: 'club.sepaUci',
    value: '{{club.sepaUci}}',
    description: 'Creditor ID des Clubs',
    placeholder: 'DE34ZZZ00000333619',
  },
  {
    identifier: 'club.uStId',
    value: '{{club.uStId}}',
    description: 'USt-ID des Clubs',
    placeholder: 'SN123456',
  },
  {
    identifier: 'club.bankAccount.bankName',
    value: '{{club.bankAccount.bankName}}',
    description: 'Bank Name des Clubs',
    placeholder: 'SPK Kraichgau',
  },
  {
    identifier: 'club.bankAccount.bic',
    value: '{{club.bankAccount.bic}}',
    description: 'BIC der Bank des Clubs',
    placeholder: 'BRUSDE66XXX',
  },
  {
    identifier: 'club.bankAccount.iban',
    value: '{{club.bankAccount.iban}}',
    description: 'IBAN des Club Bank Kontos',
    placeholder: 'DE30663500360007031008',
  },
  {
    identifier: 'club.bankAccount.accountOwner',
    value: '{{club.bankAccount.accountOwner}}',
    description: 'Inhaber des Club Bank Kontos',
    placeholder: 'Happy Systems GmbH',
  },
  {
    identifier: 'club.address.street',
    value: '{{club.address.street}}',
    description: 'Adresse des Clubs: Straße',
    placeholder: 'Am Leinwedel 67',
  },
  {
    identifier: 'club.address.zipCode',
    value: '{{club.address.zipCode}}',
    description: 'Adresse des Clubs: Postleitzahl',
    placeholder: '74889',
  },
  {
    identifier: 'club.address.city',
    value: '{{club.address.city}}',
    description: 'Adresse des Clubs: Stadt',
    placeholder: 'Sinsheim',
  },
  {
    identifier: 'club.address.countryCode',
    value: '{{club.address.countryCode}}',
    description: 'Adresse des Clubs: Land',
    placeholder: 'DE',
  },
  {
    identifier: 'club.contact.email',
    value: '{{club.contact.email}}',
    description: 'Email-Adresse des Clubs',
    placeholder: 'service@hector.de',
  },
  {
    identifier: 'club.contact.mobile',
    value: '{{club.contact.mobile}}',
    description: 'Handy-Nummer des Clubs',
    placeholder: '07000 1000 339',
  },
  {
    identifier: 'club.contact.phone',
    value: '{{club.contact.phone}}',
    description: 'Telefon-Nummer des Clubs',
    placeholder: '07000 1000 339',
  },
  {
    identifier: 'club.contact.telefax',
    value: '{{club.contact.telefax}}',
    description: 'Fax-Nummer des Clubs',
    placeholder: '07261 94 88 20',
  },
  {
    identifier: 'customer.id',
    value: '{{customer.id}}',
    description: 'Mitgliedsnummer',
    placeholder: '123456',
  },
  {
    identifier: 'customer.company',
    value: '{{customer.company}}',
    description: 'Firma des Mitglieds',
    placeholder: 'Sport GmbH',
  },
  {
    identifier: 'customer.personalData.title',
    value: '{{customer.personalData.title}}',
    description: 'Titel des Mitglieds',
    placeholder: 'Dr.',
  },
  {
    identifier: 'customer.personalData.gender',
    value: '{{customer.personalData.gender}}',
    description: 'Geschlecht des Mitglieds',
    placeholder: 'm',
  },
  {
    identifier: 'customer.personalData.name',
    value: '{{customer.personalData.name}}',
    description: 'Vorname des Mitglieds',
    placeholder: 'Heinz',
  },
  {
    identifier: 'customer.personalData.surname',
    value: '{{customer.personalData.surname}}',
    description: 'Nachname des Mitglieds',
    placeholder: 'Tester',
  },
  {
    identifier: 'customer.personalData.birthday',
    value: '{{customer.personalData.birthday | date}}',
    description: 'Geburtstag des Mitglieds',
    placeholder: '12.12.1990',
  },
  {
    identifier: 'customer.paymentInformation.bankAccount.bankName',
    value: '{{customer.paymentInformation.bankAccount.bankName}}',
    description: 'Bankname des Mitglieds',
    placeholder: 'Test Bank',
  },
  {
    identifier: 'customer.paymentInformation.bankAccount.bic',
    value: '{{customer.paymentInformation.bankAccount.bic}}',
    description: 'BIC der Bank des Mitglieds',
    placeholder: 'TEST123BIC',
  },
  {
    identifier: 'customer.paymentInformation.bankAccount.iban',
    value: '{{customer.paymentInformation.bankAccount.iban}}',
    description: 'IBAN des Mitglieds',
    placeholder: 'DE98ZZZ09999999999',
  },
  {
    identifier: 'customer.paymentInformation.bankAccount.accountOwner',
    value: '{{customer.paymentInformation.bankAccount.accountOwner}}',
    description: 'Kontoinhaber des Mitglieds',
    placeholder: 'Horst Maier',
  },
  {
    identifier: 'customer.paymentInformation.sepaMandate.mandateReference',
    value: '{{customer.paymentInformation.sepaMandate.mandateReference}}',
    description: 'SEPA-Mandat Referenz',
    placeholder: 'SEPA2323-xxx',
  },
  {
    identifier: 'customer.paymentInformation.sepaMandate.signatureDate',
    value: '{{customer.paymentInformation.sepaMandate.signatureDate | date}}',
    description: 'SEPA-Mandat Datum',
    placeholder: '12.12.2002',
  },
  {
    identifier: 'customer.contact.email',
    value: '{{customer.contact.email}}',
    description: 'E-Mail Adresse des Mitglieds',
    placeholder: 'heinz@testmail.com',
  },
  {
    identifier: 'customer.contact.subscribeToNewsletter',
    value: '{{customer.contact.subscribeToNewsletter}}',
    description: 'Mitglied hat Newsletter aboniert',
    placeholder: true,
  },
  {
    identifier: 'customer.contact.mobile',
    value: '{{customer.contact.mobile}}',
    description: 'Mobil-Telefonnummer des Mitglieds',
    placeholder: '0160/55554444',
  },
  {
    identifier: 'customer.contact.phone',
    value: '{{customer.contact.phone}}',
    description: 'Telefonnummer des Mitglieds',
    placeholder: '07554/232332',
  },
  {
    identifier: 'customer.address.street',
    value: '{{customer.address.street}}',
    description: 'Adresse des Mitglieds: Straße',
    placeholder: 'Teststraße 31',
  },
  {
    identifier: 'customer.address.zipCode',
    value: '{{customer.address.zipCode}}',
    description: 'Adresse des Mitglieds: Postleitzahl',
    placeholder: '88888',
  },
  {
    identifier: 'customer.address.city',
    value: '{{customer.address.city}}',
    description: 'Adresse des Mitglieds: Stadt',
    placeholder: 'Teststadt',
  },
  {
    identifier: 'customer.address.countryCode',
    value: '{{customer.address.countryCode}}',
    description: 'Adresse des Mitglieds: Land',
    placeholder: 'Deutschland',
  },
  {
    identifier: 'customer.address.co',
    value: '{{customer.address.co}}',
    description: 'Adresse des Mitglieds: Adresszusatz',
    placeholder: 'Test GmbH',
  },
  // Contract
  {
    identifier: 'contract.name',
    value: '{{contract.name}}',
    description: 'Vertrag: Name',
    placeholder: 'Test-Vertrag',
  },

  {
    identifier: 'contract.minimumMembershipAge',
    value: '{{contract.minimumMembershipAge}}',
    description: 'Vertrag: Mindesalter',
    placeholder: 18,
  },
  {
    identifier: 'contract.earliestStartDate',
    value: '{{contract.earliestStartDate}}',
    description: 'Vertrag: Mitgliedschaft Startdatum',
    placeholder: '12.12.2024',
  },
  {
    identifier: 'contract.renewal',
    value: '{{contract.renewal}}',
    description: 'Vertrag: Laufzeitoption',
    placeholder: 'Mitgliedschaft verlängert sich automatisch',
  },
  {
    identifier: 'contract.trialPeriod',
    value: '{{contract.trialPeriod | interval}}',
    description: 'Vertrag: Testzeitraum',
    placeholder: '2 Wochen',
  },

  {
    identifier: 'contract.mainInterval',
    value: '{{contract.mainInterval | recurring}}',
    description: 'Vertrag: Abbuchungs HauptInterval',
    placeholder: 'pro Monat',
  },
  {
    identifier: 'contract.mainIntervalPrice',
    value: '{{contract.mainIntervalPrice | currency}}',
    description: 'Vertrag: Hauptinterval Preis',
    placeholder: '24,99 €',
  },
  {
    identifier: 'contract.discountDuration',
    value: '{{contract.discountDuration | interval}}',
    description: 'Vertrag: Rabatt Zeitraum',
    placeholder: '3 Monate',
  },
  {
    identifier: 'contract.discountInterval',
    value: '{{contract.discountInterval | recurring}}',
    description: 'Vertrag: Rabatt Abbuchungs Interval',
    placeholder: 'pro Monat',
  },
  {
    identifier: 'contract.discountIntervalPrice',
    value: '{{contract.discountIntervalPrice | currency}}',
    description: 'Vertrag: Rabatt Preis',
    placeholder: '19,99 €',
  },
  {
    identifier: 'contract.mainText[0].title',
    value: '{{contract.mainText[0].title}}',
    description: 'Vertrag: Haupttext Titel',
    placeholder: 'Titel der inkludierten Leistung',
  },
  {
    identifier: 'contract.mainText[0].body',
    value: '{{contract.mainText[0].body}}',
    description: 'Vertrag: Haupttext',
    placeholder:
      'Lorem ipsum dolor sit amet, qui minim labore adipisicing minim sint cillum sint consectetur cupidatat.',
  },
  {
    identifier: 'contract.begin',
    value: '{{contract.begin | date}}',
    description: 'Vertrag: Beginn',
    placeholder: '12.12.2002',
  },
  {
    identifier: 'contract.possibleDateOfEnd',
    value: '{{contract.possibleDateOfEnd | date}}',
    description: 'Vertrag: Mögliches Vertragsende',
    placeholder: '12.12.2002',
  },
];

export const ADDON = [
  {
    identifier: 'addonName',
    value: '{{item.name}}',
    description: 'Name des Addons',
    placeholder: 'Addon Name',
  },

  {
    identifier: 'addonDescription',
    value: '{{item.description}}',
    description: 'Addons: Beschreibung des Addons',
    placeholder: `Hier steht ein etwas längerer Text der das Addon beschreibt.
      z.B. Das MYGYM Startpaket beinhaltet das Club-Booklet mit Trainings- und Ernährungsempfehlungen, 
      die Teilnahme an den Geräteeinweisungen in Kleingruppen, sowie einen persönlichen Welcome-Termin. 
      Zusätzlich inkludiert sind  2 BUDDY4FREE-Cards, 
      womit jeweils 4 Wochen kostenloses Training verschenkt werden können.`,
  },

  {
    identifier: 'addonPrice',
    value: '{{item.price | currency}}',
    description: 'Addons: Preis',
    placeholder: '120,20 €',
  },

  {
    identifier: 'addonInterval',
    value: '{{item.interval | recurring}}',
    description: 'Addons: Zeitraum des Addons ',
    placeholder: 'pro Monat',
  },

  {
    identifier: 'addonDisplayPrice',
    value: '{{item.displayPrice | currency}}',
    description: 'Addons: Anzeige Preis des Addons ',
    placeholder: '12,20 €',
  },
  {
    identifier: 'addonDisplayInterval',
    value: '{{item.displayInterval | recurring}}',
    description: 'Addons: Anzeige Interval des Addons ',
    placeholder: 'pro Monat',
  },
  {
    identifier: 'addonTrialPeriod',
    value: '{{item.trialPeriod | interval}}',
    description: 'Addons: Testphase des Addons ',
    placeholder: '1 Monat',
  },
];

export const DISCOUNT = [
  {
    identifier: 'discount.name',
    value: '{{item.name}}',
    description: 'Rabatt: Name',
    placeholder: 'Rabatt Name',
  },
  {
    identifier: 'discount.description',
    value: '{{item.description}}',
    description: 'Rabatt: Beschreibung',
    placeholder: 'Rabatt Name',
  },

  {
    identifier: 'discount.price',
    value: '{{item.price | currency}}',
    description: 'Rabatt: Preis ',
    placeholder: '-12,20 €',
  },

  {
    identifier: 'discount.duration',
    value: '{{item.duration | interval}}',
    description: 'Gebühr: Zeitraum der Gebühr',
    placeholder: '4 Wochen',
  },

  {
    identifier: 'discount.age',
    value: '{{item.age}}',
    description: 'Rabatt: Max Alter',
    placeholder: '12',
  },
];

export const FEE = [
  {
    identifier: 'feeName',
    value: '{{item.name}}',
    description: 'Gebühr: Kurzbezeicnung der Gebühr',
    placeholder: 'Gebühr Name',
  },

  {
    identifier: 'feeTitle',
    value: '{{item.title}}',
    description: 'Gebühr: Name der Gebühr',
    placeholder: 'Gebühr Name',
  },
  {
    identifier: 'feeBody',
    value: '{{item.body}}',
    description: 'Gebühr: Beschreibung der Gebühr',
    placeholder: `Hier steht ein etwas längerer Text der die Gebühr beschreibt.
      z.B. Elektrolygetränke an der Getränkebar zapfen, während des Trainings`,
  },

  {
    identifier: 'feePrice',
    value: '{{item.price | currency}}',
    description: 'Gebühr: Preis der Gebühr',
    placeholder: '120,20 €',
  },

  {
    identifier: 'feeInterval',
    value: '{{item.interval | recurring}}',
    description: 'Gebühr: Zeitraum der Gebühr',
    placeholder: 'alle 4 Wochen',
  },

  {
    identifier: 'feeDue',
    value: '{{item.due}}',
    description: 'Gebühr: Erste Abbuchung der Gebühr',
    placeholder: 'nach Testphase',
  },
];
