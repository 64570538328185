import 'easymde/dist/easymde.min.css';
import React, { Fragment, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import SimpleMDE from 'react-simplemde-editor';
import Badge from './Badge';
import Form from './Form';

function TextareaWithVariables({
  defaultValue,
  name,
  variables,
}: {
  defaultValue: string;
  name: string;
  variables: { name: string; value: string }[];
}) {
  const textArea = useRef(null);

  function insertVariable(variable) {
    const { selectionStart, selectionEnd } = textArea.current;
    textArea.current.setRangeText(`{{${variable}}}`, selectionStart, selectionEnd, 'end');
  }

  return (
    <>
      <textarea ref={textArea} className={Form.Item.text} rows={5} defaultValue={defaultValue} name={name} />
      <div className="mt-1">
        <small>
          Verfügbare Bausteine:{' '}
          {variables?.map((v, i) => (
            <Badge key={i} onClick={() => insertVariable(v.value)} className="!py-0.5 text-xs ml-1">
              {v.name}
            </Badge>
          ))}
        </small>
      </div>
    </>
  );
}

const mdeOptions = {
  spellChecker: false,
  toolbar: ['bold', 'italic', 'strikethrough'],
};
export function TextareaWithVariablesInput({
  control,
  name,
  variables,
  rules,
  markdown,
}: {
  control: Control;
  name: string;
  variables: { name: string; value: string }[];
  rules?: any;
  markdown?: boolean;
}) {
  const textArea = useRef(null);
  const mdEditor = useRef(null);

  function insertVariable(variable) {
    if (markdown) {
      const pos = mdEditor.current.codemirror.getCursor();
      mdEditor.current.codemirror.replaceRange(`{{${variable}}}`, pos);
      return mdEditor.current.value();
    } else {
      const { selectionStart, selectionEnd } = textArea.current;
      textArea.current.setRangeText(`{{${variable}}}`, selectionStart, selectionEnd, 'end');
      return textArea.current.value;
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Fragment key="test">
          {markdown ? (
            <SimpleMDE
              getMdeInstance={(instance) => (mdEditor.current = instance)}
              ref={mdEditor}
              options={mdeOptions as any}
              value={field.value}
              onChange={(value) => field.onChange(value)}
            />
          ) : (
            <textarea
              ref={((el) => field.ref(el)) && textArea}
              className={Form.Item.text}
              rows={5}
              value={field.value}
              onChange={field.onChange}
              name={field.name}
              onBlur={field.onBlur}
            />
          )}
          <div className="mt-1">
            <small>
              Verfügbare Bausteine:{' '}
              {variables?.map((v, i) => (
                <Badge
                  key={i}
                  onClick={() => {
                    field.onChange(insertVariable(v.value));
                    textArea.current?.focus();
                  }}
                  className="!py-0.5 text-xs ml-1"
                >
                  {v.name}
                </Badge>
              ))}
            </small>
          </div>
        </Fragment>
      )}
    />
  );
}

export function WithVariablesInput({
  control,
  name,
  variables,
  rules,
}: {
  control: Control;
  name: string;
  variables: { name: string; value: string }[];
  rules?: any;
}) {
  const textArea = useRef(null);

  function insertVariable(variable) {
    const { selectionStart, selectionEnd } = textArea.current;
    textArea.current.setRangeText(`{{${variable}}}`, selectionStart, selectionEnd, 'end');
    return textArea.current.value;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <>
          <input
            type={'text'}
            ref={((el) => field.ref(el)) && textArea}
            className={Form.Item.text}
            value={field.value}
            onChange={field.onChange}
            name={field.name}
            onBlur={field.onBlur}
          />
          <div className="mt-1">
            <small>
              Verfügbare Bausteine:{' '}
              {variables?.map((v, i) => (
                <Badge key={i} onClick={() => field.onChange(insertVariable(v.value))} className="!py-0.5 text-xs ml-1">
                  {v.name}
                </Badge>
              ))}
            </small>
          </div>
        </>
      )}
    />
  );
}

export default TextareaWithVariables;
