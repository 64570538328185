import React, { useEffect } from 'react';
import { create } from 'zustand';
import Button from '../components/Button';
import Input from '../components/Input';

const store = (set) => ({
  prompt: null,
  setPrompt: (prompt) => set({ prompt }),
  result: '',
  setResult: (result) => set({ result }),
  userResolved: null,
  userRejected: null,
  promptUser: async (message: string, defaultValue?: string) => {
    set({ prompt: message });
    if (defaultValue) set({ result: defaultValue });
    return new Promise<string>((resolve, reject) => {
      set({ userResolved: resolve });
      set({ userRejected: reject });
    });
  },
});

const usePromptStore = create<ReturnType<typeof store>>(store);

const Prompt = ({ config }) => {
  const { prompt, setPrompt, result, setResult, userResolved, userRejected } = usePromptStore();

  useEffect(() => {
    const abortPrompt = (e) => {
      if (e.key === 'Escape') {
        userRejected?.('');
        setPrompt(null);
        setResult(null);
      }
    };

    window.addEventListener('keydown', abortPrompt);

    return () => {
      window.removeEventListener('keydown', abortPrompt);
    };
  }, []);

  if (!prompt) return null;
  return (
    <div
      ref={(me) =>
        me
          ? (me.style.left = `calc(50${config?.relative ? '%' : 'vw'} - ${me.getBoundingClientRect().width / 2}px )`)
          : null
      }
      className="fixed top-[12vh] z-[999999999] left-[50vw] min-w-[320px] rounded-md shadow-md bg-white dark:bg-gray-600 p-6 "
    >
      <div>
        <p className="text-sm mb-3 dark:text-white">{prompt}</p>
        <Input
          ref={(me) => me?.focus()}
          type="text"
          value={result}
          onChange={(e) => setResult(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              userResolved?.(result);
              setPrompt(null);
              setResult(null);
            }
          }}
        />
        <div className="flex gap-3 mt-3 justify-end">
          <Button
            $secondary
            onClick={() => {
              userRejected?.('');
              setPrompt(null);
              setResult(null);
            }}
          >
            Abbrechen
          </Button>
          <Button
            $primary
            onClick={() => {
              userResolved?.(result);
              setPrompt(null);
              setResult(null);
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default function usePrompt() {
  const { promptUser } = usePromptStore();

  return {
    promptUser,
    PromptProvider: Prompt,
  };
}
