import env from '@/environment';
import { shortenUUID } from '@/util/shortenUUID';
import { PublicAPI } from 'ec.sdk';
import useSWRImmutable from 'swr/immutable';
import useShortID from './useShortID';

declare const window: any;

function useSettings(options: any = { revalidateOnFocus: false, dedupingInterval: 30000 }) {
  const shortID = useShortID();
  return useSWRImmutable(shortID && ['resource/settings', shortID], () => fetchSettings(shortID), options);
}

export async function fetchSettings(shortID) {
  try {
    if (window.HO_EMBED) {
      throw new Error('ho token');
    }
    const api = new PublicAPI(shortID, env.env, true);
    const {
      items: [settings],
    } = await api.entryList('settings', { _count: 1, sort: ['_created'] });
    return settings;
  } catch (err) {
    // use fallback for ho token
    const {
      config: { appID },
    } = await fetch(`https://datamanager${env.env === 'live' ? '' : '.cachena'}.entrecode.de/api/${shortID}`).then(
      (res) => res.json(),
    );

    const settings = await fetch(
      `https://appsite${env.env === 'live' ? '' : '.cachena'}.entrecode.de/stage/${shortenUUID(appID)}/api/settings`,
    ).then((res) => res.json());

    return settings;
  }
}

export default useSettings;
