import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from '../util/classNames';

declare interface ToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  secondaryLabel?: string;
  small?: boolean;
  disabled?: boolean;
}

export default function Toggle(props: ToggleProps) {
  const { label, secondaryLabel, onChange, value, small, disabled } = props;
  const [checked, setChecked] = useState(value ?? false);

  const handleChange = (value) => {
    if (!disabled) {
      setChecked(value);
      onChange?.(value);
    }
  };

  useEffect(() => {
    if (value !== undefined && checked !== value) {
      setChecked(value);
    }
  }, [value]);

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        onChange={handleChange}
        className={classNames(
          checked ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-500',

          'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          small ? 'h-4 w-8' : 'h-6 w-11',
          disabled ? 'opacity-50 !cursor-not-allowed' : '',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? (small ? 'translate-x-4' : 'translate-x-5') : 'translate-x-0',
            'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            small ? 'h-3 w-3' : 'h-5 w-5',
          )}
        />
      </Switch>
      {!!(label || secondaryLabel) && (
        <Switch.Label as="span" className="ml-3">
          <span
            className={classNames(
              'font-medium text-gray-900 dark:text-white',
              small ? 'text-xs' : 'text-sm',
              disabled ? 'opacity-50 !cursor-not-allowed' : '',
            )}
          >
            {label}
          </span>
          {!!secondaryLabel && (
            <>
              <br />
              <span className="flex leading-snug text-xs text-gray-500 dark:text-white-500">{secondaryLabel}</span>
            </>
          )}
        </Switch.Label>
      )}
    </Switch.Group>
  );
}

declare interface ToggleInputProps extends Omit<ToggleProps, 'value' | 'onChange'> {
  control: any;
  name: string;
  defaultValue?: boolean;
  rules?: any;
  disabled?: boolean;
  invertValue?: boolean;
}

export function ToggleInput(props: ToggleInputProps) {
  const { control, disabled = false, label, secondaryLabel, name, defaultValue, rules, invertValue = false } = props;
  return (
    <Controller
      render={({ field }) => (
        <Toggle
          disabled={disabled}
          label={label}
          secondaryLabel={secondaryLabel}
          value={invertValue ? !field.value : field.value}
          onChange={(e) => field.onChange(invertValue ? !e : e)}
        />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
}

export const ToggleExample = () => {
  const [checked, setChecked] = useState(false);
  return <Toggle label="Hallo" value={checked} onChange={setChecked} />;
};
