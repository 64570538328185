import { useStripe } from '@/hooks/useStripe';
import React, { useEffect } from 'react';
import SimpleSelect from './SimpleSelect';

function LegalEntitySelect(props: {
  /** Label for empty value, defaults to "Alle Backends" */
  defaultLabel?: string;
  /** Placeholder when nothing is selected */
  placeholder?: string;
  /** Prefix for backend item labels */
  prefix?: string;
  /** current value (backendID) */
  value?: string;
  /** onChange callback */
  onChange?: (value: string) => void;
  /** extra classes for SimpleSelect */
  className?: string;
  /** if true, there will be no selectable option for an empty value */
  excludeEmpty?: boolean;

  listIfEmpty?: boolean;
  setIfOnlyOne?: boolean;

  /** used to persist Selection between Navigation */
  persistAs?: string;
}) {
  const {
    defaultLabel = 'Alle Betreiber',
    prefix = '',
    value,
    onChange,
    className,
    excludeEmpty,
    listIfEmpty = false,
    setIfOnlyOne = false,
    placeholder = 'Betreiber wählen...',
  } = props;

  const { legalEntities } = useStripe();
  const backendItems = [
    ...(!excludeEmpty ? [{ label: defaultLabel, value: null }] : []),
    ...legalEntities.map((legal) => ({ label: `${legal.commercialRegisterName}`, value: legal.id })),
  ];

  useEffect(() => {
    if (setIfOnlyOne && legalEntities.length <= 1 && backendItems?.length === 1 && !value) {
      console.log('inside', 'value');
      // onChange(legalEntities[0].id);
    }
  }, [legalEntities, listIfEmpty, setIfOnlyOne, value, onChange]);

  // if (legalEntities.length <= 2) {
  //   return listIfEmpty ? <Input type="text" disabled value={legalEntities?.[value]} /> : <></>;
  // }

  return (
    <SimpleSelect
      persistAs={props.persistAs}
      items={backendItems}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
}

// just do a global search with "<BackendSelect" to see example usage
// all you need is "backends" + "setBackends" which are provided by useBackends or useSharedResource

export default LegalEntitySelect;
