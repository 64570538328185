import { classNames } from '@/util/classNames';
import dayjs, { Dayjs } from '@/util/dayjs';
import React, { useEffect, useMemo, useState } from 'react';

export default function Calendar({
  value,
  onChange,
  filterDate = () => false,
}: {
  value: string;
  onChange: (value: Dayjs) => void;
  filterDate?: (day: any) => boolean;
}) {
  const [month, setMonth] = useState(dayjs().startOf('month'));
  const [day, setDay] = useState(dayjs().startOf('day'));
  const today = useMemo(() => dayjs().startOf('day'), []);

  useEffect(() => {
    const date = dayjs(value);
    if (value && date.isValid()) {
      setDay(date.clone().startOf('day'));
      setMonth(date.clone().startOf('month'));
    }
  }, [value]);
  const changeDay = (d) => {
    setDay(d);
    onChange?.(d);
  };

  const weeks = Array(Math.ceil((month.daysInMonth() + month.weekday()) / 7))
    .fill(0)
    .map((_, i) => month.startOf('week').startOf('day').clone().add(i, 'week'));
  // markup from https://tailwinduikit.com/components/webapp/calendar/calendar (Calendar 1)
  return (
    <div className="flex items-center justify-center select-none">
      <div className="w-full rounded-xl shadow-lg md:p-6 p-3 bg-white dark:bg-gray-800">
        <div className="px-2 flex items-center justify-between leading-8">
          <h1 className="text-xl font-medium text-gray-800 dark:text-gray-100">{month.format('MMMM YYYY')}</h1>
          <div className="flex items-center text-gray-800 dark:text-gray-100">
            <a className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-left"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => {
                  setMonth(month.subtract(1, 'month').startOf('month'));
                }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="15 6 9 12 15 18" />
              </svg>
            </a>
            <a className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler ml-3 icon-tabler-chevron-right"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => {
                  setMonth(month.add(1, 'month').startOf('month'));
                }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex items-center justify-between pt-6 overflow-x-auto ">
          <table className="w-full">
            <thead>
              <tr>
                {Array(7)
                  .fill(0)
                  .map((_, i) => dayjs().startOf('week').add(i, 'days').format('dd'))
                  .map((d) => (
                    <th key={d}>
                      <div className="w-full flex justify-center">
                        <p className="text-md mb-0 font-medium text-center text-gray-800 dark:text-gray-100">{d}</p>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {weeks.map((weekStart, w) => (
                <tr key={w}>
                  {Array(7)
                    .fill(0)
                    .map((_, i) => {
                      const d = weekStart.clone().add(i, 'days').startOf('day');
                      if (day?.isSame(d, 'day')) {
                        return (
                          <td key={i} className={classNames(w === 0 && 'pt-3')}>
                            <div className="w-full h-full">
                              <div className="flex items-center justify-center w-full rounded-full cursor-pointer">
                                <p className="text-md mb-0 w-8 h-8 flex items-center justify-center font-medium text-white bg-indigo-700 rounded-full">
                                  {d.format('D')}
                                </p>
                              </div>
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td
                          key={i}
                          className={classNames(
                            w === 0 && 'pt-3',
                            filterDate(d) ? 'line-through pointer-events-none' : '',
                          )}
                          onClick={() => changeDay(d)}
                        >
                          <div className="px-2 py-4 cursor-pointer flex w-full justify-center">
                            <p
                              className={classNames(
                                'text-md mb-0 ',
                                !d.isSame(month, 'month') && 'opacity-20',
                                d.isSame(today, 'day')
                                  ? 'text-indigo-600 dark:text-indigo-300 font-bold'
                                  : 'text-gray-500 dark:text-gray-100',
                              )}
                            >
                              {d.format('D')}
                            </p>
                          </div>
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
