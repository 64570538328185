import dayjs, { Dayjs } from 'dayjs';
import de from 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale(de);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(dayOfYear);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

// returns true if the input string is a valid date of given format.
// if no format is given, str is assumed to be an ISO date
// this works around the fact that dayjs thinks "15.20.2023" is valid in "DD.MM.YYYY"...
export const isStrictlyValid = (str?: string | null, format?: string) => {
  if (!str) {
    return false;
  }
  const day = format ? dayjs(str, format) : dayjs(str);
  if (format) {
    return day.isValid() && day.format(format) === str;
  }
  return day.isValid() && day.toISOString() === str;
};

export { Dayjs };

export default dayjs;
