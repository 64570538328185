import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Spinner from '@/components/Spinner';
import { useDSB } from '@/hooks/useDSB';
import useLocalStorage from '@/hooks/useLocalStorage';
import { AdminNews } from '@entrecode/dm-types/dsb-layer/AdminNews';
import dayjs from 'dayjs';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import useSWR from 'swr';

function useCookie(name: string) {
  const cookie = Cookies.get(name);
  return [cookie, (value: string, options: Cookies.CookieAttributes) => Cookies.set(name, value, options)];
}

export const NewsModal = () => {
  const { dsbApi } = useDSB();
  const { data: latestNews, isLoading } = useSWR(
    dsbApi ? '/admin/news/latest' : null,
    async () => (await dsbApi.entryList('admin_news')).getFirstItem() as AdminNews & EntryResource,
  );
  const [lastNewsShown, setLastNewsShown] = useLocalStorage('lastNewsShown', ''); // newsID@YYYY-MM-DD
  const [open, setOpen] = useState(false);
  const [lastNewsCheck, setLastNewsCheck] = useCookie('lastNewsCheck');

  // sessionCookie mit lastNewsChecke
  React.useEffect(() => {
    if (!latestNews) return;
    if (lastNewsCheck === 'true') {
      return; // news wurden heute bereits angezeigt
    } else {
      if (lastNewsShown.split('@')[0] === latestNews?.id) {
        return; // news beitrag wurde bereits gelesen
      } else {
        if (dayjs().isBetween(latestNews.startDate, latestNews.endDate)) {
          setOpen(true);
        }
        return;
      }
    }
  }, [lastNewsCheck, lastNewsShown, latestNews]);

  function onRead() {
    // nachricht ist gelesen und wird nichtmehr angezeigt
    setOpen(false);
    setLastNewsCheck('true', { expires: dayjs().endOf('day').toDate() });
    setLastNewsShown(`${latestNews?.id}@${dayjs().format('YYYY-MM-DD')}`);
  }

  function onReadLater() {
    // nachricht wird am nächsten Tag wieder angeezeigt
    setOpen(false);
    setLastNewsCheck('true', { expires: dayjs().endOf('day').toDate() });
  }

  const { data: imageUrl, isLoading: imageUrlLoading } = useSWR(
    latestNews ? latestNews.titleAsset.assetID + '/asset' : null,
    async () => {
      return latestNews.titleAsset?.getImageUrl();
    },
  );

  return (
    <Modal open={open} onClose={() => onReadLater()}>
      <div className="flex flex-col overflow-auto max-h-[600px] max-w-xl">
        <div className="w-full h-[300px] bg-gray-50 mb-2 ">
          {imageUrlLoading ? <Spinner /> : <img src={imageUrl} className="w-full h-full object-cover" />}
        </div>
        <h2 className="text-2xl front-semibold">{latestNews?.title}</h2>
        <i>{latestNews?.intro}</i>
        <div className="mt-3"></div>
        {/* <style> */}
        {/*   {` */}
        {/*   #news-content ul { margin: 10px;} */}
        {/*   #news-content ul li { */}
        {/*     list-style: disc; */}
        {/*     list-style: disc; */}
        {/*     list-style-position: inside; */}
        {/*   } */}
        {/*   #news-content ul li * {display: inline} */}
        {/* `} */}
        {/* </style> */}
        {/**/}
        {/* <div id="news-content" dangerouslySetInnerHTML={{ __html: latestNews?.text }} /> */}
        <div className="flex justify-end gap-3 mt-6 sticky bottom-0 pt-2">
          <Button onClick={onReadLater}>Später erinnern</Button>
          <Button $primary onClick={onRead}>
            Nicht mehr anzeigen
          </Button>
        </div>
      </div>
    </Modal>
  );
};
