import { environment as env } from 'ec.sdk/lib/Core';

type EnviromentVars = {
  tasksServiceUrl: string;
  hecInfoServiceUrl: string;
  hecInfoUrl: string;
  dsbLayerUrl: string;
  clubappApiUrl: string;
  hecRenderUrl: string;
  accountServerUrl: string;
  marketingToken: string;
  hecUserGroupId: string;
  dsbShortID: string;
  production: string;
  embedUrl: string;
  env: env;
};

const environment = Object.fromEntries(
  Object.entries(import.meta.env)
    .filter(([key]) => key.startsWith('VITE_'))
    .map(([key, value]) => {
      return [key.replace('VITE_', ''), value];
    }),
);

export default environment as EnviromentVars;
