import { PublicAPI } from 'ec.sdk';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import environment from '../environment';
import { request } from '../util/request';
import { withParams } from '../util/withParams';
import useSettings from './useSettings';

// hallo
/* [markdown]
 * Like [useAppsite](./useAppsite.tsx), but with token. TODO: merge with useAppsite (uses same api, only with token..).
 */
export const useDSB = () => {
  const dsbApi = useMemo(() => new PublicAPI(environment.dsbShortID, environment.env, true), []);
  // const { api } = useSdk();

  const { data: settings } = useSettings();

  const routeURL = useCallback(
    (route) => {
      if (!settings) {
        return null;
      }
      const { appsiteUrl } = settings;
      const base = new URL(appsiteUrl + (!appsiteUrl.endsWith('/') ? '/' : '')); // https://dsb-layer.cachena.entrecode.de/{seoTitle}/ // please leave slash 🥺
      const url = base.href + route;
      return url;
    },
    [settings],
  );

  const fetcher = useCallback(
    async (method, url, body?, headers = {}) => {
      return request(url, method, body, headers).catch((err) => {
        console.error('ERRRR', err);
        throw err;
      });
    },
    [settings],
  );
  const get = (route) => fetcher('get', routeURL(route), null, {});
  const put = (route, body) => fetcher('put', routeURL(route), body);
  const post = (route, body, headers?) => fetcher('post', routeURL(route), body, headers);
  const del = (route, body, headers?) => fetcher('delete', routeURL(route), body, headers);
  return {
    get,
    put,
    post,
    del,
    swr: (route, params?, swrOptions = {}) => {
      const url = routeURL(route);
      return useSWR(url ? withParams(url, params) : null, (url) => fetcher('get', url), swrOptions);
    },
    dsbApi,
  };
};
