export const hectorIntervals = [
  { label: 'einmalig', value: 'NULL' },
  { label: 'pro Tag', value: 'P1D' },
  { label: 'pro Woche', value: 'P1W' },
  { label: '2-wöchentlich', value: 'P2W' },
  { label: '4-wöchentlich', value: 'P4W' },
  { label: 'pro Kalendermonat', value: 'P1M' },
  { label: 'pro Vierteljahr', value: 'P3M' },
  { label: 'pro Halbjahr', value: 'P6M' },
  { label: 'pro Jahr', value: 'P1Y' },
];
