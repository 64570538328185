import useDocumentClick from '@/hooks/useDocumentClick';
import dayjs from '@/util/dayjs';
import type { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import Input from './Input';

const format = 'DD.MM.YYYY';
export function CalendarRangeInput({
  value,
  onChange,
}: {
  value: {
    from: string;
    to: string;
  };
  onChange: (value: { from: string; to: string }) => void;
}) {
  const [showRange, setShowRange] = useState(false);
  const [touched, setTouched] = useState(false);
  const [selectedRange, setSelectedRange] = useState(
    { from: dayjs(value?.from).format(format), to: dayjs(value?.to).format(format) } || {
      from: dayjs().subtract(1, 'month').format(format),
      to: dayjs().subtract(1, 'day').format(format),
    },
  );

  useDocumentClick((e) => {
    if (!e.target.closest('#calendar-range')) {
      if (touched) console.log('cheagne', selectedRange);
      onChange({
        from: dayjs(selectedRange.from, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        to: dayjs(selectedRange.to, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      });
      setShowRange(false);
    }
    setTouched(false);
  });

  return (
    <div className="relative" id="calendar-range" onFocus={() => setShowRange(true)}>
      <Input
        type="text"
        placeholder="Zeitraum wählen..."
        value={value ? `${selectedRange.from} – ${selectedRange.to}` : null}
      />
      {showRange && selectedRange && (
        <div className="absolute z-50">
          <CalendarRange
            value={selectedRange}
            onChange={(e) => {
              setSelectedRange((prev) => ({
                from: e.from ? e.from?.format(format) : prev.from,
                to: e.to ? e.to?.format(format) : prev.to,
              }));
              setTouched(true);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default function CalendarRange({
  value,
  onChange,
}: {
  value: {
    from: string;
    to: string;
  };
  onChange: (value: { from: Dayjs; to: Dayjs }) => void;
}) {
  const [start, setStart] = useState<Dayjs>(dayjs(value.from));

  const [end, setEnd] = useState<Dayjs>(dayjs(value.to));

  const [month, setMonth] = useState(dayjs().startOf('month'));

  const changeDay = (d) => {
    if (start && end) {
      setEnd(() => null);
      setStart(() => d);
    } else if (start && !end) {
      d.isAfter(start) ? setEnd(() => d) : setStart(() => d);
      onChange({ from: start, to: end });
    }
  };

  useEffect(() => {
    onChange({
      from: start,
      to: end,
    });
  }, [start, end]);

  const weeks = Array(5)
    .fill(0)
    .map((_, i) => month.startOf('week').startOf('day').clone().add(i, 'week'));
  // markup from https://tailwinduikit.com/components/webapp/calendar/calendar (Calendar 1)
  return (
    <div className="flex items-center justify-center select-none">
      <div className="w-full rounded-xl shadow-lg md:p-6 p-3 bg-white dark:bg-gray-800">
        <div className="px-2 flex items-center justify-between leading-8">
          <h1 className="text-xl font-medium text-gray-800 dark:text-gray-100">{month.format('MMMM YYYY')}</h1>
          <div className="flex items-center text-gray-800 dark:text-gray-100">
            <a className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-left"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => {
                  setMonth(month.subtract(1, 'month').startOf('month'));
                }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="15 6 9 12 15 18" />
              </svg>
            </a>
            <a className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler ml-3 icon-tabler-chevron-right"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => {
                  setMonth(month.add(1, 'month').startOf('month'));
                }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
          </div>
        </div>
        <div>
          {start.format('DD.MM.YYYY')} - {end?.format('DD.MM.YYYY')}
        </div>
        {/*Weekdays Header*/}
        <div className="flex items-center justify-between pt-6 overflow-x-auto ">
          <table className="w-full">
            <thead>
              <tr>
                {Array(7)
                  .fill(0)
                  .map((_, i) => dayjs().startOf('week').add(i, 'days').format('dd'))
                  .map((d) => (
                    <th key={d}>
                      <div className="w-full flex justify-center">
                        <p className="text-md mb-0 font-medium text-center text-gray-800 dark:text-gray-100">{d}</p>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {weeks.map((weekStart, w) => (
                <tr key={w}>
                  {Array(7)
                    .fill(0)
                    .map((_, i) => {
                      const d = weekStart.clone().add(i, 'days').startOf('day');
                      if (start?.isSame(d, 'day')) {
                        return (
                          <td key={i} className={w === 0 ? 'pt-3' : ''}>
                            <div className="w-full h-full">
                              <div className="flex items-center justify-center w-full cursor-pointer">
                                <p className="text-md mb-0 h-8 flex items-center justify-center w-full font-medium text-white bg-indigo-700 rounded-tl-lg rounded-bl-lg">
                                  {d.format('D')}
                                </p>
                              </div>
                            </div>
                          </td>
                        );
                      }
                      if (end?.isSame(d, 'day')) {
                        return (
                          <td key={i} className={w === 0 ? 'pt-3' : ''}>
                            <div className="w-full h-full">
                              <div className="flex items-center justify-center w-full cursor-pointer">
                                <p className="text-md mb-0 w-full h-8 flex items-center justify-center font-medium text-white bg-indigo-700 rounded-tr-lg rounded-br-lg">
                                  {d.format('D')}
                                </p>
                              </div>
                            </div>
                          </td>
                        );
                      }

                      if (d.isBetween(start, end, 'day')) {
                        return (
                          <td key={i} className={w === 0 ? 'pt-3' : ''} onClick={() => changeDay(d)}>
                            <div className="w-full my-4 h-full bg-gray-200">
                              <div className="flex items-center justify-center w-full cursor-pointer">
                                <p className="text-md mb-0 w-8 h-8 flex items-center justify-center font-medium text-gray-900 bg-gray-200 ">
                                  {d.format('D')}
                                </p>
                              </div>
                            </div>
                          </td>
                        );
                      }
                      return (
                        <td key={i} className={w === 0 ? 'pt-3' : ''} onClick={() => changeDay(d)}>
                          <div className="px-2 py-4 cursor-pointer flex w-full justify-center">
                            {d.isSame(month, 'month') ? (
                              <p className="text-md mb-0 text-gray-500 dark:text-gray-100">{d.format('D')}</p>
                            ) : (
                              <p className="text-md mb-0 text-gray-200 dark:text-gray-100">{d.format('D')}</p>
                            )}
                          </div>
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
