import { classNames } from '@/util/classNames';
import { XCircleIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import useDocumentClick from '../hooks/useDocumentClick';
import Button from './Button';
import CalendarRange from './CalendarRange';

const dateFormat = 'DD.MM.YYYY';

/**
 *
 * Input field for a date. Either enter via keyboard or click a day
 *
 * ```js
 * <CalendarInput
 *   value={value}
 *   onChange={setValue}
 *   placeholder="Wann möchtest du mit dem Wassertaxi fahren?"
 * />
 * ```
 */

function CalendarRangeInput({
  value: valueProp,
  state,
  onChange,
}: {
  value?: {
    from: string;
    to: string;
  };
  state?: any;
  onChange?: (
    value: {
      from: string;
      to: string;
    } | null,
  ) => void;
  placeholder?: string;
}) {
  const [value, setValue] =
    state ||
    useState({
      from: '',
      to: '',
    });

  const [open, setOpen] = useState(false);

  const clearInput = () => {
    setValue({ from: '', to: '' });
    onChange?.({ from: '', to: '' });
  };

  useDocumentClick((e: any) => {
    if (!e.target.closest('.calendar-range-input')) {
      setOpen(false);
    }
  });

  return (
    <div className="calendar-range-input relative">
      <div className="flex " onFocus={() => setOpen(true)}>
        <input
          type="text"
          value={value?.from}
          className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Von"
        />
        <input
          type="text"
          value={value?.to}
          className="block w-full rounded-none rounded-r-md border-gray-300 pl-10 border-l-0 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Bis"
        />
        <Button.Action tooltip="Reset" onClick={clearInput}>
          <XCircleIcon className="h-5 w-5 text-gray-400" />
        </Button.Action>
      </div>
      <div className={classNames('absolute z-10 left-0 right-0', open ? 'block' : 'hidden')}>
        {open && (
          <CalendarRange
            value={value}
            onChange={(e) => {
              setValue(() => ({
                from: e?.from?.format(dateFormat) || '',
                to: e?.to?.format(dateFormat) || '',
              }));
              onChange?.({
                from: e?.from?.format(dateFormat) || '',
                to: e?.to?.format(dateFormat) || '',
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CalendarRangeInput;
