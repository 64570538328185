import { fabric } from 'fabric';
import * as QRCode from 'qrcode';

fabric.Object.prototype.objectCaching = false;
// @ts-ignore
fabric.Object.prototype.caching = false;

function generateQrCode(data: any): Promise<string> {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(
      typeof data === 'string' ? data : JSON.stringify(data),
      {
        scale: 8,
        margin: 0,
        errorCorrectionLevel: 'high',
      },
      (err: any, url: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      },
    );
  });
}

const QrPlugin = fabric.util.createClass(fabric.Object, {
  type: 'QrCode',
  initialize(options: any) {
    this.callSuper('initialize', options || {});
  },
  async setData(data: any) {
    this.data = data;
    const img = new Image();
    img.src = await generateQrCode(this.data);
    this.element = img;
    await new Promise((resolve) => {
      if (img.complete) resolve(img);
      img.onload = resolve;
    });
  },
  toObject() {
    const object = this.callSuper('toObject');
    object.data = this.data;
    return object;
  },
  _render(ctx: any) {
    ctx.drawImage(
      this.element,
      0,
      0,
      this.element.width,
      this.element.height,
      -this.width / 2,
      -this.height / 2,
      this.width,
      this.height,
    );
  },
  toSVG() {
    const x = -this.width / 2;
    const y = -this.height / 2;

    return [
      '\t<image id="',
      'SVGID_',
      // @ts-ignore
      // eslint-disable-next-line no-plusplus, no-underscore-dangle
      fabric.Object.__uid++,
      '" xlink:href="',
      this.element.src,
      '" x="',
      x,
      '" y="',
      y,
      '" width="',
      this.width,
      '" height="',
      this.height,
      '" ',
      this.getSvgTransform(),
      '/>\n',
    ].join('');
  },
});
//@ts-ignore
fabric.QrCode = QrPlugin;

//@ts-ignore
fabric.QrCode.fromObject = async (object: any, callback: any) => {
  //@ts-ignore
  const qr = new fabric.QrCode(object);
  await qr.setData(qr.data);
  callback(qr);
};
