import { useHasFeature } from '@/hooks/useFeatureFlags';
import { motion } from 'framer-motion';
import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import useGlobalTransition from '../hooks/useGlobalTransition';
import { classNames } from '../util/classNames';
import { isActive } from '../util/router';
import Layout from './Layout';

// TODO: use in Sidemenu Level3

export const Tabs = tw.nav`flex border-b w-full m-auto px-4 bg-white dark:bg-gray-800 dark:border-b gap-3 dark:border-gray-700`;

export function Tab({ $active, onClick, children, to }: any) {
  return (
    <>
      <Link
        to={to}
        reloadDocument={false}
        replace={true}
        // onClick={() => onClick?.()}
        className={classNames(
          $active && 'text-indigo-600 dark:text-indigo-400 -mb-0.5 border-indigo-600',
          'py-3 inline-flex px-4 dark:text-gray-300 relative text-gray-700 cursor-pointer text-sm',
        )}
      >
        {children}
        {$active && (
          <motion.div
            className="h-[2px] absolute bottom-0 left-0 bg-indigo-600 w-full"
            layoutId="underline"
          ></motion.div>
        )}
      </Link>
    </>
  );
}

export function TabLink({
  to,
  children,
  permissions,
  featureFlag,
}: {
  to: string;
  children: React.ReactNode;
  permissions?: boolean;
  featureFlag?: string;
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasFeature } = useHasFeature();
  // TODO: is there a more api friendly way to get relative links?
  const target = pathname.split('/').slice(0, -1).concat([to]);

  const startTransition = useGlobalTransition();
  if (permissions === false) {
    return <></>;
  }
  if (featureFlag && !hasFeature(featureFlag)) {
    return <></>;
  }
  return (
    <Tab
      to={target.join('/')}
      onClick={() => startTransition(() => navigate(target.join('/')))}
      $active={isActive(target.slice(1))}
    >
      {children}
    </Tab>
  );
}

export function TabLayout({ children, noOverflow = false }) {
  return (
    <Layout>
      <div className="h-full flex flex-col">
        <Tabs>
          <div className="max-w-screen-xl px-4 h-full m-auto w-full">{children}</div>
        </Tabs>
        <div className={classNames('grow relative ', !noOverflow && 'overflow-auto')}>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}
