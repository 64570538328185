import useEntryList from '@/hooks/useEntryList';
import { classNames } from '@/util/classNames';
import { Combobox } from '@headlessui/react';
import { ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import { filterOptions } from 'ec.sdk/lib/resources/ListResource';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Button from './Button';
import Dropdown from './Dropdown';
import Input from './Input';
import Spinner from './Spinner';

export function TagsInput({
  name,
  rules,
  allowNew,
  control,
  filter,
  model,
}: {
  name: string;
  rules?: any;
  control: any;
  model?: string;
  allowNew?: boolean;
  filter?: filterOptions;
}): JSX.Element {
  return (
    <Controller
      render={({ field }) => (
        <TagsPicker filter={filter} value={field.value} allowNew={allowNew} onChange={field.onChange} model={model} />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}

export function TagsPicker({
  value,
  onChange,
  model,
  allowNew = true,
  filter = {},
}: {
  value: string[];
  onChange: (value: string[]) => void;
  model?: string;
  allowNew?: boolean;
  filter?: filterOptions;
}) {
  const [input, setInput] = useState('');

  const add = () => {
    if (input && !value.includes(input)) {
      onChange(value.concat([input]));
      setInput('');
    }
  };
  return (
    <div className="flex-col">
      <div className="flex items-center flex-wrap">
        {(value || [])
          .map((item) => (typeof item !== 'object' ? { value: item, label: item } : item))
          // @ts-ignore
          .map((item) => (item.hasOwnProperty('id') ? { value: item.id, label: item._entryTitle } : item))
          .map((item) => (
            <Badge
              hasX
              key={item.value}
              theme="gray"
              onX={() => {
                onChange(value.filter((v) => (v.id ? v.id !== item.value : v !== item.value)));
              }}
              className="mr-2 mb-2"
            >
              {item.label.split(':').length > 1 ? `${item.label.split(':')[1]}` : item.label}
            </Badge>
          ))}
      </div>
      <div className="flex items-center">
        {model ? (
          <TagsComboBox filter={filter} model={model} allowNew={allowNew} value={input} onChange={setInput} />
        ) : (
          <Input
            type="text"
            placeholder="Tag eingeben.."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') add();
            }}
          />
        )}
        <Button.Action prevent $primary onClick={add}>
          <PlusIcon className="w-4 h-4" />
        </Button.Action>
      </div>
      {/* <PlusDropdown items={items} value={value} onChange={onChange} /> */}
    </div>
  );
}

function PlusDropdown({ items, value, onChange }: { items; onChange: (v: any) => void; value: any }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Dropdown
        showIcon={false}
        label={
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="rounded-full w-7 h-7 flex items-center justify-center border-dashed border-2 text-gray-400 border-gray-400"
          >
            <PlusIcon className="w-4 h-4" />
          </div>
        }
      >
        <Dropdown.Panel $right>
          {items.map((item) => (
            <Dropdown.Checkbox
              checked={value.includes(item.value)}
              onChange={(e) =>
                onChange(
                  e.target.checked ? (value || []).concat([item.value]) : (value || []).filter((v) => v !== item.value),
                )
              }
              key={item.value}
            >
              {item.label}
            </Dropdown.Checkbox>
          ))}
        </Dropdown.Panel>
      </Dropdown>
    </>
  );
}

export function TagsComboBox({
  model,
  value,
  allowNew,
  onChange,
  filter,
}: {
  model: string;
  value: any;
  allowNew?: boolean;
  onChange: (v: any) => void;
  filter?: filterOptions;
}) {
  const { data: tags } = useEntryList({ model, filterOptions: filter, swrOptions: { revalidateOnFocus: true } });
  const [query, setQuery] = useState('');

  if (!tags) return <Spinner />;
  const filteredTags =
    query === ''
      ? tags?.getAllItems()
      : tags?.getAllItems().filter((tag) => tag?.tag?.toLowerCase().includes(query.toLowerCase()));

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative w-full">
        <Combobox.Input
          className={`block shadow-sm 
        focus:ring-indigo-500 focus:border-indigo-500 
        sm:text-sm w-full
        disabled:bg-gray-200 
        disabled:dark:bg-gray-900 
        border-gray-300 dark:border-gray-500 rounded-md 
        dark:bg-gray-700`}
          displayValue={(v) => (v.split(':').length > 1 ? `${v.split(':')[1]}` : v)}
          //displayValue={v => tags.getAllItems().find(t => t.tag === v)?.tag}
          onChange={(event) => setQuery(event.target.value)}
        />
        <Combobox.Button className=" absolute top-2.5 right-2">
          <ChevronUpDownIcon className=" h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        <Combobox.Options className="absolute z-10 p-1 w-full bg-white dark:bg-gray-600 rounded-md shadow-md">
          {filteredTags.length === 0 &&
            (allowNew ? (
              <Combobox.Option value={query} className="">{`"${query}" hinzufügen`}</Combobox.Option>
            ) : (
              <>Nichts gefunden</>
            ))}

          {filteredTags?.map((tag) => (
            <Combobox.Option key={tag.id} value={tag.tag}>
              {({ active }) => (
                <div className={classNames(active && 'bg-indigo-700 text-white', 'p-2 ')}>
                  {tag.tag.split(':').length > 1 ? `${tag.tag.split(':')[1]}` : tag.tag}
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

function Badge({ children, theme = 'gray', showDot = false, hasX = false, onX, className }: BadgeProps) {
  return (
    <span
      className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium dark:bg-${theme}-600 dark:text-${theme}-200 bg-${theme}-100 text-${theme}-800 ${className}`}
    >
      {showDot && (
        <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${theme}-400`} fill="currentColor" viewBox="0 0 8 8">
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {children}
      {hasX && (
        <button
          type="button"
          onClick={onX}
          className={`-mr-0.5 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-${theme}-400 hover:bg-${theme}-200 hover:text-${theme}-500 focus:outline-none focus:bg-${theme}-500 focus:text-white`}
        >
          <span className="sr-only">Remove large option</span>
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      )}
    </span>
  );
}

type BadgeProps = {
  children: React.ReactNode;
  theme?: 'gray' | 'red' | 'yellow' | 'green' | 'blue' | '${theme}' | 'purple' | 'pink';
  showDot?: boolean;
  onX?: () => void;
  hasX?: boolean;
  className?: string;
};
