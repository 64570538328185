import { classNames } from '@/util/classNames';
import { getColor } from '@/util/getColor';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  arrowGoBackFill,
  arrowGoForwardFill,
  bold,
  codeView,
  h1,
  h2,
  h3,
  imageAddLine,
  italic,
  link,
  listOrdered,
  listUnordered,
  strikethrough,
  underline,
} from '@remirror/icons';
import { useActive, useCommands } from '@remirror/react';
import React, { createElement, useMemo, useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import Tooly from '../Tooly';
import { ToolbarItem } from './ProseInput';
// import ImageAddModal, { WithSrc } from '../ImageAddModal';
// import useEvent from '../../hooks/useEvent';

function Icon({ data, ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22">
      {data.map(({ tag, attr }, i) => createElement(tag, { ...attr, key: i }))}
    </svg>
  );
}

export const imageAddKey = 'Tailbar.addImage';

export function triggerImageAdd(src) {
  document.dispatchEvent(
    new CustomEvent(imageAddKey, {
      detail: {
        src,
      },
    }),
  );
}

function Tailbar({
  onImageAdd,
  vars,
  onAssignVar,
  options,
}: {
  onImageAdd?: (files: File[]) => Promise<any[]>;
  vars?: any;
  options?: {
    toolbar: Array<ToolbarItem>;
  };
  onAssignVar?: (v: any) => void;
}) {
  const {
    focus,
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrike,
    toggleBulletList,
    toggleOrderedList,
    toggleHeading,
    undo,
    redo,
    insertText,
    updateLink,
    removeLink,
  } = useCommands();
  const active = useActive();
  const [showImageModal, setShowImageModal] = useState(false);

  const actions = useMemo(
    () => ({
      undo: {
        icon: arrowGoBackFill,
        label: 'Rückgängig',
        group: 'undo',
        onClick: () => {
          undo();
          focus();
        },
      },
      redo: {
        icon: arrowGoForwardFill,
        label: 'Wiederholen',
        group: 'undo',
        onClick: () => {
          redo();
          focus();
        },
      },
      bold: {
        icon: bold,
        label: 'Fett',
        group: 'format',
        get active() {
          return active.bold();
        },
        onClick: () => {
          toggleBold();
          focus();
        },
      },
      italic: {
        icon: italic,
        label: 'Kursiv',
        group: 'format',
        get active() {
          return active.italic();
        },
        onClick: () => {
          toggleItalic();
          focus();
        },
      },
      strike: {
        icon: strikethrough,
        label: 'Durchgestrichen',
        group: 'format',
        get active() {
          return active.strike();
        },
        onClick: () => {
          toggleStrike();
          focus();
        },
      },
      underline: {
        icon: underline,
        group: 'format',
        label: 'Unterstrichen',
        get active() {
          return active.underline();
        },
        onClick: () => {
          toggleUnderline();
          focus();
        },
      },
      h1: {
        icon: h1,
        label: 'Überschrift 1',
        group: 'headings',
        get active() {
          return active.heading({ level: 1 });
        },
        onClick: () => {
          toggleHeading({ level: 1 });
          focus();
        },
      },
      h2: {
        icon: h2,
        label: 'Überschrift 2',
        group: 'headings',
        get active() {
          return active.heading({ level: 2 });
        },
        onClick: () => {
          toggleHeading({ level: 2 });
          focus();
        },
      },
      h3: {
        icon: h3,
        label: 'Überschrift 3',
        group: 'headings',
        get active() {
          return active.heading({ level: 3 });
        },
        onClick: () => {
          toggleHeading({ level: 3 });
          focus();
        },
      },
      link: {
        icon: link,
        label: 'Link',
        group: 'headings',
        get active() {
          return active.link();
        },
        onClick: () => {
          if (!active.link()) {
            updateLink({
              href: '',
            });
          } else {
            removeLink();
          }
          focus();
        },
      },

      listUnordered: {
        icon: listUnordered,
        label: 'Aufzählung',
        group: 'list',
        get active() {
          return active.bulletList();
        },
        onClick: () => {
          toggleBulletList();
          focus();
        },
      },
      listOrdered: {
        icon: listOrdered,
        label: 'Nummerierte Liste',
        group: 'list',
        get active() {
          return active.orderedList();
        },
        onClick: () => {
          toggleOrderedList();
          focus();
        },
      },
      variables: {
        get hidden() {
          return !mappedVars.length;
        },
        icon: codeView,
        label: 'Variablen',
        group: 'extra',
        onClick: () => {
          setShowVariablesModal(true);
        },
      },
    }),
    [],
  );

  const [showVariablesModal, setShowVariablesModal] = useState(false);

  const actionsByGroup = useMemo(() => {
    return Object.entries(actions ?? {})
      .filter((action) => {
        if (!options?.toolbar) {
          return true;
        }
        return options?.toolbar?.includes(action[0] as any);
      })
      .map(([_, v]) => v)
      .reduce((acc, action) => {
        acc[action.group] = acc[action.group] || [];
        acc[action.group].push(action);
        return acc;
      }, {});
  }, [actions]);

  const mappedVars = useMemo(() => {
    return (
      vars?.sort((a, b) => {
        return a.identifier.split('.').length - b.identifier.split('.').length;
      }) ?? []
    ).map((v) => ({
      ...v,
      color: getColor(v.identifier.includes('.') ? v.identifier.split('.').shift() : null),
    }));
  }, [vars]);

  return (
    <>
      <Modal open={showVariablesModal} onClose={() => setShowVariablesModal(false)} className="max-w-4xl">
        <div className="flex justify-between items-center mb-3">
          <span>Variable einfügen</span>
          <Button.Action onClick={() => setShowVariablesModal(false)}>
            <XMarkIcon className="w-4 h-4" />
          </Button.Action>
        </div>
        <div className="grid grid-cols-4 gap-1.5 max-h-96 overflow-auto">
          {mappedVars?.map((v) => (
            <Button
              $small
              $secondary
              as="div"
              key={v.identifier}
              onClick={() => {
                insertText(v.value);
                onAssignVar?.(v);
                setShowVariablesModal(false);
                focus();
              }}
              style={{
                borderColor: v.color,
              }}
              className="border-2"
            >
              <div className="text-xs text-ellipsis overflow-clip">
                <div>{v.description}</div>
                <small className="text-gray-400 font-mono text-[8px]">{v.identifier}</small>
              </div>
            </Button>
          ))}
        </div>
      </Modal>
      <div className="sm:flex gap-1 flex-wrap p-2 rounded-tl-lg bg-gray-100 dark:bg-gray-900 rounded-tr-lg">
        {Object.entries(actionsByGroup).map(([key, group]: any) => (
          <div key={key} className="whitespace-nowrap">
            {group.map((action, i) => {
              if (action.hidden) {
                return null;
              }

              return (
                <Tooly key={i} placement="top" label={action.label}>
                  <Button
                    $small
                    $secondary
                    className={classNames(
                      i > 0 && i < group.length - 1 && 'rounded-none',
                      i === 0 && group.length !== 1 && 'rounded-r-none',
                      i === group.length - 1 && group.length !== 1 && 'rounded-l-none',
                      action.active && 'bg-indigo-500 dark:bg-indigo-600 hover:bg-indigo-400 hover:dark:bg-indigo-500',
                      group.length !== 1 && ' border-r-0',
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.onClick(e);
                    }}
                  >
                    <Icon
                      data={action.icon}
                      className={classNames('w-3.5 h-3.5 dark:fill-white ', action.active && 'fill-white')}
                    />
                  </Button>
                </Tooly>
              );
            })}
          </div>
        ))}
        <div id="link-input-container" />
        {!!onImageAdd && (
          <Button $small $secondary onClick={() => setShowImageModal(true)}>
            <Icon data={imageAddLine} className="w-3.5 h-3.5 fill-current" />
          </Button>
        )}

        {/* <Button
        $disabled={!toggleBulletList.enabled()}
        $primary={active.bulletList()}
        className="rounded-md"
        onClick={() => {
          toggleBulletList();
          focus();
        }}
      >
        <PhotoIcon className="w-4 h-4 fill-inherit" />
      </Button>
      <Button
        $disabled={!toggleLink.enabled()}
        $primary={active.link()}
        className="rounded-md"
        onClick={() => {
          toggleLink();
          focus();
        }}
      >
        <LinkIcon className="w-4 h-4 fill-inherit" />
      </Button> */}
      </div>
      {/* {onImageAdd && ( */}
      {/*   <ImageAddModal */}
      {/*     open={showImageModal} */}
      {/*     onClose={() => setShowImageModal(false)} */}
      {/*     onImageAdd={async (files) => { */}
      {/*       const images = await onImageAdd(files); */}
      {/*       images.forEach((image) => insertImage(image)); */}
      {/*       focus(); */}
      {/*     }} */}
      {/*   /> */}
      {/* )} */}
    </>
  );
}

export default Tailbar;
