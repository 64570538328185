import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import CodesTable from '@/routes/Discounts/components/CodesTable';
import { DiscountCampaign } from '@entrecode/dm-types/types/DiscountCampaign';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { useContext } from 'react';

const CodesForm = () => {
  const { membershipTemplate: mt, selectedVersion } = useContext(OMTContext);
  const membershipTemplate = mt as DiscountCampaign & EntryResource;

  console.log(membershipTemplate);
  return (
    <div>
      <div className="mb-6" />
      <CodesTable />
    </div>
  );
};

export default CodesForm;
