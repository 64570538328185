import { useCreateDefaultMakroCategories } from '@/routes/MakroConfig';
import { ClipboardIcon, PencilIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import TextareaAutosize from 'react-textarea-autosize';
import useSWR from 'swr';
import useEntryList from '../../hooks/useEntryList';
import useNotifications from '../../hooks/useNotifications';
import useSdk from '../../hooks/useSdk';
import { classNames } from '../../util/classNames';
import Accordion from '../Accordion';
import Button from '../Button';
import Form from '../Form';
import Info from '../Info';
import Sidebar from '../Sidebar';
import Spinner from '../Spinner';
import Tab from '../Tabs';
import TextareaWithVariables from '../TextAreaWithVariables';

export default function MakroEditor({
  value,
  onChange,
  category,
  values,
  placeholder,
}: {
  value: string;
  onChange: (value: string) => void;
  category: string;
  values?: { [key: string]: string | number };
  placeholder?: string;
}) {
  const { api } = useSdk();
  const [showMakroSelector, setShowMakroSelector] = useState(false);
  const [selectedMakro, setSelectedMakro] = useState<string>(null);
  const [editSidebar, setEditSidebar] = useState(false);

  const { data: makros, mutate } = useSWR(api ? '/makros' + category : null, async () =>
    api
      .entryList('makro_editor', {
        category: category,
      })
      .then((list) => list.getAllItems()),
  );

  const {
    data: selectedCategory,
    isValidating,
    mutate: mutateCategory,
  } = useEntryList({
    model: 'makro_categories',
    filterOptions: {
      categoryID: category,
    },
  });

  const { pending, createDefaultCategories } = useCreateDefaultMakroCategories();
  useEffect(() => {
    if (!isValidating && selectedCategory?.getAllItems().length < 1) {
      createDefaultCategories(() => {
        mutate();
        mutateCategory();
      });
    }
  }, [selectedCategory, isValidating]);

  const makroValue = useMemo(() => {
    const activeValue = makros?.find((makro) => makro.id === selectedMakro)?.value;

    return reactStringReplace(activeValue, /{{(.*?)}}/g, (match, i) => (
      <span key={i} className="bg-green-100 text-green-800 py-1 px-2 font-medium shadow-sm rounded-md">
        {match}
      </span>
    ));
  }, [makros, selectedMakro]);

  const getReplacedValue = useCallback(() => {
    let activeValue = makros?.find((makro) => makro.id === selectedMakro)?.value;
    Object.keys(values || {}).forEach((key) => {
      activeValue = activeValue.replaceAll(`{{${key}}}`, values[key]);
    });
    setShowMakroSelector(false);
    return activeValue;
  }, [makros, selectedMakro, values]);

  const categoryTitle = useMemo(() => {
    try {
      return selectedCategory?.getFirstItem()?.name;
    } catch (err) {
      console.warn('Category not found');
      return null;
    }
  }, [selectedCategory]);

  return (
    <div className="relative">
      <TextareaAutosize
        minRows={3}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        className="bg-white/60 rounded-lg border-gray-300 dark:border-gray-500 shadow-sm dark:bg-gray-700 bg-white text-gray-900 dark:text-gray-100 w-full h-28"
      />
      {categoryTitle && (
        <>
          <div className="absolute right-3 bottom-5">
            <div
              onClick={() => setShowMakroSelector(!showMakroSelector)}
              className=" bg-white/50 cursor-pointer rounded-lg p-2"
            >
              <ClipboardIcon className="w-4 h-4 text-gray-700" />
            </div>
            {showMakroSelector && (
              <>
                <div
                  className="fixed w-screen h-screen inset-0 z-10 outsideClickOverlay"
                  onClick={() => setShowMakroSelector(false)}
                />
                <div className="absolute w-auto bottom-full bg-gray-100 dark:bg-gray-800 rounded-lg z-20 right-0 shadow-md">
                  <div className="flex justify-between p-3 text-gray-500 dark:text-gray-300 border-b dark:border-gray-700 items-center">
                    <span className="text-sm font-medium">Makros - {categoryTitle}</span>{' '}
                    <PencilIcon onClick={() => setEditSidebar(true)} className="w-5 h-5 cursor-pointer" />
                  </div>
                  <div className="flex min-w-[500px]">
                    <div className="text-sm  py-3 px-2 flex flex-col gap-2 w-full font-medium text-gray-500 dark:text-gray-200 h-full overflow-y-auto">
                      {makros?.map((makro) => (
                        <span
                          key={makro.id}
                          className={classNames(
                            selectedMakro === makro.id
                              ? 'bg-gray-300 dark:bg-gray-600'
                              : 'hover:bg-gray-200 hover:dark:bg-gray-700',
                            'rounded-md py-1 px-2 cursor-pointer whitespace-nowrap',
                          )}
                          onClick={() => setSelectedMakro(makro.id)}
                        >
                          {makro?.title?.length > 35 ? (
                            <div title={makro.title}>{makro?.title?.substring(0, 35) + '...'}</div>
                          ) : (
                            makro?.title
                          )}
                        </span>
                      ))}
                    </div>

                    <div className="text-sm leading-5 border-l dark:border-gray-700 py-3 px-2 w-full text-black dark:text-white relative">
                      <div>{makroValue ? makroValue : <span>Wähle ein Makro aus</span>}</div>
                      {selectedMakro && (
                        <div
                          className="absolute cursor-pointer right-3 bottom-3 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg"
                          onClick={() => onChange(getReplacedValue())}
                        >
                          <CheckIcon className="text-green-500 w-5 h-5" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {selectedCategory && (
            <ManageMakroSidebar
              open={editSidebar}
              key={selectedCategory.getFirstItem().id}
              onClose={() => setEditSidebar(false)}
              onChange={mutate}
              category={category}
            />
          )}
        </>
      )}
    </div>
  );
}

export function ManageMakroSidebar({
  open,
  onClose,
  category,
  onChange,
}: {
  open: boolean;
  onClose: () => void;
  category: string;
  onChange: () => void;
}) {
  const { api } = useSdk();
  const notifications = useNotifications();
  const [catSearch] = category?.split('$');
  const { data: makros, mutate } = useEntryList({
    model: open ? 'makro_editor' : null,
    filterOptions: {
      category: { search: catSearch },
    },
  });

  const { data: makroCategories } = useEntryList({
    model: open ? 'makro_categories' : null,
    filterOptions: {
      categoryID: { search: catSearch },
    },
  });

  const [isCreating, setIsCreating] = useState(false);
  function addNewMakro() {
    setIsCreating(true);
    try {
      api
        .createEntry('makro_editor', {
          title: 'Neues Makro',
          value: '',
          category: activeTab,
        })
        .then(() => {
          notifications.emit({
            type: 'success',
            message: 'Makro erfolgreich erstellt',
          });
          mutate();
          setIsCreating(false);
        });
    } catch (error) {
      notifications.emit({
        type: 'error',
        message: 'Makro konnte nicht erstellt werden',
      });
      setIsCreating(false);
    }

    return; // TODO
  }

  const [isDeleting, setIsDeleting] = useState(null);
  function deleteMakro(makro: EntryResource) {
    setIsDeleting(makro.id);
    try {
      makro.delete().then(() => {
        notifications.emit({
          type: 'success',
          message: 'Makro erfolgreich gelöscht',
        });
        mutate();
        setIsDeleting(null);
      });
    } catch (error) {
      notifications.emit({
        type: 'error',
        message: 'Makro konnte nicht gelöscht werden',
      });
      setIsDeleting(null);
    }
  }

  const [isSaving, setIsSaving] = useState(null);
  async function editMakro(e: FormEvent<HTMLFormElement>, makro: EntryResource) {
    e.preventDefault();
    const form = new FormData(e.target as HTMLFormElement);
    setIsSaving(makro.id);
    try {
      makro.setProperty('title', form.get('title'));
      makro.setProperty('value', form.get('value'));

      await makro.save();
      notifications.emit({
        type: 'success',
        message: 'Makro gespeichert',
      });
      mutate();
      setIsSaving(null);
      onChange();
    } catch (err) {
      console.error(err);
      notifications.emit({
        type: 'error',
        message: 'Fehler beim Speichern',
      });
      setIsSaving(null);
    }
  }

  const [activeTab, setActiveTab] = useState();
  useEffect(() => {
    if (makroCategories?.items.length > 0)
      setActiveTab(makroCategories.items.sort((a, b) => a.created - b.created)[0].categoryID);
  }, [makroCategories]);

  return (
    <Sidebar open={open} onClose={onClose}>
      <Sidebar.Head>
        <Sidebar.Heading>Makros Verwalten</Sidebar.Heading>
        <Sidebar.X />
      </Sidebar.Head>
      <Sidebar.Body>
        <Tab>
          <Tab.Head>
            {makroCategories?.items
              .sort((a, b) => a.created - b.created)
              .map((mCat) => (
                <Tab.Header
                  key={mCat.name}
                  active={activeTab === mCat.categoryID}
                  onClick={() => setActiveTab(mCat.categoryID)}
                  title={mCat.name}
                />
              ))}
          </Tab.Head>

          <Tab.Content>
            {makroCategories?.items.map(
              (mCat) =>
                activeTab === mCat.categoryID && (
                  <div key={mCat}>
                    <p className="text-gray-400 font-normal mb-5">{mCat.description}</p>

                    {makros?.items?.filter((makro) => makro.category === mCat.categoryID).length > 0 ? (
                      <Accordion>
                        {makros?.items
                          ?.filter((makro) => makro.category === mCat.categoryID)
                          .map((makro) => (
                            <Accordion.Item key={makro.id}>
                              <Accordion.Head>{makro.title}</Accordion.Head>
                              <Accordion.Body>
                                <form onSubmit={(e) => editMakro(e, makro)}>
                                  <Form>
                                    <Form.Item $first>
                                      <Form.Item.Label htmlFor="title">Titel</Form.Item.Label>
                                      <Form.Item.Body>
                                        <input
                                          className={Form.Item.text}
                                          defaultValue={makro.title}
                                          name="title"
                                          type="text"
                                        />
                                      </Form.Item.Body>
                                    </Form.Item>
                                    <Form.Item>
                                      <Form.Item.Label htmlFor="value">Text</Form.Item.Label>
                                      <Form.Item.Body>
                                        <TextareaWithVariables
                                          name="value"
                                          defaultValue={makro.value}
                                          variables={mCat.values}
                                        />
                                      </Form.Item.Body>
                                    </Form.Item>
                                    <div className="flex felx-col gap-3 justify-end">
                                      <Button
                                        $danger
                                        loading={isDeleting === makro.id}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deleteMakro(makro);
                                        }}
                                      >
                                        Löschen
                                      </Button>
                                      <Button $primary loading={isSaving === makro.id}>
                                        Speichern
                                      </Button>
                                    </div>
                                  </Form>
                                </form>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                      </Accordion>
                    ) : (
                      <Info>Noch keine Makros vorhanden</Info>
                    )}
                    <div
                      className="flex items-center gap-3 text-indigo-500 justify-end mt-3 cursor-pointer"
                      onClick={() => addNewMakro()}
                    >
                      {!isCreating ? <PlusCircleIcon className="w-5 h-5" /> : <Spinner />} Neues Makro hinzufügen
                    </div>
                  </div>
                ),
            )}
          </Tab.Content>
        </Tab>
      </Sidebar.Body>
    </Sidebar>
  );
}
