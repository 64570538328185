export interface FetchError extends Error {
  info: any;
  status: number;
}

export const request = (url, method = 'get', body?, headers = {}) => {
  console.log(method, url);
  if (!url) {
    return Promise.reject(new Error('No url provided'));
  }
  return fetch(url, {
    method,
    headers: { 'content-type': 'application/json', ...headers, 'x-requested-with': 'admin' },
    ...(body ? { body: JSON.stringify(body) } : {}),
  }).then(async (res) => {
    if (!res.ok) {
      if (res.status === 401) {
        console.warn(
          `Unauthorized: failed to fetch "${url}". Could it be that the URL is missing in OidcTrustedDomains.js?`,
        );
      }
      const error: Error & any = new Error('An error occurred while fetching the data.');
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }
    if ([202, 204].includes(res.status)) {
      return res; // no content
    }
    if (res.headers.get('content-type').includes('application/json')) {
      return res.json();
    }
    return res.text();
  });
};

export const get = (url, headers?) => request(url, 'get', undefined, headers);
export const put = (url, body, headers?) => request(url, 'put', body, headers);
export const post = (url, body, headers?) => request(url, 'post', body, headers);
export const del = (url, body, headers?) => request(url, 'delete', body, headers);
