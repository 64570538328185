import environment from '@/environment';
import useSdk from '@/hooks/useSdk';
import { getHumanReadableAboDefinition } from '@entrecode/hectorone-helpers';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '../Button';
import { GLOBALTEMPLATEVAR } from '../GlobalContract/model';
import { dotToObject } from '../GlobalContract/useGlobalContractPreview';
import Modal from '../Modal';
import CommentPreview from './CommentPreview';

const Preview = ({ name, type, id, modified, selectedVersion, membershipTemplate }) => {
  const [preview, setPreview] = useState(null);
  const { api } = useSdk();

  const generatePreview = useCallback(async () => {
    const url = new URL(environment.hecRenderUrl);
    let templateType = type.replace('global_', '');
    if (templateType === 'pdf') {
      templateType = 'fabric';
    }

    url.pathname = `${api.shortID}/${`${name}/${templateType}`}`;
    url.searchParams.set('type', 'jpg');
    const res = await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify(
        dotToObject({
          modified,
          ...GLOBALTEMPLATEVAR.reduce((acc, { identifier, placeholder }) => {
            return { ...acc, [identifier]: placeholder };
          }, {}),

          contract: {
            templateName: selectedVersion?.contractTemplates[0]?.name,
            name: membershipTemplate?.name,
            mainText: selectedVersion.mainText,
            begin: '2009-12-12',
            duration: 'P3M',
            entranceFee: 0,
            interval: selectedVersion.mainInterval,
            partialFee: 12.15,
            possibleDateOfEnd: '2009-12-12',
            pricePerInterval: selectedVersion.mainIntervalPrice,
            ...{ ...selectedVersion, _links: {}, membershipTemplate: {}, comments: {}, contractTemplates: [] },
          },
          consumerism: getHumanReadableAboDefinition(selectedVersion),
          addons: selectedVersion.addons,
          fees: selectedVersion.fees,
          discounts:
            selectedVersion.discounts?.map((d) => {
              d.price = -d.price;
              return d;
            }) ?? [],
          isGlobalTemplate: selectedVersion?.contractTemplates[0]?.type === 'global_contract',
        }),
      ),
      headers: {
        'content-type': 'application/json',
      },
    });

    const blob: Blob = await res.blob();

    return URL.createObjectURL(blob);
  }, [api, name, type]);

  useEffect(() => {
    if (!preview) {
      generatePreview().then(setPreview);
    }

    return () => URL.revokeObjectURL(preview);
  }, [id, preview]);

  return (
    <div className="mx-auto rounded-lg w-full mb-4">
      <div className="w-full border h-11 relative rounded-t-lg bg-white dark:bg-gray-800 flex overflow-hidden justify-start items-center space-x-1.5 px-2">
        {name.split('-').pop()}
      </div>
      <CommentPreview entry={selectedVersion} image={preview} name={name.split('-').pop()} showBack={false} />
    </div>
  );
};

export const LegalPreview = ({ open, selectedVersion, membershipTemplate }) => {
  const [, setSearch] = useSearchParams();

  if (!selectedVersion) {
    return null;
  }
  const { legalTemplates, contractTemplates } = selectedVersion;

  return (
    <Modal
      open={open}
      onClose={() =>
        setSearch((prev) => {
          prev.delete('legalpreview');
          return prev;
        })
      }
      className="w-full max-w-[80%]"
    >
      <div className="flex justify-between mb-6">
        <div>Rechtstexte Vorschau</div>
        <Button.Action
          onClick={() =>
            setSearch((prev) => {
              prev.delete('legalpreview');
              return prev;
            })
          }
        >
          <XMarkIcon className="w-5 h-5" />
        </Button.Action>
      </div>
      {contractTemplates?.map((template, index) => (
        <Preview {...template} selectedVersion={selectedVersion} membershipTemplate={membershipTemplate} key={index} />
      ))}
      {legalTemplates?.map((template, index) => (
        <Preview {...template} selectedVersion={selectedVersion} key={index} />
      ))}
    </Modal>
  );
};
