import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import DMAssetList from 'ec.sdk/lib/resources/publicAPI/DMAssetList';
import DMAssetResource from 'ec.sdk/lib/resources/publicAPI/DMAssetResource';
import React, { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import useNotifications from '../hooks/useNotifications';
import useSdk from '../hooks/useSdk';
import AssetPickerModal from '../routes/AssetManager/AssetPickerModal';
import { uploadAssets } from '../util/assets';
import fileVariant from '../util/fileVariant';
import { AssetDropzone } from './AssetDropzone';
import Img from './Img';
import Ink from './Ink';
import Spinner from './Spinner';

// TODO: storybook?

export default function AssetPickerSingle(props: any) {
  const { value, group, onChange, children } = props;
  const { api } = useSdk();
  const [open, setOpen] = useState(false);
  const [currentlyUploadedFile, setCurrentlyUploadedFile] = useState<any>();
  const notifications = useNotifications();
  const { data: selectedAsset } = useSWR<DMAssetList | DMAssetResource>(
    group && value ? `asset/${group}/${value}` : null,
    () => (value?.assetID ? value : api.dmAsset(group, value)),
    { revalidateOnFocus: false },
  );
  const handleClick = useCallback(() => setOpen(true), []);
  const handleRemove = useCallback(
    (e) => {
      e.stopPropagation();
      onChange?.(null);
    },
    [onChange],
  );
  const source = useMemo(() => fileVariant(selectedAsset, 400), [selectedAsset]);

  const galleryImage = useMemo(() => {
    return <Img src={source} className="h-full" />; //  title={title}
  }, [source, selectedAsset?.title, handleClick, handleRemove, selectedAsset?.file, currentlyUploadedFile]);

  const selection = (
    <div className="max-w-[120px]">
      <div className="rounded-md p-0 aspect-1 overflow-hidden relative group">
        {!!selectedAsset?.file && !currentlyUploadedFile && (
          <>
            {galleryImage}
            <div className="absolute top-2 right-2 space-y-2 group-hover:opacity-100 opacity-0">
              <Ink.Primary onClick={() => setOpen(true)} className="cursor-pointer">
                <PencilIcon className="w-6 h-6 bg-gray-200 dark:bg-gray-800 p-1 rounded-md" />
              </Ink.Primary>
              <Ink.Error onClick={() => onChange(null)} className="cursor-pointer">
                <TrashIcon className="mt-1 w-6 h-6 bg-gray-200 dark:bg-gray-800 p-1 rounded-md" />
              </Ink.Error>
            </div>
          </>
        )}
        {!value?.length && !currentlyUploadedFile && (
          <div className="rounded-md p-0 relative">
            <div
              className=" cursor-pointer h-full aspect-1 bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col space-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300"
              onClick={() => setOpen(true)}
            >
              <div className="flex flex-col h-full justify-center items-center gap-2">
                <PlusIcon className="w-5 h-5" />
                <span className="text-xs">Bild hinzufügen</span>
              </div>
            </div>
          </div>
        )}
        {currentlyUploadedFile && (
          <div className="rounded-md p-0 aspect-1 overflow-hidden relative group">
            <div className=" cursor-pointer h-full bg-white dark:bg-gray-600 border border-dashed border-gray-400 dark:border-gray-400 flex flex-col space-y-4 items-center py-4 rounded-md text-gray-500 dark:text-gray-300">
              <div className="flex flex-col h-full justify-center items-center gap-2">
                <Spinner className="w-4 h-4" />
                <span className="text-sm">{currentlyUploadedFile.name} wird hochgeladen</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  return (
    <>
      <AssetDropzone
        onDrop={async ({ files }) => {
          if (files.length > 1) {
            return alert('Bitte nur eine Datei');
          }
          try {
            setCurrentlyUploadedFile(files[0]);
            const assets = await uploadAssets(api, group, files);
            onChange?.(assets?.[0]?.assetID);
            setCurrentlyUploadedFile(null);
            notifications.emit({
              type: 'success',
              title: 'Datei hochgeladen!',
            });
          } catch (err) {
            if (err.code === 2375) {
              notifications.emit({
                type: 'success',
                title: `Die Datei exisitiert bereits und wurde nicht erneut hochgeladen.`,
              });
              onChange?.(err.verbose[0]);
              return setCurrentlyUploadedFile(null);
            }

            notifications.emit({
              type: 'error',
              title: 'Fehler beim Hochladen',
            });
          }
        }}
      >
        {children && typeof children === 'function'
          ? children({ selectedAsset, currentlyUploadedFile, setOpen, value, Selection: selection })
          : selection}
      </AssetDropzone>
      <AssetPickerModal
        assetGroupID={group}
        open={open}
        onClose={() => setOpen(false)}
        onChange={(items) => {
          onChange?.(items[0]);
        }}
        value={[value]}
        solo
      />
    </>
  );
}

const VideoPicker = (props: any) => {
  const { value, group, onChange, children } = props;
  const { api } = useSdk();
  const [open, setOpen] = useState(false);
  const [currentlyUploadedFile, setCurrentlyUploadedFile] = useState<any>();
  const notifications = useNotifications();
  const { data: selectedAsset } = useSWR<DMAssetList | DMAssetResource>(
    group && value ? `asset/${group}/${value}` : null,
    () => (value?.assetID ? value : api.dmAsset(group, value)),
    { revalidateOnFocus: false },
  );
  const handleClick = useCallback(() => setOpen(true), []);
  const handleRemove = useCallback(
    (e) => {
      e.stopPropagation();
      onChange?.(null);
    },
    [onChange],
  );
  const source = useMemo(() => fileVariant(selectedAsset, 400), [selectedAsset]);

  const galleryImage = useMemo(() => {
    return <video preload="metadata" src={source} className="h-full" />;
    // return <Img src={source} className="h-full" />; //  title={title}
  }, [source, selectedAsset?.title, handleClick, handleRemove, selectedAsset?.file, currentlyUploadedFile]);

  const selection = (
    <div className="max-w-[120px]">
      <div className="bg-gray-100 dark:bg-gray-500 rounded-md p-0 aspect-[4/3] overflow-hidden relative group">
        {!!selectedAsset?.file && !currentlyUploadedFile && (
          <>
            {galleryImage}
            <div className="absolute top-2 right-2 space-y-2 group-hover:opacity-100 opacity-0">
              <Ink.Primary onClick={() => setOpen(true)} className="cursor-pointer">
                <PencilIcon className="w-6 h-6 bg-gray-800 p-1 rounded-md" />
              </Ink.Primary>
              <Ink.Error onClick={() => onChange(null)} className="cursor-pointer">
                <TrashIcon className="mt-1 w-6 h-6 bg-gray-800 p-1 rounded-md" />
              </Ink.Error>
            </div>
          </>
        )}
        {!value?.length && !currentlyUploadedFile && (
          <div
            className="flex flex-col h-full w-full justify-center items-center cursor-pointer p-2 gap-1"
            onClick={() => setOpen(true)}
          >
            <PlusIcon className="w-5 h-5" />
            <span className="text-xs">Video hinzufügen</span>
          </div>
        )}
        {currentlyUploadedFile && (
          <div className="overflow-hidden relative p-4 text-sm">Upload: {currentlyUploadedFile?.name}</div>
        )}
      </div>
    </div>
  );
  return (
    <>
      <AssetDropzone
        onDrop={async ({ files }) => {
          if (files.length > 1) {
            return alert('Bitte nur eine Datei');
          }
          if (files[0].type !== 'video/mp4') {
            return alert('Bitte lade eine MP4 Datei hoch');
          }

          try {
            setCurrentlyUploadedFile(files[0]);
            const assets = await uploadAssets(api, group, files);
            onChange?.(assets?.[0]?.assetID);
            setCurrentlyUploadedFile(null);
            notifications.emit({
              type: 'success',
              title: 'Datei hochgeladen!',
            });
          } catch (err) {
            if (err.code === 2375) {
              notifications.emit({
                type: 'success',
                title: `Die Datei exisitiert bereits und wurde nicht erneut hochgeladen.`,
              });
              onChange?.(err.verbose[0]);
              return setCurrentlyUploadedFile(null);
            }

            notifications.emit({
              type: 'error',
              title: 'Fehler beim Hochladen',
            });
          }
        }}
      >
        {children && typeof children === 'function'
          ? children({ selectedAsset, currentlyUploadedFile, setOpen, value, Selection: selection })
          : selection}
      </AssetDropzone>
      <AssetPickerModal
        assetGroupID={group}
        open={open}
        onClose={() => setOpen(false)}
        onChange={(items) => onChange?.(items[0])}
        value={[value]}
        type="video"
        solo
      />
    </>
  );
};

AssetPickerSingle.Video = VideoPicker;
