import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { classNames } from '../util/classNames';

export interface SelectItem<T> {
  label: string;
  value?: T;
  hidden?: boolean; // TODO: implement everywhere it's used
  divider?: boolean; // if set, a divider will be inserted after the item
  disabled?: boolean;
}

export interface SimpleSelectProps<T> {
  items: Array<SelectItem<T> /* | { divider: boolean } */>;
  id?: string;
  disabled?: boolean;
  onChange?: (value?: T) => void;
  value?: T;
  placeholder?: string;
  persistAs?: string; // if set, the value will be persisted in url, to keep selection between page transitions
  className?: string;
  custom?: React.ReactNode;
}

export default function SimpleSelect(props: SimpleSelectProps<any>) {
  const { items, onChange, disabled, value = null, placeholder, className, persistAs, id } = props;
  const [queryParam, setQueryParam] = useSearchParams();

  const findItemByValue = useCallback(
    (_value: any) =>
      items?.find(({ value }) => (value?.id && _value?.id ? value?.id === _value?.id : value === _value)),
    [items],
  );

  const [selected, setSelected] = useState(
    findItemByValue(persistAs && queryParam.has(persistAs) ? queryParam.get(persistAs) : value),
  );
  useEffect(() => {
    if (persistAs && queryParam.get(persistAs) !== selected?.value) {
      onChange?.(queryParam.get(persistAs));
    }
  }, [value, persistAs, queryParam, onChange, selected]);

  const handleChange = (item) => {
    setSelected(item);
    onChange?.(item.value);
    persistAs &&
      setQueryParam((prev) => {
        prev.set(persistAs, item.value);
        return prev;
      });
  };

  useEffect(() => {
    setSelected(() => findItemByValue(value));
  }, [value, findItemByValue]);

  const buttonRef = useRef<any>();

  return (
    <Listbox disabled={disabled} value={selected} onChange={handleChange}>
      {({ open }) => (
        <div id={id} className={classNames('relative', className)}>
          <Listbox.Button
            ref={buttonRef}
            className={classNames(
              'bg-white dark:bg-gray-700 relative w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
              disabled && '!bg-gray-200 dark:!bg-gray-900',
            )}
          >
            <span className="block truncate">{selected?.label || selected?.value || placeholder || '-'}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              style={{ width: buttonRef?.current?.clientWidth }}
              className="absolute z-[1200] min-w-[200px] mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {items?.map((item, i) => (
                <Fragment key={i}>
                  {item.custom ? (
                    item.custom({ onChange })
                  ) : (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          item.hidden && 'hidden',
                          item.disabled && 'pointer-events-none opacity-50',
                          active ? 'text-white dark:text-gray-300 bg-indigo-600' : 'text-gray-900 dark:text-gray-400 ',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {item.label || item.value}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600 dark:text-indigo-300',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  )}
                  {item.divider ? <div className="my-2 border-b border-gray-500" /> : null}
                </Fragment>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export function SimpleSelectExample() {
  return (
    <div className="max-w-md">
      <SimpleSelect
        placeholder="Bitte wählen"
        items={[
          { label: 'A', value: 'a' },
          { label: 'B', value: 'b' },
          { label: 'C', value: 'c', divider: true },
          { label: 'D', value: 'd' },
          { label: 'E', value: 'e' },
        ]}
      />
    </div>
  );
}
