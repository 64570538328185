import ConfirmDelete from '@/components/ConfirmDelete';
import MultiOptionLink from '@/components/MultiOptionLink';
import Tooly from '@/components/Tooly';
import useFeedback from '@/hooks/useFeedback';
import {
  CheckIcon,
  CircleStackIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import CopyLink from '../../components/CopyLink';
import Form from '../../components/Form';
import Ink from '../../components/Ink';
import Input from '../../components/Input';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import { TagsInput } from '../../components/TagsPicker';
import { useDatamanagerResource } from '../../hooks/useDatamanager';
import AssetTile from './AssetTile';
import { getAllVariants } from './assetUtil';

function AssetSidebar({ asset, group, onClose, onSubmit }) {
  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  useEffect(() => {
    if (asset) {
      let { title, tags, caption } = asset;
      reset({ title, caption, tags });
    }
  }, [asset]);

  const { data: dm } = useDatamanagerResource();
  const { fileVariants, thumbnails } = useMemo(() => {
    if (!asset || !group || !dm) return {};
    return {
      fileVariants: getAllVariants(asset, false, group, dm),
      thumbnails: getAllVariants(asset, true, group, dm),
    };
  }, [asset, group, dm]);

  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const { withFeedback } = useFeedback();

  return (
    <Sidebar open={!!asset} onClose={() => onClose?.(null)}>
      {asset && (
        <>
          <Sidebar.Head>
            <Sidebar.Heading>{asset?.title}</Sidebar.Heading>
            <Sidebar.X />
          </Sidebar.Head>
          <Sidebar.Body>
            <div className="grid grid-cols-2 gap-4">
              <AssetTile asset={asset} size={600} label="" group={group} />
              <div className="flex flex-col space-y-1">
                {thumbnails?.map((thumbnail, i) => (
                  <MultiOptionLink href={thumbnail.url} key={i}>
                    Thumbnail {thumbnail.size}
                    {thumbnail.generated && <CheckIcon className="w-5 h-5" />}
                  </MultiOptionLink>
                ))}
                {fileVariants?.map((variant, i) => (
                  <MultiOptionLink href={variant.url} key={i}>
                    Variante {variant.size}
                    {variant.generated && <CheckIcon className="w-5 h-5" />}
                  </MultiOptionLink>
                ))}
                <MultiOptionLink href={asset.file.url}>
                  Original{' '}
                  {asset.file.resolution ? Math.max(asset.file.resolution.width, asset.file.resolution.height) : ''}{' '}
                  <CheckIcon className="w-5 h-5" />
                </MultiOptionLink>
                {!asset.mimetype.startsWith('image/') && (
                  <MultiOptionLink href={asset.file.url}>
                    Link kopieren{' '}
                    {asset.file.resolution ? Math.max(asset.file.resolution.width, asset.file.resolution.height) : ''}{' '}
                    <CheckIcon className="w-5 h-5" />
                  </MultiOptionLink>
                )}
              </div>
            </div>
            <div className="my-4 flex items-center">
              <Ink.Secondary>
                <Tooly label="Größe">{friendlyBytes(asset.file.size)}</Tooly> |{' '}
                <Tooly label="Typ">
                  <div className="flex gap-2 items-center">
                    <DocumentMagnifyingGlassIcon className="w-4 h-4" />
                    {asset.mimetype.split('/')?.[1]}
                  </div>
                </Tooly>{' '}
                {' | '}
                <Tooly label="Duplikate">
                  <div className="flex gap-2 items-center">
                    <DocumentDuplicateIcon className="w-4 h-4" />
                    {asset.duplicates}
                  </div>
                </Tooly>
                {' | '}
                <Tooly label="Verwendet?">
                  <div className="flex gap-2 items-center">
                    <LinkIcon className="w-4 h-4" />
                    {asset.isUsed ? (
                      <CheckIcon className="w-4 h-4 text-green-500" />
                    ) : (
                      <XMarkIcon className="w-4 h-4 text-red-500" />
                    )}
                  </div>
                </Tooly>
                {' | '}
                <Tooly label="ID">
                  <div className="flex gap-2 items-center">
                    <CircleStackIcon className="w-4 h-4" />
                    <CopyLink value={asset.assetID}>{asset.assetID}</CopyLink>
                  </div>
                </Tooly>
              </Ink.Secondary>

              {!asset.isUsed && (
                <div className="ml-auto">
                  <Button.Action tooltip="Löschen" onClick={() => setConfirmDelete(true)}>
                    <TrashIcon className="w-4 h-4 text-red-500" />
                  </Button.Action>
                </div>
              )}
              <ConfirmDelete
                title="Wirklich löschen?"
                description="Das Asset wird unwiderruflich gelöscht."
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                onDelete={() => {
                  withFeedback(
                    async () => {
                      await asset.delete();
                      setConfirmDelete(false);
                      onClose?.(null);
                      onSubmit?.(null);
                    },
                    {
                      success: 'Asset gelöscht',
                      error: () => {
                        setConfirmDelete(false);
                        return 'Fehler beim Löschen';
                      },
                    },
                  );
                }}
              />
            </div>
            <Form $dense>
              <Form.Item>
                <Form.Item.Label>Titel</Form.Item.Label>
                <Form.Item.Body>
                  <Input type="text" {...register('title')} />
                </Form.Item.Body>
              </Form.Item>
              <Form.Item>
                <Form.Item.Label>Alt</Form.Item.Label>
                <Form.Item.Body>
                  <Input type="text" {...register('caption')} />
                </Form.Item.Body>
              </Form.Item>
              <Form.Item>
                <Form.Item.Label>Tags auswählen</Form.Item.Label>
                <Form.Item.Body>
                  <TagsInput control={control} name="tags" />
                </Form.Item.Body>
              </Form.Item>
              <div className="flex space-x-2 justify-end">
                <Button $secondary onClick={() => onClose?.()}>
                  Abbrechen
                </Button>
                <Button
                  $primary
                  onClick={handleSubmit(async (values) => {
                    if (isSubmitting) {
                      return;
                    }
                    try {
                      Object.assign(asset, values);
                      await asset.save();
                      onClose?.();
                      onSubmit?.(asset);
                    } catch (err) {
                      console.error(err);
                    }
                  })}
                >
                  Speichern {isSubmitting && <Spinner className="ml-2 w-5 h-5" />}
                </Button>
              </div>
            </Form>
          </Sidebar.Body>
        </>
      )}
    </Sidebar>
  );
}

export function friendlyBytes(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = 0;
  while (bytes >= 1024) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(1)} ${units[i]}`;
}

export default AssetSidebar;
