import Accounts from 'ec.sdk/lib/Accounts';
import useSWRImmutable from 'swr/immutable';
import environment from '../environment';

function useAccounts() {
  return useSWRImmutable(['Accounts', environment.env], () => {
    const accounts = new Accounts(environment.env);
    accounts.setClientID('rest');

    return accounts;
  });
}

export default useAccounts;
