import React from 'react';
import { classNames } from '../util/classNames';
import Layout from './Layout';

const AuthError = () => {
  return (
    // bg-gray-200 dark:bg-gray-600
    <Layout hideSideMenu skeleton>
      <div
        className={classNames(
          'pointer-events-none transition-opacity fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50',
        )}
      >
        Bitte neu laden
      </div>
    </Layout>
  );
};

export default AuthError;
