export function shortenUUID(uuid, factor = 2) {
  let shortUUID;
  let validatedFactor;
  if (
    !/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(uuid) ||
    (factor && typeof factor !== 'number')
  ) {
    throw new Error('invalid parameter format');
  }
  if (!factor || factor < 1 || factor > 5) {
    validatedFactor = 1;
  } else {
    validatedFactor = factor;
  }
  shortUUID = uuid.replace(/-/g, '');
  shortUUID = shortUUID.split('');
  shortUUID = shortUUID.map((element) => parseInt(element, 16));
  let j;
  let l;

  function xor(val, index) {
    return val ^ shortUUID[l / 2 + index];
  }

  for (j = 0; j < validatedFactor; j++) {
    l = shortUUID.length;
    shortUUID = shortUUID.slice(0, l / 2).map(xor);
  }
  shortUUID = shortUUID.map((element) => element.toString(16));
  shortUUID = shortUUID.join('');
  return shortUUID;
}
