import { classNames } from '@/util/classNames';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';

export default function Alert({ children, small = false }: any) {
  return (
    <div className={classNames('rounded-md bg-blue-100', small ? 'p-2' : 'p-4')}>
      <div className="flex items-center">
        <div className="flex-shrink-0 mr-4">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="text-sm text-blue-700 w-full">{children}</div>
      </div>
    </div>
  );
}
