import { useAddons } from '@/hooks/useAppsite';
import dayjs from '@/util/dayjs';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect } from 'react';
import Button from './Button';
import SimpleSelect from './SimpleSelect';

export default function AddonSelect({
  filter,
  value,
  onChange,
  className,
}: {
  filter?: (i: any) => boolean;
  value: any;
  onChange: (v: any) => void;
  className?: string;
}) {
  const { data: addons, error } = useAddons();
  const [selected, setSelected] = React.useState<any>(value);
  const addon = React.useMemo(() => addons?.find((i: any) => i.id === selected), [addons, selected]);

  if (error) console.error(error);

  const item = typeof selected === 'string' ? addon : selected;
  useEffect(() => {
    onChange(item);
  }, [item]);
  return (
    <div className={className}>
      <SimpleSelect
        items={addons
          ?.filter((a: any) => (filter ? filter(a) : true))
          .map((addon: any) => ({ label: addon.name, value: addon.id }))}
        value={selected}
        onChange={setSelected}
      />
      {selected && (
        <div className="flex justify-between mt-3">
          <div>
            <div className="text-base leading-6 font-normal text-gray-700 dark:text-gray-300">{item?.name}</div>
            <small className="text-xs font-normal text-gray-400 dark:text-gray-500">
              {String(item?.pricePerInterval).replace('.', ',')} € | {dayjs.duration(item?.duration).humanize()}{' '}
              Laufzeit | {item?.freeTestingPhase} Tage Testphase
            </small>
          </div>
          <Button.Action tooltip="Zurücksetzen" onClick={() => setSelected(null)}>
            <XMarkIcon className="w-6 h-6 text-red-500" />
          </Button.Action>
        </div>
      )}
    </div>
  );
}
