import Button from '@/components/Button';
import EntrySidebar from '@/components/EntrySidebar';
import Layout from '@/components/Layout';
import { ManageMakroSidebar } from '@/components/MakroEditor/MakroEditor';
import { NothingHere } from '@/components/NothingHere';
import Section from '@/components/Section';
import { ListingTable } from '@/components/Table';
import useEntryList from '@/hooks/useEntryList';
import useFeedback from '@/hooks/useFeedback';
import useNotifications from '@/hooks/useNotifications';
import useSdk from '@/hooks/useSdk';
import { CogIcon, PencilIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

type MakroCategory = {
  name: string;
  description: string;
  categoryID: string;
  values: any;
};

const marketingReportsValues = [
  {
    name: 'Report Name',
    value: 'report.name',
  },
  {
    name: 'Report Beschreibung',
    value: 'report.description',
  },
  {
    name: 'Report Start',
    value: 'report.fromDate',
  },
  {
    name: 'Report Ende',
    value: 'report.toDate',
  },
  {
    name: 'Anmeldungen Gesamt',
    value: 'hectorStats.total.activations',
  },
  {
    name: 'Kündigungen Gesamt',
    value: 'hectorStats.total.cancellations',
  },
  {
    name: 'Budget Google',
    value: 'performanceMarketing.budget.google',
  },
  {
    name: 'Budget Meta',
    value: 'performanceMarketing.budget.meta',
  },
  {
    name: 'Budget Insegesamt',
    value: 'performanceMarketing.budget.total',
  },
  {
    name: 'Klicks Insegesamt',
    value: 'performanceMarketing.clicks.total',
  },
  {
    name: 'Klicks Meta',
    value: 'performanceMarketing.clicks.meta',
  },
  {
    name: 'Klicks Google',
    value: 'performanceMarketing.clicks.google',
  },
  {
    name: 'Klicks Analytics',
    value: 'performanceMarketing.clicks.analyitcsVisitors',
  },
  {
    name: 'Klicks MyBusiness',
    value: 'performanceMarketing.clicks.googleMyBusiness',
  },
  {
    name: 'Followers Facebook',
    value: 'socialMediaOrganic.facebook.followers',
  },
  {
    name: 'Followers Instgram',
    value: 'socialMediaOrganic.instgram.followers',
  },
];

const defaults: MakroCategory[] = [
  {
    name: 'Mitglied werden Vorteile',
    description: 'Wird bei der Aktionsplanung - Vorteile verwendet -',
    categoryID: 'mw-benefit',
    values: [],
  },
  {
    name: 'Marketing Reports: Handlungsempfehlungen',
    description: 'Makros für Handlungsempfehlungen am Ende des Reports',
    categoryID: 'marketingReports$footer',
    values: marketingReportsValues,
  },
  {
    name: 'Marketing Reports: Mitgliedschaftsabschlüsse',
    description: 'Makros für die Mitgliedschaftsabschlüsse',
    categoryID: 'marketingReports$hectorStats',
    values: marketingReportsValues,
  },
  {
    name: 'Marketing Reports: Einleitung',
    description: 'Makros für die Einleitung am Anfang des Reports',
    categoryID: 'marketingReports$intro',
    values: marketingReportsValues,
  },
];
export const useCreateDefaultMakroCategories = () => {
  // creates the Makro Default Categories if they don't exist
  // else it does nothing

  const { api } = useSdk();
  const { data: categories, mutate } = useEntryList({ model: 'makro_categories' });
  const { withFeedback, pending } = useFeedback();
  const notification = useNotifications();

  const createDefaultCategories = useCallback(
    (callback) => {
      if (categories && api) {
        const cats = categories.getAllItems();
        if (defaults.length > cats.length) {
          notification.emit({
            type: 'success',
            message: 'Einen Moment. Die Makro Kategorien werden erstellt.',
          });
          withFeedback(
            async () => {
              for (const defaultCat of defaults) {
                const found = cats.find((cat) => cat.categoryID === defaultCat.categoryID);
                if (!found) {
                  await api.createEntry('makro_categories', defaultCat);
                } else {
                  console.log('found', found);
                }
                callback?.();
              }
            },
            {
              success: 'Default Makro Categories created',
              error: 'Error creating default Makro Categories',
            },
          );
        }
      }
    },
    [categories, api],
  );

  return { pending, createDefaultCategories };
};

export default function MakroConfig() {
  const { data: categories, mutate } = useEntryList({
    model: 'makro_categories',
  });

  const { pending, createDefaultCategories } = useCreateDefaultMakroCategories();

  const items: MakroCategory[] = categories?.getAllItems();
  const [selected, setSelected] = React.useState<MakroCategory>();
  const [selectedCategory, setSelectedCategory] = React.useState<string>('');

  return (
    <Layout>
      <Helmet>
        <title>Makros Verwalten | Clubapp Admin</title>
      </Helmet>

      <Section>
        <Section.Sticky>
          <Section.Head>
            <Section.Heading>Makros Verwalten </Section.Heading>
          </Section.Head>
          <Section.Divider />
        </Section.Sticky>
        <Section.Container>
          {items?.length === 0 ? (
            <NothingHere
              headline="Noch keine Makros vorhanden"
              subline="Beginne damit die Standard Makros zu erstellen"
              onCreate={() => createDefaultCategories(() => mutate())}
            />
          ) : (
            <>
              <ListingTable
                header={['name', 'ID', 'Makros', '']}
                data={items}
                cell={(cell) => [
                  <div className="flex flex-col gap-3">
                    <strong>{cell.name}</strong>
                    <small className="text-gray-500">{cell.description}</small>
                  </div>,
                  cell.categoryID,
                  <Button.Action tooltip="Makros verwalten" onClick={() => setSelectedCategory(cell.categoryID)}>
                    <PencilIcon className="w-4 h-4 cursor-pointer" />
                  </Button.Action>,
                  <Button.Action tooltip="Bearbeiten" onClick={() => setSelected(cell)}>
                    <CogIcon className="w-4 h-4 cursor-pointer" />
                  </Button.Action>,
                ]}
              />

              <ManageMakroSidebar
                open={selectedCategory !== ''}
                category={selectedCategory}
                onChange={() => mutate()}
                onClose={() => setSelectedCategory('')}
              />

              <EntrySidebar
                open={!!selected}
                heading="Makro Kategorie bearbeiten"
                model="makro_categories"
                entry={selected}
                fields={{
                  name: { label: 'Name' },
                  description: { label: 'Beschreibung' },
                  categoryID: { label: 'Kategorie Id (Änderungen können Probleme verursachen)' },
                  values: { label: 'Makro Variablen' },
                }}
                onSubmit={() => mutate()}
                onClose={() => setSelected(null)}
              />
            </>
          )}
        </Section.Container>
      </Section>
    </Layout>
  );
}
