import { classNames } from '@/util/classNames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { usePopper } from 'react-popper';
import useDocumentClick from '../hooks/useDocumentClick';
import dayjs from '../util/dayjs';
import Button from './Button';
import Calendar from './Calendar';
import Form from './Form';

const dateFormat = 'DD.MM.YYYY'; // the format used for typing in the text input

/**
 *
 * Input field for a date. Either enter via keyboard or click a day
 *
 * ```js
 * <CalendarInput
 *   value={value}
 *   onChange={setValue}
 *   placeholder="Wann möchtest du mit dem Wassertaxi fahren?"
 * />
 * ```
 */

function CalendarInput({
  value: valueProp,
  format,
  onChange,
  disabled,
  placeholder,
}: {
  value: string | null;
  format?: string; // if not set, iso is assumed
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  placeholder?: string;
}) {
  const [referenceElement, setReferenceElement] = useState();
  const inputRef = useRef<any>();
  const [container, setContainer] = useState<any>(null); // need this as state to make sure usePopper reruns
  const { styles, attributes } = usePopper(referenceElement, container, { placement: 'bottom-start' });
  const [value, setValue] = useState(valueProp);
  const [open, setOpen] = useState(false);

  const documentClick = useCallback(
    (e) => {
      if (open && e.target !== inputRef.current && !(container as any)?.contains(e.target)) {
        setOpen(false);
      }
    },
    [open, container],
  );
  useDocumentClick(documentClick);

  const calendarValue = useMemo(() => {
    if (!value) {
      return null;
    }

    const d = dayjs(value);

    if (!d.isValid()) {
      return null;
    }

    return d.toISOString();
  }, [value]);

  const inputValue = useMemo(() => {
    if (!value) {
      return null;
    }

    const d = dayjs(value);

    if (!d.isValid()) {
      return null;
    }

    return dayjs(value).format(dateFormat);
  }, [value]);

  useEffect(() => {
    if (valueProp !== value) {
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <>
      <div ref={setReferenceElement as any}>
        <InputMask
          mask="99.99.9999"
          value={inputValue}
          placeholder={placeholder}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          onChange={({ target: { value: v } }) => {
            if (!v || v === '__.__.____' || v?.trim().length === 0) {
              onChange?.(null);
            }
            const d = dayjs(v, dateFormat);
            if (!d.isValid()) {
              setValue(v);
            } else {
              setValue(d.toISOString());
              onChange?.(d.toISOString());
            }
          }}
          className={Form.Item.text}
        >
          {(props) => (
            <input
              type="text"
              ref={inputRef}
              {...props}
              className={classNames(Form.Item.text, disabled && 'bg-gray-200 pointer-events-none')}
            />
          )}
        </InputMask>
      </div>
      {open && !disabled && (
        <div ref={setContainer} {...attributes.popper} style={styles.popper} className="w-96 pt-2 z-50">
          <Calendar
            value={calendarValue}
            onChange={(day) => {
              const v = day.toISOString();
              inputRef.current?.focus();
              setValue(v);
              onChange?.(v);
            }}
          />
        </div>
      )}
    </>
  );
}

export function DateInput(props: any) {
  const { placeholder, control, name, rules, format, disabled } = props;
  return (
    <Controller
      render={({ field }) => (
        <CalendarInput
          disabled={disabled}
          placeholder={placeholder}
          value={field.value ? dayjs(field.value).format(format) : ''}
          onChange={(value) => field.onChange(value)}
          format={format}
        />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}

export default CalendarInput;

export function CalendarInputExample() {
  const [iso, setISO] = useState<string | null>(dayjs().toISOString());
  const [nullish, setNullish] = useState<string | null>(null);
  const [ddmmyyyy, setDdmmyyyy] = useState<string | null>('26.01.2023');
  const [yyyymmdd, setYyyymmdd] = useState<string | null>('2023-01-26');
  return (
    <div className="max-w-md">
      <h3>With null: {nullish}</h3>
      <CalendarInput value={nullish} onChange={(v) => setNullish(v)} />
      <h3>With ISO String: {iso}</h3>
      <CalendarInput value={iso} onChange={(v) => setISO(v)} />
      <h3>With DD.MM.YYYY: {ddmmyyyy}</h3>
      <CalendarInput value={ddmmyyyy} onChange={(v) => setDdmmyyyy(v)} format="DD.MM.YYYY" />
      <h3>With YYYY-MM-DD: {yyyymmdd}</h3>
      <CalendarInput value={yyyymmdd} onChange={(v) => setYyyymmdd(v)} format="YYYY-MM-DD" />
    </div>
  );
}
export function DateInputExample() {
  const { control, watch, reset } = useForm({
    /* defaultValues: {
      date: dayjs().toISOString(),
    }, */
  });
  useEffect(() => {
    reset({
      null: null,
      date: dayjs().toISOString(),
    });
  }, []);
  const date = watch('date');

  return (
    <div className="max-w-md">
      <Button
        onClick={() => {
          reset({ date: dayjs(date).add(1, 'day').toISOString() });
        }}
      >
        + 1
      </Button>
      {date}
      <DateInput control={control} name="date" />
    </div>
  );
}
