import dayjs from '@/util/dayjs';
import { useEffect } from 'react';
import useFeedback from './useFeedback';
import useSdk from './useSdk';
import useSettings from './useSettings';
type FeatureFlag = any;
export default function useFeatureFlags() {
  const { data: settings, isLoading, mutate } = useSettings();

  function getFeatureFlags(): { [key: string]: FeatureFlag } {
    return settings?.featureFlags;
  }

  function featureIsEnabled(feature: string, platform: keyof FeatureFlag = 'admin'): boolean {
    if (settings?.featureFlags[feature]) {
      if (typeof settings?.featureFlags[feature][platform] === 'boolean') {
        return settings?.featureFlags[feature][platform];
      } else {
        return dayjs().isAfter(dayjs(settings?.featureFlags[feature][platform]));
      }
    }
    return null;
  }

  const { withFeedback, pending } = useFeedback();
  function enableFeature(feature: string, platform: Array<keyof FeatureFlag> = ['admin'], date?: string) {
    withFeedback(
      async () => {
        if (!settings) throw new Error('Settings not found');

        if (!settings.featureFlags[feature]) {
          settings.featureFlags[feature] = {
            api: false,
            admin: false,
            clubapp: false,
            appsite: false,
          };
        }

        platform.forEach((p) => {
          if (p === 'admin') {
            return (settings.featureFlags[feature][p] = true);
          }
          return (settings.featureFlags[feature][p] = date || true);
        });

        const s = await settings.save(true);
        await mutate(s);
      },
      {
        success: `Feature ${feature} aktiviert`,
        error: 'Feature konnte nicht aktiviert werden',
      },
    );
  }

  function disableFeature(feature: string, platform: Array<keyof FeatureFlag> = ['admin']) {
    withFeedback(
      async () => {
        if (!settings) throw new Error('Settings not found');

        if (!settings.featureFlags[feature]) {
          settings.featureFlags[feature] = {
            api: false,
            admin: false,
            clubapp: false,
            appsite: false,
          };
        }

        platform.forEach((p) => {
          return (settings.featureFlags[feature][p] = false);
        });

        const s = await settings.save(true);
        await mutate(s);
      },
      {
        success: `Feature ${feature} deaktiviert`,
        error: 'Feature konnte nicht deaktiviert werden',
      },
    );
  }

  return { getFeatureFlags, pending: pending || isLoading, featureIsEnabled, enableFeature, disableFeature };
}

export const useHasFeature = (platform = 'admin') => {
  const { data: settings, isValidating } = useSettings();
  const { api } = useSdk();

  const featureFlags = JSON.parse(sessionStorage.getItem('featureFlags') || '{}');

  useEffect(() => {
    // set value per Appsite
    sessionStorage.setItem(
      'featureFlags',
      JSON.stringify({
        ...featureFlags,
        [api?.shortID]: settings?.featureFlags,
      }),
    );
  }, [settings, featureFlags, api?.shortID]);

  const hasFeature = (feature: string): boolean => {
    if (featureFlags?.[api?.shortID]?.[feature]) {
      const platformFeature = featureFlags[api?.shortID][feature][platform];
      if (typeof platformFeature === 'boolean') {
        return platformFeature;
      } else {
        return dayjs().isAfter(dayjs(platformFeature));
      }
    }
    if (settings?.featureFlags?.[feature]) {
      if (typeof settings?.featureFlags[feature][platform] === 'boolean') {
        return settings?.featureFlags[feature][platform];
      } else {
        return dayjs().isAfter(dayjs(settings?.featureFlags[feature][platform]));
      }
    }
    return false;
  };

  return { hasFeature, isValidating: (!settings && isValidating === false) || isValidating };
};
