import { GLOBALTEMPLATEVAR } from '@/components/GlobalContract/model';
import environment from '@/environment';
import useFeedback from '@/hooks/useFeedback';
import { useGlobalContract } from '@/hooks/useGlobalContract';
import useNotifications from '@/hooks/useNotifications';
import useSdk from '@/hooks/useSdk';
import useSWR from 'swr';

export const useGlobalContractPreview = () => {
  const { api } = useSdk();
  const { data: globalContract } = useGlobalContract();
  const { withFeedback, pending } = useFeedback();
  const notification = useNotifications();

  async function generatePreview(template, download = false, callback = (_: any) => {}) {
    withFeedback(
      async () => {
        notification.emit({
          type: 'success',
          message: 'Vorschau wird generiert',
        });

        const url = new URL(environment.hecRenderUrl);
        url.pathname = `${api.shortID}/${
          template.isGlobalTemplate ? `${globalContract.name}/contract` : `${template.contract?.templateName}/fabric`
        }`;

        url.searchParams.set('type', 'pdf');
        const blob = await fetch(url.toString(), {
          method: 'POST',
          body: JSON.stringify(
            dotToObject({
              timestamp: new Date().toISOString(),
              ...GLOBALTEMPLATEVAR.reduce((acc, { identifier, placeholder }) => {
                return { ...acc, [identifier]: placeholder };
              }, {}),
              ...template,
            }),
          ),
          headers: {
            'content-type': 'application/json',
          },
        }).then((res) => res.blob());

        if (download) {
          const a = document.createElement('a');
          const href = URL.createObjectURL(blob);
          a.href = href;
          a.download = `${globalContract.name}.pdf`;
          a.click();
          URL.revokeObjectURL(href);
        } else {
          console.log('herre', URL.createObjectURL(blob));
          callback(URL.createObjectURL(blob));
        }
      },
      {
        success: 'Vorschau wurde generiert',
        error: 'Vorschau konnte nicht generiert werden',
      },
    );
  }

  return { generatePreview, pending };
};

export const useGlobalContractPreviewImage = (type = 'GlobalContract') => {
  const { api } = useSdk();
  const { data: globalContract } = useGlobalContract(type);

  return useSWR(api && type ? `${type}Preview` : null, async () => {
    const url = new URL(environment.hecRenderUrl);
    url.pathname = `${api.shortID}/${globalContract.name}/contract`;
    url.searchParams.set('type', 'jpg');
    const blob: Blob = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    }).then((res) => res.blob());
    return URL.createObjectURL(blob);
  });
};

export function dotToObject(data) {
  function index(parent, key, value) {
    const [mainKey, ...children] = key.split('.');
    parent[mainKey] = parent[mainKey] || {};

    if (children.length === 1) {
      parent[mainKey][children[0]] = value;
    } else {
      index(parent[mainKey], children.join('.'), value);
    }
  }

  const result = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.includes('.')) {
      index(acc, key, value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
  return result;
}
