import {
  CommandButton,
  FloatingToolbar,
  useActive,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useExtensionEvent,
  useUpdateReason,
} from '@remirror/react';
import type { ChangeEvent, HTMLProps, KeyboardEvent } from 'react';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { LinkExtension, ShortcutHandlerProps, createMarkPositioner } from 'remirror/extensions';
import Input from '../Input';

function useLinkShortcut() {
  const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    LinkExtension,
    'onShortcut',
    useCallback(
      (props) => {
        if (!isEditing) {
          setIsEditing(true);
        }

        return setLinkShortcut(props);
      },
      [isEditing],
    ),
  );

  return { linkShortcut, isEditing, setIsEditing };
}

function useFloatingLinkState() {
  const chain = useChainedCommands();
  const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
  const { to, empty } = useCurrentSelection();

  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);

  // A positioner which only shows for links.
  const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);

  const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);

  const updateReason = useUpdateReason();

  useLayoutEffect(() => {
    if (!isEditing) return;

    if (updateReason.doc || updateReason.selection) {
      setIsEditing(false);
    }
  }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  useEffect(() => {
    setHref(url);
  }, [url]);

  const submitHref = useCallback(() => {
    setIsEditing(false);
    const range = linkShortcut ?? undefined;

    if (href === '') {
      chain.removeLink();
    } else {
      chain.updateLink({ href, auto: false }, range);
    }

    chain.focus(range?.to ?? to).run();
  }, [setIsEditing, linkShortcut, chain, href, to]);

  const cancelHref = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink();
    }

    setIsEditing((old) => !old);
  }, [chain, empty, setIsEditing]);

  return useMemo(
    () => ({
      href,
      setHref,
      linkShortcut,
      linkPositioner,
      isEditing,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref,
    }),
    [href, linkShortcut, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref],
  );
}

const DelayAutoFocusInput = ({ autoFocus, ...rest }: HTMLProps<HTMLInputElement>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let frame;
    if (autoFocus) {
      frame = window.requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }

    return () => window.cancelAnimationFrame(frame);
  }, [autoFocus]);

  return <Input className="z-20 !rounded-none !outline-none !border-none !ring-0" ref={inputRef} {...rest} />;
};

export const FloatingLinkToolbar = () => {
  const { isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref } =
    useFloatingLinkState();
  const active = useActive();
  const activeLink = active.link();
  const { empty } = useCurrentSelection();

  const handleClickEdit = useCallback(() => {
    clickEdit();
  }, [clickEdit]);

  const linkEditButtons = activeLink && (
    <div className="flex gap-2 p-2 border-b bg-gray-100 dark:bg-gray-400 text-white">
      <CommandButton commandName="updateLink" onSelect={handleClickEdit} icon="pencilLine" enabled />
      <CommandButton commandName="removeLink" onSelect={onRemove} icon="linkUnlink" enabled />
    </div>
  );

  return (
    <div className="relative">
      {activeLink && linkEditButtons}
      {activeLink && empty && <FloatingToolbar positioner={linkPositioner}>{linkEditButtons}</FloatingToolbar>}
      {isEditing && (
        <DelayAutoFocusInput
          autoFocus
          placeholder="Dein Link…"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
          onBlur={submitHref}
          value={href}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            const { code } = event;
            if (code === 'Enter') {
              submitHref();
            }
            if (code === 'Escape') {
              cancelHref();
            }
          }}
        />
      )}
    </div>
  );
};
