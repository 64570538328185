import { useSessionStorage } from '@/hooks/useSessionStorage';
import useUser from '@/hooks/useUser';
import { NewsModal } from '@/routes/AdminNews/Modal';
import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import userflow from 'userflow.js';
import packageJson from '../../package.json';
import useSdk from '../hooks/useSdk';
import { classNames } from '../util/classNames';
import Button from './Button';
import Sidemenu from './Sidemenu';
import Topmenu from './Topmenu';

const Header = tw.div`flex relative justify-between  border-gray-200 dark:border-gray-700 flex-wrap items-center py-4 px-5 bg-white dark:bg-gray-800`;

export const HideMenuContext = React.createContext({
  hideTopMenu: false,
  hideSideMenu: false,
  setHideSideMenu: (to: boolean) => {},
  setHideTopMenu: (to: boolean) => {},
});

const TABLET_BREAKPOINT = 992;
function isMobileTablet() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

const HideMenuProvider = ({ children }) => {
  const [hideSideMenu, setHideSidemenuExplicitly] = React.useState(
    isMobileTablet() || window.innerWidth < TABLET_BREAKPOINT,
  );
  const [hideTopMenu, setHideTopMenuExplicitly] = React.useState(false);

  useEffect(() => {
    const updateSidemenu = () => {
      setHideSidemenuExplicitly(window.innerWidth < TABLET_BREAKPOINT);
    };

    window.addEventListener('resize', updateSidemenu);

    return () => {
      window.removeEventListener('resize', updateSidemenu);
    };
  }, []);

  return (
    <HideMenuContext.Provider
      value={{
        hideTopMenu,
        hideSideMenu,
        setHideSideMenu: setHideSidemenuExplicitly,
        setHideTopMenu: setHideTopMenuExplicitly,
      }}
    >
      {children}
    </HideMenuContext.Provider>
  );
};

// This Layout is at the Top Level of the Router and renders all routes through <Outlet/>
//hideTopmenu, hideSideMenu,
function LayoutNew({ skeleton, noScroll }: any) {
  const { api } = useSdk();

  const { hideSideMenu, setHideTopMenu, setHideSideMenu, hideTopMenu } = useContext(HideMenuContext);

  const toggle = () => setHideSideMenu(!hideSideMenu);

  const { shortID: urlShortID } = useParams();

  const showSidemenu = !hideSideMenu && urlShortID && api?.shortID;
  const showTopmenu = !hideTopMenu;

  const user = useUser();
  const shortID = api?.shortID;

  useEffect(() => {
    if (user?.email && shortID) {
      userflow.init('ct_bwybycvmxndkrgweodszvb4j7y');
      userflow.identify(user.email, { appsite_id: shortID });
    }
  }, [user?.email, shortID]);

  return (
    <>
      {showTopmenu && (
        <header id="header" className="relative border-b">
          <Header>
            {!skeleton && <Topmenu onToggleSidemenu={() => toggle()} />}
            {skeleton && <div className="h-12 animate-pulse" />}
          </Header>
        </header>
      )}
      <div
        className="bg-white dark:bg-gray-900 flex overflow-hidden"
        id="admin"
        style={{
          height: !showTopmenu ? '100vh' : `calc(100vh - ${document.querySelector('#header')?.clientHeight}px)`,
        }}
      >
        {showSidemenu && (
          <aside className="w-64 h-full flex-shrink-0 overflow-auto bg-white dark:bg-gray-800 dark:border-gray-700">
            <Sidemenu />
          </aside>
        )}
        <main className={classNames('flex-1', noScroll ? '' : 'overflow-y-auto')} id="scroll-container">
          <Outlet />
        </main>

        {user.canEditDSBLayers && <NewsModal />}
        <NewVersionAvailable />
        <div className="fixed bottom-1 right-1 text-[9px] text-gray-500 print:hidden">
          v{packageJson.version} - &copy; {new Date().getFullYear()} Hectors Welt GmbH - developed by{' '}
          <a href="https://entrecode.de" target="_blank">
            entrecode
          </a>
        </div>
      </div>
    </>
  );
}

export default () => (
  <HideMenuProvider>
    <LayoutNew />
  </HideMenuProvider>
);

const NewVersionAvailable = () => {
  const [lastVersionCheck, setLastVersionCheck] = useSessionStorage('lastVersionCheck', false);
  const { data } = useSWR(
    'newVersionAvailable',
    async () => {
      return (await fetch('index.html', { method: 'HEAD' })).headers.get('Last-Modified');
    },
    {
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateOnFocus: true,
      refreshInterval: 60000,
    },
  );

  useEffect(() => {
    if (!lastVersionCheck && data) {
      setLastVersionCheck(data);
    }
  }, [data]);

  if (data && dayjs(data).isAfter(dayjs(lastVersionCheck))) {
    return (
      <div className="fixed flex border gap-3 flex-col bottom-4 left-4 bg-gray-50 p-6 rounded-md text-gray-500">
        Neue Version verfügbar
        <small>Bitte lade die Seite neu nachdem du deine Arbeit gespeichert hast</small>
        <Button
          $primary
          onClick={() => {
            setLastVersionCheck(data);
            window.location.reload();
          }}
        >
          Neu laden
        </Button>
      </div>
    );
  }
  return null;
};

/* export function TempLayout({ children, hideTopmenu, skeleton }: any) {
  const { api } = useSdk();
  // const [hideSidemenuExplicitly, setHideSidemenuExplicitly] = React.useState(undefined);
  // const hide = hideSidemenuExplicitly ?? hideSideMenu;
  const { isOpen, toggle } = useSidebar();
  const showSidemenu = isOpen && api?.shortID;
  return (
    <>
      {!hideTopmenu && (
        <header id="header" className="relative">
          <Header>
            {!skeleton && <Topmenu onToggleSidemenu={() => toggle()} />}
            {skeleton && <div className="h-12 animate-pulse" />}
          </Header>
        </header>
      )}
      <div
        className="bg-gray-100 dark:bg-gray-900 flex overflow-hidden"
        id="admin"
        style={{ height: hideTopmenu ? '100vh' : 'calc(100vh - 84px)' }}
      >
        {showSidemenu && (
          <aside className="w-64 h-full flex-shrink-0  overflow-auto bg-white dark:bg-gray-800 border-r dark:border-gray-700">
            <Sidemenu />
          </aside>
        )}
        <main className="flex-1 overflow-y-auto" id="scroll-container">
          {children}
        </main>
      </div>
    </>
  );
} 

export default function useSidebar() {
  const { data: isOpen, mutate } = useSWR('sidebar', () => true, { revalidateOnFocus: false });

  function toggle() {
    mutate(!isOpen, { revalidate: false });
  }

  return {
    isOpen,
    toggle,
  };
}
 */
