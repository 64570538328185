import { useClubs } from '@/hooks/useAppsite';
import React, { useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Input from './Input';
import SimpleSelect from './SimpleSelect';
type ClubSelectProps = {
  /** Label for empty value, defaults to "Alle Backends" */
  defaultLabel?: string;
  /** Placeholder when nothing is selected */
  placeholder?: string;
  /** Prefix for backend item labels */
  prefix?: string;
  /** current value (backendID) */
  value?: string;
  /** onChange callback */
  onChange?: (value: string) => void;
  /** extra classes for SimpleSelect */
  className?: string;
  /** if true, there will be no selectable option for an empty value */
  excludeEmpty?: boolean;

  multi?: boolean;
  listIfEmpty?: boolean;
  setIfOnlyOne?: boolean;
  filter?: (backend: string) => boolean;
  /** used to persist Selection between Navigation */
  persistAs?: string;
};

function ClubSelect(props: ClubSelectProps) {
  const {
    defaultLabel = 'Alle Clubs',
    prefix = '',
    value,
    multi,
    onChange,
    className,
    excludeEmpty,
    listIfEmpty = false,
    setIfOnlyOne = false,
    placeholder = 'Club wählen...',
    filter,
  } = props;
  const { data: clubs } = useClubs();

  const clubItems = [
    // ...(!excludeEmpty ? [{ label: defaultLabel, value: null }] : []),
    ...(clubs?.map((club) => ({ label: club.name, value: club.id })) || []),
  ].filter((item) => (filter ? filter(item?.value) : true));

  useEffect(() => {
    if (setIfOnlyOne && clubItems?.length === 1 && !value) {
      // onChange(Object.keys(backends || {})[0]); // <- BUG!??!?!
      onChange(clubItems[0].value);
    }
  }, [clubItems, listIfEmpty, setIfOnlyOne, value, onChange]);
  //
  if (clubs?.length < 2) {
    return listIfEmpty ? <Input type="text" disabled value={clubs?.[value]} /> : <></>;
  }

  if (multi) {
    let multiValue = value
      ? value.split(',').map((v) => ({
          label: clubItems?.find((c) => c.value === (v || null))?.label,
          value: v || null,
        }))
      : clubItems;
    return (
      <>
        <style>
          {`
:is(.dark body.dark-theme) .rmsc {
  --rmsc-main: ;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: rgb(67 56 202 / var(--tw-bg-opacity)); 
  --rmsc-border: rgb(107 114 128 / var(--tw-border-opacity));
  --rmsc-gray: rgb(107 114 128 / var(--tw-border-opacity));
  --rmsc-bg: rgb(55 65 81 / var(--tw-bg-opacity));
  color: #fff;
}
        `}
        </style>
        <MultiSelect
          options={clubItems}
          value={multiValue}
          onChange={(e) => {
            onChange(e.map((v) => v.value).join(','));
          }}
          overrideStrings={{
            allItemsAreSelected: 'Alle Clubs',
            clearSearch: 'Suche leeren',
            clearSelected: 'Auswahl löschen',
            noOptions: 'Keine Clubs',
            search: 'Suche',
            selectAll: 'Alle Clubs auswählen',
            selectAllFiltered: 'Alle (gefilterten) Clubs auswählen',
            selectSomeItems: 'Wählen...',
            create: 'Erstellen',
          }}
          labelledBy="Select"
        />
      </>
    );
  }

  return (
    <SimpleSelect
      persistAs={props.persistAs}
      items={clubItems}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
}

// just do a global search with "<BackendSelect" to see example usage
// all you need is "backends" + "setBackends" which are provided by useBackends or useSharedResource

export default ClubSelect;
