import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function Info({ children }: { children: React.ReactNode }) {
  return (
    <div className="rounded-md bg-blue-50 dark:bg-blue-900/50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400 " aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between text-blue-800 dark:text-blue-200">{children}</div>
      </div>
    </div>
  );
}
