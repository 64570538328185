import React, { useContext, useEffect } from 'react';
import { HideMenuContext } from './LayoutNew';

function Layout({ children, hideSideMenu }: any) {
  const { setHideSideMenu: setHideSidemenuExplicitly } = useContext(HideMenuContext);

  useEffect(() => {
    setHideSidemenuExplicitly(hideSideMenu);
  }, [hideSideMenu]);

  return <>{children}</>;
}

export default Layout;
