import React from 'react';
import useSWR from 'swr';
import { useDSB } from './useDSB';
import useSettings from './useSettings';

export default function useIsMigrating() {
  const { data: settings } = useSettings();
  const seoTitle = settings?.appsiteUrl.split('/').pop();
  const { dsbApi } = useDSB();
  return useSWR(seoTitle + ' /isMigration', async () => {
    const entry = (await dsbApi.entryList('ho_migrate', { franchise_name: seoTitle })).getFirstItem();
    if (!entry) return 'not_started';
    return entry.state;
  });
}
