import environment from '@/environment';
import { useState } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { useDSB } from './useDSB';
import useSdk from './useSdk';

export function useDiscountList({
  page,
  size,
  search,
  sort,
  ...filter
}: {
  page: number;
  size: number;
  search?: string;
  sort?: string;
  [key: string]: any;
}) {
  const { api } = useSdk();
  return useSWR(api ? `/discounts/${page}&${search}&${sort}&${JSON.stringify(filter)}` : null, async () =>
    api.entryList('discount_campaign', {
      page: page,
      _count: size,
      sort: sort ? sort : undefined,
      name: search ? { search } : undefined,
      ...filter,
    }),
  );
}

export function useDiscountTags() {
  const { api } = useSdk();
  return useSWR(api ? `/discounts/tags` : null, async () => api.entryList('discount_campaign_tag', { _count: 1000 }));
}

type DiscountCode = {
  code: string;
  created: string;
  email: string;
  id: string;
  hectorConfig: {};
  issued: string;
  printed: string;
  redeemed: string;
  paid: string;
};

export function useDiscountCodes() {
  const { swr } = useDSB();
  return (
    campaignID: string,
    filter?: {
      size?: number;
      page?: number;
      code?: string;
      email?: string;
      printed?: boolean;
      redeemed?: boolean;
      createdFrom?: string;
      createdTo?: string;
      printedFrom?: string;
      printedTo?: string;
      issued?: boolean;
      issuedFrom?: string;
      issuedTo?: string;
      redeemedFrom?: string;
      redeemedTo?: string;
    },
  ): SWRResponse<{
    meta: { total: number };
    data: DiscountCode[];
  }> => swr(`admin/discount/${campaignID}/codes`, filter, { revalidateOnFocus: false });
}

const defaultSettings = {
  url: undefined,
  params: {},
  refreshInterval: 2000,
  callback: () => {},
  onError: (_error: any) => {},
};

export function useTaskService() {
  const taskServiceUrl = environment.tasksServiceUrl;

  const [settings, setSettings] = useState<{
    url: string;
    params: any;
    refreshInterval?: number;
    callback: (data: any) => void;
    onError: (error: any) => void;
  }>(defaultSettings);

  const monitor = useSWR(
    settings.url && settings.params ? taskServiceUrl + settings.url + JSON.stringify(settings.params) : null,
    async () => {
      const task = await fetch(taskServiceUrl + '/' + settings.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings.params),
      }).then((res) => res.json());
      return task;
    },
    {
      revalidateOnFocus: false,
    },
  );

  const taskStatus = useSWR(
    monitor.data?._links ? taskServiceUrl + monitor.data._links.monitor.href : null,
    async (url) => {
      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.some((log: string) => log.toLowerCase().includes('finished'))) {
            setSettings(defaultSettings);
            settings.callback(res);
          }
          if (res.some((log: string) => log.toLowerCase().includes('error'))) {
            setSettings(defaultSettings);
            settings.onError(res);
          }
          return res;
        });
    },
    {
      refreshInterval: settings.refreshInterval || 2000,
    },
  );

  return {
    monitor,
    taskStatus,
    setSettings,
  };
}
